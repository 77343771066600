import { en as basic } from "./namespaces/basic";
import { en as Header } from "./namespaces/Header";
import { en as countries } from "./namespaces/countries";
import { en as OurServices } from "./namespaces/OurServices";
import { en as Search } from "./namespaces/Search";
import { en as Page404 } from "./namespaces/Page404";
import { en as Page500 } from "./namespaces/Page500";
import { en as faq } from "./namespaces/faq";
import { en as About } from "./namespaces/About";
import { en as OurClients } from "./namespaces/OurClients";
import { en as ServicesDetails } from "./namespaces/ServicesDetails";
import { en as PrivacyPolicy } from "./namespaces/PrivacyPolicy";
import { en as TermsConditions } from "./namespaces/TermsConditions";
import { en as Footer } from "./namespaces/Footer";
import { en as Home } from "./namespaces/Home";
import { en as ContactUs } from "./namespaces/ContactUs";
import { en as Forms } from "./namespaces/Forms";
import { en as Profile } from "./namespaces/Profile";
import { en as Table } from "./namespaces/Table";
import { en as serviceProvider } from "./namespaces/serviceProvider";
import { en as OurTeams } from "./namespaces/OurTeams";

export default {
  basic,
  Header,
  countries,
  About,
  OurServices,
  Search,
  Page404,
  Page500,
  faq,
  OurClients,
  ServicesDetails,
  PrivacyPolicy,
  TermsConditions,
  Footer,
  Home,
  ContactUs,
  Profile,
  Table,
  serviceProvider,
  Forms,
  OurTeams,
};
