import {
  PERSONAL_REGISTER,
  CORPORATE_REGISTER,
  LOGIN_WEBSITE_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
} from "./types";

export const personalUserRegister = (data) => ({
  type: PERSONAL_REGISTER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/register-personal`,
    method: "post",
    data,
  },
});

export const corporateUserRegister = (data) => ({
  type: CORPORATE_REGISTER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/register-corporate`,
    method: "post",
    data,
  },
});

export const loginWebsiteUser = (data) => ({
  type: LOGIN_WEBSITE_USER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}login`,
    method: "post",
    data,
  },
  meta: { data },
});

export const forgetPassword = (data) => ({
  type: FORGET_PASSWORD,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}forget-password`,
    method: "post",
    data,
  },
});

export const resetPassword = (data) => ({
  type: RESET_PASSWORD,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}reset-password`,
    method: "post",
    data,
  },
});

export const signOut = () => ({
  type: LOGOUT,
});
