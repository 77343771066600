import moment from "moment";

export const timeFormatter = (time) => {
  let formattedTime = moment(time)
    .locale("en")
    .format("LT")
    .replace("PM", function () {
      return "مساءً";
    })
    .replace("AM", function () {
      return "صباحاً";
    });

  return formattedTime;
};

let langType = localStorage.getItem("i18nextLng");

export const language = (lang) => {
  // if (lang.includes("-")) {
  //   let langCharacter = lang.substring(0, lang.indexOf("-"));
  //   // console.log(langCharacter, "pp");
  //   return langCharacter;
  // } else {
  //   // console.log(lang, "oo");
    return lang;
  // }
};

export const timeFormat = (time) => {
  if (language(langType) === "en") {
    return moment(time).locale("en").format("LT");
  } else {
    return timeFormatter(time);
  }
};

export const dateTimeFormat = (dt) => {
  if (language(langType) === "en") {
    return (
      moment(dt).locale("en").format("MMMM DD") +
      " at " +
      moment(dt).locale("en").format("LT")
    );
  } else {
    return (
      moment(dt).locale("en").format("DD") +
      " " +
      moment(dt).format("MMMM") +
      " فى " +
      timeFormatter(dt)
    );
  }
};
