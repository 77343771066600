import React from "react";
import styles from "./PersonalServices.module.scss";
import CreditCard from "components/common/CreditCard/CreditCard";
import { Tr } from "utils/i18n";

const PersonalServices = ({ ...props }) => {
  return (
    <>
      {props.personalServices && props.personalServices.length > 0 && (
        <div className="container">
          <div className={styles.AddPadding}>
            <div className={styles.Tit}>
              <p className={`TitleFont ${styles.Title}`}>
                <Tr tr="OurServices:PERSONAL_SERVICES" />
              </p>
              <p className={`DescFont ${styles.Desc}`}>
                {Tr.tr("OurServices:PERSONAL_SERVICES_DESCRIPTION")}
              </p>
            </div>
            <div className="row">
              {/* Loop over CreditCard card and appear them based on Array Number */}
              {props.personalServices &&
                props.personalServices.map((services, index) => (
                  <div className="col-12 col-md-6 col-lg-4" key={services.id}>
                    <CreditCard
                      key={services.id}
                      requestPath={`/profile-services/${services.id}`}
                      detailsPath={`/services-details?id=${services.id}`}
                      imgSrc={
                        services.image !== null
                          ? services.image
                          : "../img/creditcard.png"
                      }
                      title={services.name}
                      desc={services.description}
                      isApplied={services.is_applied}
                      userServiceId={services.user_service_id}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalServices;
