import React from "react";
import styles from "./Profile.module.scss";

import SideBar from "components/SideBar";
import PersonalTable from "./components/PersonalTable/PersonalTable";
const Profile = () => {
  return (
    <div className={styles.Profile}>
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            <PersonalTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
