import axios from "axios";
import api from "../env";

const httpDriver = axios.create({
	baseURL: api.API_BASE_URL,
	headers: {
		"Accept-Language": localStorage.getItem("i18nextLng") || "en",
	},
});

export default httpDriver;
