export const en = {
  request_service: "Request a Service",
  type_required: "TYPE OF FACILITY REQUIRED",
  amount_required: "AMOUNT OF FACILITY REQUIRED",
  income: "MONTHLY INCOME",
  submit: "SUBMIT",
  request_service_desc:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
  international_money_transfer: "INTERNATIONAL MONEY TRANSFER",
  international_money_transfer_paragraph:
    "Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut",
  process_customer: "Customer",
  process_customer_title: "Register",
  process_customer_description:
    "El Momawel will review and validate the customer request, then sends the customer’s request to all banks.",
  process_elMomawel: "El Momawel",
  process_elMomawel_title: "Process and validate customer’s request",
  process_elMomawel_description:
    "El Momawel will review and validate the customer request, then sends the customer’s request to all banks.",
  process_Banks_commercial: "Banks and commercial lenders",
  process_Banks_commercial_title: "Study the customer request",
  process_Banks_commercial_description:
    "El Momawel will review and validate the customer request, then sends the customer’s request to all banks.",
};

export const ar = {
  request_service: "طلب خدمة",
  type_required: "نوع التسهيل المطلوب",
  amount_required: "مقدار التسهيلات المطلوبة",
  income: "الدخل الشهري",
  submit: "تقديم",
  request_service_desc:
    "ستقوم “الممول” بمراجعة طلب العميل والتحقق من صحته ، ثم ترسل طلب العميل",
  international_money_transfer: "تحويل الأموال الدولي",
  international_money_transfer_paragraph:
    "ستقوم “الممول” بمراجعة طلب العميل والتحقق من صحته ، ثم ترسل طلب العميل إلى جميع البنوك.",
  process_customer: "عميل",
  process_customer_title: "تسجيل",
  process_customer_description:
    "ستقوم “الممول” بمراجعة طلب العميل والتحقق من صحته ، ثم ترسل طلب العميل إلى جميع البنوك.",
  process_elMomawel: "الممول",
  process_elMomawel_title: "العملية والتحقق من صحة طلب العميل",
  process_elMomawel_description:
    "ستقوم “الممول” بمراجعة طلب العميل والتحقق من صحته ، ثم ترسل طلب العميل إلى جميع البنوك .",
  process_Banks_commercial: "البنوك والمقرضين التجاريين",
  process_Banks_commercial_title: "دراسة طلب العملاء",
  process_Banks_commercial_description:
    "ستقوم “الممول” بمراجعة طلب العميل والتحقق من صحته ، ثم ترسل طلب العميل إلى جميع البنوك.",
};
