import React from "react";
import styles from "./LanguageDropDown.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { withChangeLang } from "components/hocs/withChangeLang";

const LanguageDropDown = ({
  currLang, //the current language of the page
  changeLang, //method that changes the language to the required language
  ...props
}) => {
  return (
    <>
      {props.responsive ? (
        <Dropdown className={styles.LanguageDropDown}>
          <Dropdown.Toggle as="span" bsPrefix="DescFont SignLink">
            {currLang === "ar" ? "العربية" : "ENGLISH"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <>
              <span
                className="DescFont SignLink text-center Lato"
                onClick={() => changeLang("en")}
                style={{ color: currLang === "en" ? "#e22523" : null }}
              >
                ENGLISH
              </span>
              <span
                className="DescFont SignLink text-center"
                onClick={() => changeLang("ar")}
                style={{ color: currLang === "ar" ? "#e22523" : null }}
              >
                العربية
              </span>
            </>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          <span
            className="DescFont SignLink text-center Lato"
            onClick={() => changeLang("en")}
            style={{ color: currLang === "en" ? "#e22523" : null }}
          >
            ENGLISH
          </span>
          <span
            className="DescFont SignLink text-center"
            onClick={() => changeLang("ar")}
            style={{ color: currLang === "ar" ? "#e22523" : null }}
          >
            العربية
          </span>
        </>
      )}
    </>
  );
};

export default withChangeLang()(LanguageDropDown);
