import React from "react";
import styles from "./Loader.module.scss";

const Loader = ({ ...props }) => {
  let countVal = 0;
  const loaderInterval = setInterval(function() {
    countVal = countVal + 1;
    if(document.getElementById("loaderPersent")){
      document.getElementById("loaderPersent").innerHTML = countVal;
      document.getElementById("barFill").style.width = countVal + "%";
      if (countVal === 100) {
        clearInterval(loaderInterval);
        document
            .getElementById("loader")
            .setAttribute("style", "opacity: 0; visibility: hidden;");
        document.body.classList.add("ScrollAuto");
      }
    }

  }, 500 / 100);
  return (
    <div className={styles.Loader} id="loader">
      <div className={styles.LoaderContent}>
        <div className={`${styles.Persent} Other`}>
          <span className={styles.Num} id="loaderPersent"></span>%
        </div>
        <div className={styles.Bar}>
          <div className={styles.BarFill} id="barFill" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
