import React, { Component } from "react";
import styles from "./RejectWithdrawal.module.scss";
import { Tr } from "utils/i18n";

import { withRouter } from "react-router-dom";

import { Formik, ErrorMessage } from "formik";
import { TextAreaField, Field } from "components/fields/index";

import { connect } from "react-redux";
import { rejectWithdrawal, loadRejectReasons } from "redux/profile/actions";

import Select from "react-select";

class RejectWithdrawal extends Component {
  state = {
    errorMessage: "",
    rejectReasons: null,
    othersInput: false,
  };

  componentDidMount() {
    this.props.loadRejectReasons().then((reasons) => {
      this.setState({ rejectReasons: reasons.data.data });
    });
  }

  onChangeMultiSelect = (selected, setFieldValue, name) => {
    if (selected && selected.length !== 0) {
      setFieldValue(name, selected);
      selected.forEach((sel) => {
        if (sel.value === 24) {
          this.setState({ othersInput: true });
        } else {
          this.setState({ othersInput: false });
          setFieldValue("other", "");
        }
      });
      // this.setState({ othersInput: true });
    } else {
      setFieldValue("other", "");
      setFieldValue(name, "");
      this.setState({ othersInput: false });
    }
  };

  // Scroll To top after catching errors [400] [Bad Request]
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { location, history } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          reasons: "",
          comment: "",
          withdrawal_id: this.props.withdrawalId,
          other: "",
        }}
        validate={(values) => {
          let errors = {};
          if (!values.reasons) {
            errors.reasons = Tr.tr("Forms:err_requierd", {
              field: Tr.tr("serviceProvider:reject_reason"),
            });
          }
          if (!values.comment)
            errors.comment = Tr.tr("Forms:err_requierd", {
              field: Tr.tr("serviceProvider:comment"),
            });
          if (!values.other && this.state.othersInput)
            errors.other = Tr.tr("Forms:err_requierd", {
              field: Tr.tr("serviceProvider:reject_other"),
            });

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          values.withdrawal_id = parseInt(values.withdrawal_id);
          let value = {};
          Object.keys(values).forEach((v) => {
            if (v === "reasons") {
              let options = [];
              values[v].forEach((opt) => {
                options.push(opt.value);
              });
              value[v] = options;
            } else {
              value[v] = values[v];
            }
          });
          this.props
            .rejectWithdrawal(value)
            .then((result) => {
              if (location.state && location.state.returned) {
                history.push("/provider-returned");
              } else {
                history.push("/provider-withdrawal");
              }
            })
            .catch((res) => {
              try {
                if (res.error.response.data.data) {
                  setErrors(res.error.response.data.data);
                  this.setState({
                    errorMessage: res.error.response.data.message,
                  });
                } else {
                  this.props.setMessage(res.error.response.data.message);
                  this.scrollUp();
                }
              } catch (err) {
                console.error(err);
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={styles.RejectWithdrawal}>
            <div className={styles.RejectCase}>
              <div className="row">
                <div className="col-lg-4">
                  <div className={`InputGroup ${styles.SelectReason}`}>
                    <label htmlFor="" className="Required">
                      {Tr.tr("serviceProvider:reject_reason")}
                    </label>
                    <Select
                      onChange={(value) =>
                        this.onChangeMultiSelect(
                          value,
                          setFieldValue,
                          "reasons"
                        )
                      }
                      name="reasons"
                      label={Tr.tr("serviceProvider:reject_reason")}
                      options={
                        this.state.rejectReasons &&
                        this.state.rejectReasons.map((reason) => {
                          return reason;
                        })
                      }
                      value={values.reasons}
                      isSearchable={false}
                      placeholder={Tr.tr("Forms:select")}
                      autoFocus={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isMulti
                      required
                    />
                    <div className="err">
                      <ErrorMessage name="reasons" />
                    </div>
                  </div>
                </div>
                {this.state.othersInput && (
                  <div className="col-lg-8">
                    <Field
                      name="other"
                      label={Tr.tr("serviceProvider:reject_other")}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.other}
                      touched={touched}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="InputGroup">
                <TextAreaField
                  name="comment"
                  label={Tr.tr("serviceProvider:reject_comment")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comment}
                  touched={touched}
                  required
                />
                <div className="err">
                  <ErrorMessage name="comment" />
                </div>
              </div>
              <button type="submit" className="Button">
                {Tr.tr("Forms:submit")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default connect(null, { rejectWithdrawal, loadRejectReasons })(
  withRouter(RejectWithdrawal)
);
