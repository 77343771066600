import { combineReducers } from "redux";
import mainReducer from "./main/reducer";
import authReducer from "./auth/reducer";
import homeReducer from "./home/reducer";
export default function createRootReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth: authReducer,
    main: mainReducer,
    home: homeReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
