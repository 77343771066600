import React, { useState } from "react";
import styles from "./OfferDetails.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";

import Modal from "components/common/ModalComponent/ModalComponent";

const OfferDetails = ({ bank, ...props }) => {
  // Show or Hide state based on show value
  const [show, setShow] = useState(false);

  // Show modal if show value is true [setShow(true)]
  const handleShow = () => {
    setShow(true);
  };

  // Hide modal if show value is false [setShow(false)] [Sign Out Modal]
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Offers}>
        <div className={styles.OffersContent}>
          <div className={styles.Img}>
            <img src={bank.logo} alt="offer details" />
          </div>
          <div className="OtherBl">
            <span className={`Other ${styles.Date}`}>{props.date}</span>

            <p className={styles.Desc}>
              <span>{props.offerTitle} </span>
            </p>

            <div className={styles.Details}>
              <div
                className={`Other ${styles.Text}`}
                dangerouslySetInnerHTML={{ __html: props.offerDesc }}
              ></div>
            </div>
          </div>
        </div>
        <button
          className={`Button White ${styles.Button}`}
          title={Tr.tr("Profile:APPLIED_SERVICE")}
          onClick={handleShow}
        >
          <Tr tr="basic:Request_service" />
        </button>
        {props.is_applied === 0 ? (
          <Link
            to={`/profile-services/${props.service_id}`}
            className={`Button White ${styles.Button}`}
          >
            <Tr tr="basic:Request_service" />
          </Link>
        ) : (
          <button
            className={`Button White ${styles.Button}`}
            title={Tr.tr("Profile:APPLIED_SERVICE")}
            onClick={handleShow}
          >
            <Tr tr="basic:Request_service" />
          </button>
        )}
      </div>
      {/* Applied Service Modal */}
      <Modal show={show} close={handleClose}>
        <span className="TitleFont SignOutModal">
          <Tr tr="Profile:APPLIED_SERVICE" />
        </span>
        <button className="Button White ModalLeftButton" onClick={handleClose}>
          {Tr.tr("basic:cancel")}
        </button>
        <Link
          to={`/personal-request-details/${props.request_id}`}
          className="Button ModalRightButton"
        >
          {Tr.tr("Profile:VIEW_THE_REQUEST")}
        </Link>
      </Modal>
    </div>
  );
};

export default OfferDetails;
