import React, { Component } from "react";
import styles from "./Footer.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { subscribe, loadSettings } from "redux/main/actions";

import { withChangeLang } from "components/hocs/index";

const SUCCESS_MESSAGE = Tr.tr("Footer:subscribed_SUCCESS_MESSAGE");
const INITIAL_STATE = {
	email: "",
	message: "",
	isError: false,
	isSuccess: false,
};
class Footer extends Component {
	state = {
		...INITIAL_STATE,
		social: null,
		allrightReserved: null,
	};

	scrollUp = () => {
		window.scrollTo(0, 0);
	};

	componentDidMount() {
		let { currLang } = this.props;
		this.props.loadSettings().then((res) => {
			this.setState({
				social: res.data.data,
				allrightReserved: res.data.data[`all_rigth_reseved_${currLang}`],
			});
		});
	}

	resetState = () => {
		setTimeout(() => this.setState({ ...INITIAL_STATE }), 3000);
	};

	handleSubmit = (e) => {
		if (this.state.email.trim() === "") return;
		if (
			!/^[valuesA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)
		) {
			this.setState(
				{ isError: true, message: `${Tr.tr("Footer:error_invalid_email")}` },
				() => this.resetState()
			);
			return;
		}
		this.props
			.subscribe({ email: this.state.email })
			.then((res) => {
				this.setState({ isSuccess: true, message: SUCCESS_MESSAGE }, () =>
					this.resetState()
				);
			})
			.catch((error) => {
				this.setState(
					{ isError: true, message: error.error.response.data.data.email },
					() => this.resetState()
				);
			});
	};

	render() {
		let socialLinks = this.state.social;
		return (
			<footer>
				<div className="container footerContainer">
					<img src="/img/logo.png" alt="logo" className={styles.Logo} />
					<div className={styles.FooterHolder}>
						<div className="row">
							<div className="col-6 col-sm-4 col-lg-2">
								<div className={styles.FooterSection}>
									<p className="FooterTabs">{Tr.tr("Footer:company")}</p>
									<Link to="/about" className="DescFont">
										<Tr tr="Footer:about_us" />
									</Link>
									<Link to="/our-services" className="DescFont">
										<Tr tr="Footer:our_serv" />
									</Link>
									<Link to="/our-clients" className="DescFont">
										<Tr tr="Footer:our_clients" />
									</Link>
								</div>
							</div>
							<div className="col-6 col-sm-4 col-lg-3">
								<div className={styles.FooterSection}>
									<p className="FooterTabs">{Tr.tr("Footer:support")}</p>
									<Link to="/contact-us" className="DescFont">
										<Tr tr="Footer:contact_us" />
									</Link>
									<Link to="/faq" className="DescFont">
										<Tr tr="Footer:faq" />
									</Link>
								</div>
							</div>
							<div className="col-12 col-sm-4 col-lg-3">
								<div className={styles.FooterSection}>
									<p className="FooterTabs">{Tr.tr("Footer:social_media")}</p>
									{socialLinks && (
										<div className={styles.FooterIcon}>
											<a
												href={socialLinks.linked_id_link}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="icon-linkedin" />
											</a>
											<a
												href={socialLinks.facebook_link}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="icon-facebook" />
											</a>
										</div>
									)}
								</div>
							</div>
							<div className="col-12 col-sm-8 col-lg-4">
								<div className={`${styles.FooterSection} ${styles.Subscribe}`}>
									<p className="FooterTabs">{Tr.tr("Footer:subscribe_now")}</p>
									<p className="Other">{Tr.tr("Footer:subscribe_desc")}</p>
									<p
										style={{ color: this.state.isError ? "red" : "green" }}
										className="subscribe-err"
									>
										{(this.state.isSuccess || this.state.isError) &&
											this.state.message}
									</p>
									<div className="subscribe-form">
										<input
											type="email"
											placeholder={Tr.tr("Footer:email_address")}
											name="email"
											value={this.state.email}
											onChange={(e) => this.setState({ email: e.target.value })}
											onKeyUp={(e) => e.keyCode === 13 && this.handleSubmit()}
											disabled={this.state.isError || this.state.isSuccess}
										/>
										<button
											className={styles.BtnSubmit}
											onClick={this.handleSubmit}
										>
											<span className={`icon-next ${styles.NextIcon}`} />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.Separator} />
					<div className={styles.LastSection}>
						<p className={`DescFont ${styles.CopyRights}`}>
							{/* {Tr.tr("Footer:copy_rights")} */}
							{this.state.allrightReserved}
						</p>
						<div className={styles.TermsPrivacy}>
							<Link to="/terms-conditions" className="DescFont">
								{Tr.tr("Footer:terms_conditions")}
							</Link>
							<span>|</span>
							<Link to="/privacy-policy" className="DescFont">
								{Tr.tr("Footer:privacy_policy")}
							</Link>
						</div>
					</div>
					<button className="Button ScrollUp" onClick={this.scrollUp}>
						<i className="icon-upload icon"></i>
					</button>
				</div>
			</footer>
		);
	}
}

export default connect(null, { subscribe, loadSettings })(
	withChangeLang()(Footer)
);
