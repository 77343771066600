import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "utils/useAuthHook";

/***
 * if you are auth and need to access not allow route
 * will render default component from config auth file
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
export const Guest = ({ component: Component, ...rest }) => {
  // let [, token] = useAuth();
  let token = localStorage.getItem("api_token");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" {...props} />;
        }
      }}
    />
  );
};
