import React, { Component } from "react";
import styles from "./RequestService.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import { Tr } from "utils/i18n";
import { connect } from "react-redux";
import RequestServiceForm from "./RequestServiceForm";
import { USERS_TYPES } from "utils/constants/usersTypes";
import { changeQuickForm } from "redux/home/actions";
import i18n from "i18next";

class RequestService extends Component {
  state = {
    currDirection: i18n.language === "ar" ? "rtl" : "ltr",
  };

  render() {
    const {
      authUserType,
      corporate_type_of_facility,
      personal_type_of_facility,
      changeQuickForm,
    } = this.props;
    return (
      <div className={styles.RequestService}>
        <div className={styles.Info}>
          <p className={`SubTitle ${styles.SubTitle}`}>
            <Tr tr="Home:request_service" />
          </p>
          {/* <p className={`DescFont ${styles.DescFont}`}>
            {Tr.tr("Home:request_service_desc")}
          </p> */}
        </div>
        <Tabs
          bsPrefix={styles.RequestServiceTabs}
          {...(authUserType &&
            authUserType !== USERS_TYPES.Approval &&
            authUserType !== USERS_TYPES.Maker && {
              activeKey: authUserType,
            })}
          onSelect={() => changeQuickForm("type_of_facility", null)}
        >
          <Tab
            eventKey="personal"
            title={Tr.tr("basic:personal")}
            className="DescFont"
            {...(authUserType && {
              disabled: authUserType === USERS_TYPES.Corporate,
            })}
          >
            <RequestServiceForm
              currDirection={this.state.currDirection}
              facilityOptions={personal_type_of_facility}
            />
          </Tab>
          <Tab
            eventKey="corporate"
            title={Tr.tr("basic:corporate")}
            className="DescFont"
            {...(authUserType && {
              disabled: authUserType === USERS_TYPES.Personal,
            })}
          >
            <RequestServiceForm
              corporate
              currDirection={this.state.currDirection}
              facilityOptions={corporate_type_of_facility}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { type: authUserType },
  home: {
    data: { personal_type_of_facility, corporate_type_of_facility },
  },
}) => ({
  authUserType,
  personal_type_of_facility,
  corporate_type_of_facility,
});
export default connect(mapStateToProps, { changeQuickForm })(RequestService);
