import React, { Component } from "react";
import styles from "./Proces.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

class Proces extends Component {
  // number of items showed in each breakpoint in the slider in responsive in [Home] page
  state = {
    responsive: {
      0: {
        items: 1,
      },
      769: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  owlOnChange = () => {
    const firstActive = document.querySelectorAll(".home-proces .active");
    const oldActive = document.querySelectorAll(".home-proces .owl-item");
    if (firstActive.length > 0) {
      oldActive.forEach(function (el) {
        el.classList.remove("first-active");
      });
      setTimeout(() => {
        const firstActive = document.querySelectorAll(".home-proces .active");
        if (firstActive[0]) {
          firstActive[0].classList.add("first-active");
        }
      }, 100);
    }
  };

  setProcessActive = (index) => {
    const oldActive = document.querySelectorAll(".home-proces .owl-item");
    oldActive.forEach(function (el) {
      el.classList.remove("first-active");
    });
    oldActive[index].classList.add("first-active");
  };

  componentDidUpdate() {
    // if condition because there is an error
    if (document.querySelectorAll(".home-proces .active")[0]) {
      document
        .querySelectorAll(".home-proces .active")[0]
        .classList.add("first-active");
    }
  }

  render() {
    const processSlider = [
      {
        icon: "../img/customer.png",
        title: Tr.tr("Home:process_customer"),
        processTitle: Tr.tr("Home:process_customer_title"),
        processDesc: Tr.tr("Home:process_customer_description"),
      },
      {
        icon: "../img/elMomawel.png",
        title: Tr.tr("Home:process_elMomawel"),
        processTitle: Tr.tr("Home:process_elMomawel_title"),
        processDesc: Tr.tr("Home:process_elMomawel_description"),
      },
      {
        icon: "../img/banksCommercialLenders.png",
        title: Tr.tr("Home:process_Banks_commercial"),
        processTitle: Tr.tr("Home:process_Banks_commercial_title"),
        processDesc: Tr.tr("Home:process_Banks_commercial_description"),
      },
    ];
    return (
      <div className={`${styles.Proces} SectionPadding`}>
        <div className="container">
          <div className="TitleContainer">
            <h1 className="TitleFont SubPadding">
              <Tr tr="our_services" />
            </h1>
            <Link to="/our-services" className="DescFont">
              <Tr tr="see_all" />
            </Link>
          </div>
          <h2 className={`SubTitle SubPadding ${styles.SubTitle}`}>
            <Tr tr="basic:process" />
          </h2>
          <div className="row">
            <div className="col-11">
              <OwlCarousel
                className="owl-theme home-proces"
                responsive={this.state.responsive}
                margin={30}
                items={3}
                loop
                autoplay
                autoplayHoverPause
                autoplayTimeout={5000}
                autoplaySpeed={1000}
                nav
                navText={[
                  '<i class="icon-previous" />',
                  '<i class="icon-next" />',
                ]}
                onChanged={this.owlOnChange}
                dots={false}
              >
                {processSlider.map((el, index) => (
                  <div className="item" key={index}>
                    {/* onClick={() => this.setProcessActive(index)} */}
                    <div className={styles.ProcessBox}>
                      <div className={styles.Img}>
                        <img src={el.icon} alt="alider icon" />
                      </div>
                      <p className={`SubTitle ${styles.Title}`}>{el.title}</p>
                      <span className={styles.Bullet} />
                      <p className={`SubTitle ${styles.SubTitle}`}>
                        {el.processTitle}
                      </p>
                      <p className={`DescFont ${styles.Desc}`}>
                        {el.processDesc}
                      </p>
                    </div>
                  </div>
                ))}
                {/* <div className="item">
                  <div className={styles.ProcessBox}>
                    <div className={styles.Img}>
                      <img src="../img/elMomawel.png" />
                    </div>
                    <p className={`SubTitle ${styles.Title}`}>El Momawel</p>
                    <span className={styles.Bullet} />
                    <p className={`SubTitle ${styles.SubTitle}`}>
                      Process and validate customer’s request
                    </p>
                    <p className={`DescFont ${styles.Desc}`}>
                      El Momawel will review and validate the customer request,
                      then sends the customer’s request to all banks and…..
                    </p>
                  </div>
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Proces;
