import React from "react";
import { Tr } from "utils/i18n";
import styles from "../Search.module.scss";

export default function ResultCount({ count }) {
  return (
    <div className={styles.ResultsFound}>
      <span className="DescFont">
        <Tr tr="Search:RESULTS_FOUND" />
      </span>
      <span className={styles.Separator}></span>
      <span className="DescFont">
        {count} {""}
        <Tr tr="Search:results" />
      </span>
    </div>
  );
}
