import React, { useState } from "react";
import styles from "./SideBar.module.scss";
import SideBarTabs from "../SideBarTabs/SideBarTabs";
import { withRouter } from "react-router-dom";

const SideBar = ({
  routes, //object that containes all the sidebar tabs
  children,
  location: { pathname },
  ...props
}) => {
  const [Show, setShow] = useState(false);
  function toggleShow() {
    setShow(!Show);
  }

  let selectedTab =
    routes &&
    routes.length > 0 &&
    routes.filter((obj) => {
      return (
        obj.pathName === props.match.url ||
        (obj.subRoutes && obj.subRoutes.includes(pathname.split("/")[1]))
      );
    });

  return (
    <div className={styles.SideBarHolder}>
      {/* ADD (ACTIVE) CLASS HERE*/}
      <div
        onClick={toggleShow}
        className={`d-flex d-lg-none SideTitleResp
        ${Show ? styles.active : null}`}
      >
        <i className={`icon-dropdown ${styles.DropdownIcon}`}></i>

        <SideBarTabs {...selectedTab[0]} />
      </div>

      {/* IN RESPONSIVE: ADD (SHOW) CLASS TO (SIDE-BAR) TO SHOW THE DROPDOWN*/}
      <div className={`${styles.SideBar} ${Show ? styles.Show : null}`}>
        {routes &&
          routes.length > 0 &&
          routes.map((prop, index) => <SideBarTabs {...prop} key={index} />)}
        {children}
      </div>
    </div>
  );
};

export default withRouter(SideBar);
