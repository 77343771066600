export const en = {
  dir: "ltr",
  yes: "yes",
  no: "no",
  cancel: "CANCEL",
  ok: "ok",
  search: "search",
  sign_in: "SIGN IN",
  sign_up: "SIGN UP",
  sign_out: "SIGN OUT",
  language: "LANGUAGE",
  Request_service: "Request service",
  explore: "EXPLORE",
  save_changes: "SAVE CHANGES",
  save_changes_load: "SAVE CHANGES...",
  back: "BACK",
  questions: "questions",
  personal: "Personal",
  corporate: "Corporate",
  our_services: "Our services",
  process: "process",
  services: "services",
  our_clients: "our clients",
  latest_news: "latest news",
  testimonials: "Testimonials",
  see_all: "SEE ALL",
  all_notifications: "All Notifications",
  notifications: "Notifications",
  wrong_email_or_password: "Wrong Email Or Password",
  email: "E-Mail",
  password: "Password",
  forget_password: "Forget Password",
  dont_have_account: "Don't Have Account",
  remember_me: "Remember Me",
  location: "Location",
  phone: "Phone",
  fax: "Fax",
  Notification_settings: "NOTIFICATION SETTINGS",
  personal_services: "Personal Services",
  corporate_services: "Corporate Services",
  i_need: "I Need A",
  english: "ENGLISH",
  arabic: "ARABIC",
  next: "NEXT",
  no_results_found: "no results found",
  no_available_data: "NO AVAILABLE DATA",
  male: "Male",
  female: "Female",

  maker_status: "Maker status",
  download_file: "Download File",
  maker_comment: "Maker Comment",
  approver_comment: "Approver return comment",

  there_are_no_data: "THERE ARE NO DATA",
  date_format: "YYYY-MM-DD",
  maker: "maker",
  approval: "approval",
};

export const ar = {
  dir: "rtl",
  yes: "نعم",
  no: "لا",
  cancel: "إلغاء",
  ok: "حسناً",
  search: "بحث",
  sign_in: "تسجيل دخول",
  sign_up: "انشاء حساب",
  sign_out: "الخروج",
  language: "اللغة",
  Request_service: "طلب خدمة",
  explore: "اكتشف",
  save_changes: "حفظ التعديلات",
  save_changes_load: "حفظ التعديلات...",
  back: "الرجوع",
  questions: "الأسئلة",
  personal: "شخصي",
  corporate: "الشركات",
  our_services: "خدماتنا",
  process: "العملية",
  services: "الخدمات",
  our_clients: "عملائنا",
  latest_news: "آخر الأخبار",
  testimonials: "الشهادات",
  see_all: "مشاهدة الكل",
  all_notifications: "كل الإشعارات",
  notifications: "إشعارات",
  wrong_email_or_password: "Wrong Email Or Password",
  email: "البريد الالكتروني",
  password: "كلمة المرور",
  forget_password: "Forget Password",
  dont_have_account: "Don't Have Account",
  remember_me: "Remember Me",
  location: "الموقع",
  phone: "رقم الهاتف",
  fax: "الفاكس",
  Notification_settings: "ضبط التنبيهات",
  personal_services: "Personal Services",
  corporate_services: "Corporate Services",
  i_need: "انا احتاج الي..",
  english: "ENGLISH",
  arabic: "ARABIC",
  next: "التالي",
  no_results_found: "لا توجد نتائج مطابقة",
  no_available_data: "لا توجد بيانات متاحة",
  male: "ذكر",
  female: "انثي",

  maker_status: "حالة الصانع",
  download_file: "تحميل الملف",
  maker_comment: "تعليق الصانع",
  approver_comment: "تعليق المؤيد",

  there_are_no_data: "لا توجد بيانات",
  date_format: "يوم-شهر-سنة",
  maker: "صانع",
  approval: "مؤيد",
};
