import React, { useEffect, useState } from "react";
import styles from "./ProviderSelectService.module.scss";
import { Tr } from "utils/i18n";
import { withRouter } from "react-router-dom";

import SideBar from "components/SideBar";
import NumbersCard from "components/common/NumbersCard/NumbersCard";
import { connect } from "react-redux";
import {
  getMakerWithDrawal,
  getApprovalWithDrawal,
} from "redux/profile/actions";
import { loadServices } from "redux/ourServices/actions";
import MyCasesTable from "./components/ServiceTable/MyCasesTable";
import { useAuth } from "utils/useAuthHook";

import { Response } from "../../utils/Response";
import { usePagination } from "utils/usePagination";

import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";
import TableTitle from "components/common/TableTitle/TableTitle";

const ProviderSelectService = ({ ...props }) => {
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);
  const [type, setType] = useState(props.match.params.type);
  const [id, setId] = useState(props.match.params.id);
  const [boxes, setboxes] = useState(null);
  let [userType] = useAuth();

  const [serviceName, setServiceName] = useState("");

  // Loader & Pagination
  const [response, setResponse] = useState(Response);

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  const { page, loading, setLoading, onPaginate } = usePagination();

  // sort
  const [order, setorder] = useState("");
  const [sortType, setsortType] = useState("asc");

  // onSort function
  const onSort = (order, type) => {
    setorder(order);
    setsortType(type);
  };

  const action = (id) => {
    return userType === "approval"
      ? props.getApprovalWithDrawal(id, { page, type: sortType, order })
      : props.getMakerWithDrawal(id, { page, type: sortType, order });
  };
  useEffect(() => {
    action(id)
      .then((result) => {
        if (result.data) {
          setLoading(false);
          setResponse(result.data.data.applications);
          setRows(result.data.data.applications.data);
          setboxes(result.data.data.count);
          setServiceName(result.data.data.service_name);
        }
      })
      .catch((res) => {
        console.log(res);
        if (res.error) {
          if (res.error.response.data.message) {
            setLoading(false);
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });

    props
      .loadServices()
      .then((result) => {
        if (result.data.data[type]) {
          setServices(result.data.data[type]);
        }
      })
      .catch((res) => {
        console.log(res.error);
        if (res.error) {
          if (res.error.response.data.message) {
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });
    return () => false;
  }, [id, page, sortType, order]);

  // console.log(props.match.params.type);
  // const { type } = props.match.params;
  return (
    <div className="AddPadding">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!message ? (
              <>
                {boxes
                  ? (userType === "maker" && (
                      <div className="row">
                        {/* <div className="col-lg-4">
                          <NumbersCard
                            desc={Tr.tr("serviceProvider:num_applications")}
                            number={boxes.all_application_count}
                          />
                        </div> */}
                        <div className="col-lg-4">
                          <NumbersCard
                            desc={Tr.tr("serviceProvider:withdraweled")}
                            number={boxes.withdrawal_count}
                          />
                        </div>
                        <div className="col-lg-4">
                          <NumbersCard
                            desc={Tr.tr("serviceProvider:num_app_today")}
                            number={boxes.application_today_count}
                          />
                        </div>
                      </div>
                    )) || (
                      <div className="row">
                        <div className="col-lg-4">
                          <NumbersCard
                            desc={Tr.tr(
                              "serviceProvider:current_withdrawaled_cases"
                            )}
                            number={boxes && boxes.current_withdrawaled_cases}
                          />
                        </div>
                        <div className="col-lg-4">
                          <NumbersCard
                            desc={Tr.tr(
                              "serviceProvider:accepted_withdarwaled_cases"
                            )}
                            number={boxes && boxes.accepted_withdarwaled_cases}
                          />
                        </div>
                        <div className="col-lg-4">
                          <NumbersCard
                            desc={Tr.tr(
                              "serviceProvider:total_withdrawaled_cases"
                            )}
                            number={boxes && boxes.total_withdrawaled_cases}
                          />
                        </div>
                      </div>
                    )
                  : null}
                <TableTitle {...{ serviceName, type }} />

                <MyCasesTable
                  rows={rows}
                  response={response}
                  onPaginate={onPaginate}
                  loading={loading}
                  {...{ sortType, order, onSort }}
                />
              </>
            ) : (
              <BadRequestMessage
                message={message}
                redirectPage="/provider-withdrawal"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProviderSelectService.defaultProps = {};

ProviderSelectService.propTypes = {};

export default connect(null, {
  getMakerWithDrawal,
  getApprovalWithDrawal,
  loadServices,
})(withRouter(ProviderSelectService));
