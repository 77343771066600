export const en = {
  Home: "Home",
  about_us: "About Us",
  our_services: "our services",
  our_clients: "our clients",
  news: "news",
  request_ads: "REQUEST ADD",
  request_call: "REQUEST A CALL",
};

export const ar = {
  Home: "الرئيسية",
  about_us: "من نحن",
  our_services: "خدماتنا",
  our_clients: "عملائنا",
  news: "الأخبار",
  request_ads: "طلب اعلان",
  request_call: "طلب مكالمة",
};
