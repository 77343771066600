import React from "react";
import styles from "./SideBarTabs.module.scss";
import { NavLink, withRouter } from "react-router-dom";

const SideBarTabs = ({
  iconName,
  tabName,
  type,
  location: { pathname },
  ...props
}) => {
  const isActiveTab =
    props.subRoutes && props.subRoutes.includes(pathname.split("/")[1]);

  const item =
    type === "span" ? (
      <span className={`${styles.SideBarContent}`}>
        <i className={`${iconName} ${styles.SideIcon}`} />
        <span className={`${styles.SideLink} DescFont`}>{tabName}</span>
      </span>
    ) : (
      <NavLink
        className={`${styles.SideBarContent} ${
          isActiveTab ? styles.active : null
        }`}
        activeClassName={styles.active}
        to={props.pathName ? props.pathName : "null"}
      >
        {!props.iconImage && <i className={`${iconName} ${styles.SideIcon}`} />}
        {props.iconImage && (
          <span className={styles.iconImage}>
            <img src={props.iconImage} />
            <img src={props.iconImageHover} />
          </span>
        )}
        <span className={`${styles.SideLink} DescFont`}>{tabName}</span>
      </NavLink>
    );
  return item;
};

export default withRouter(SideBarTabs);
