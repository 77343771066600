import React, { Component } from "react";
import { ErrorMessage } from "formik";
import styles from "./Personal.module.scss";
import { Tr } from "utils/i18n";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import ScrollToError from "components/common/ScrollToError";
import moment from "moment";

import { Formik } from "formik";
import { Field, CheckBoxField } from "components/fields/index";

import { personalUserRegister } from "redux/auth/actions";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ar from "date-fns/locale/ar-SA";
const currLang = localStorage.getItem("i18nextLng");

class Personal extends Component {
  state = {
    errorMessage: "",
    startDate: null,
  };

  onChangeGender(selected, setFieldValue) {
    setFieldValue("gender", selected);
  }

  onChangeBirthDate(selected, setFieldValue) {
    setFieldValue("date_of_birth", selected);
    this.setState({ startDate: selected });
  }

  handleChangeInput(name, value, setFieldValue) {
    setFieldValue(name, value);
  }

  render() {
    const dateFormat = "YYYY-MM-DD";
    return (
      <div className={styles.Personal}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name: "",
            middle_name: "",
            last_name: "",
            date_of_birth: null,
            gender: "",
            phone: "",
            email: "",
            password: "",
            password_confirmation: "",
            remember: false,
            lang: "",
          }}
          validate={(values) => {
            let errors = {};
            if (!values.first_name.trim())
              errors.first_name = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:first_name"),
              });
            if (!values.middle_name.trim())
              errors.middle_name = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:middle_name"),
              });
            if (!values.last_name.trim())
              errors.last_name = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:last_name"),
              });
            if (!values.date_of_birth)
              errors.date_of_birth = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:date_picker"),
              });

            if (!values.gender)
              errors.gender = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:gender"),
              });
            if (!values.lang)
              errors.lang = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:pref_lang"),
              });
            if (!values.phone.trim())
              errors.phone = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:phone_number"),
              });
            else if (values.phone.length < 9) {
              errors.phone = Tr.tr("Forms:err_min", {
                num: 9 + Tr.tr("Forms:digit"),
              });
            } else if (!/^[0-9]+$/.test(values.phone)) {
              errors.phone = Tr.tr("Forms:err_invalid_phone");
            }

            if (!values.email)
              errors.email = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:email"),
              });
            else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = Tr.tr("Forms:invalid");
            }

            if (!values.password)
              errors.password = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:password"),
              });
            else if (
              !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}$/.test(values.password)
            ) {
              errors.password = Tr.tr("Forms:INVALID_PASSWORD_CONTENT");
            }

            if (!values.password_confirmation)
              errors.password_confirmation = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:password_confirmation"),
              });
            else if (values.password_confirmation !== values.password)
              errors.password_confirmation = Tr.tr("Forms:doesnt_match");
            if (!values.remember)
              errors.remember = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:not_robot"),
              });

            return errors;
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            let val = { ...values };
            val.type = "personal";
            val.gender = val.gender.value;
            val.lang = val.lang.value;
            val.date_of_birth = val.date_of_birth.toString().includes("GMT")
              ? moment(val.date_of_birth).locale("en").format(dateFormat)
              : val.date_of_birth;
            ///TO::DO will transform birth date before send to back end
            this.props
              .personalUserRegister(val)
              .then((result) => {
                if (this.props.history.location.pathname === "/sign-up") {
                  this.props.history.replace(`/`);
                } else {
                  this.props.history.replace(
                    this.props.history.location.pathname
                  );
                }
                this.setState({ errorMessage: "" });
              })
              .catch((res) => {
                try {
                  if (res.error.response.data !== undefined) {
                    setErrors(res.error.response.data.data);
                  }
                  this.setState({
                    errorMessage: res.error.response.data.message,
                  });
                } catch (err) {
                  console.error(err);
                }
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form action="" onSubmit={handleSubmit}>
              <Field
                name="first_name"
                label={Tr.tr("Forms:first_name")}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                touched={touched}
                required
              />
              <Field
                name="middle_name"
                label={Tr.tr("Forms:middle_name")}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                value={values.middle_name}
                required
              />
              <Field
                name="last_name"
                label={Tr.tr("Forms:last_name")}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
                touched={touched}
                required
              />

              <div className="InputGroup">
                <label htmlFor="" className="Required">
                  {Tr.tr("Forms:birth_date")}
                </label>
                <div className="DatePicker">
                  <DatePicker
                    name="date_of_birth"
                    selected={this.state.startDate}
                    onChange={(value) =>
                      this.onChangeBirthDate(value, setFieldValue)
                    }
                    value={values.date_of_birth}
                    className="Input"
                    dateFormat="yyyy-MM-dd"
                    placeholderText={Tr.tr("basic:date_format")}
                    locale={currLang === "ar" && ar}
                    autoComplete="off"
                    onKeyDown={(e) => e.preventDefault()}
                    maxDate={new Date().setDate(new Date().getDate() - 1)}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={121}
                  />
                  <i className="icon-calendar"></i>
                </div>
                <div className="err">
                  <ErrorMessage name="date_of_birth" />
                </div>
              </div>

              <div className="InputGroup">
                <label htmlFor="" className="Required">
                  {Tr.tr("Forms:gender")}
                </label>
                <div className="SelectPicker">
                  <Select
                    onBlur={() => setFieldTouched("gender", true)}
                    onChange={(value) =>
                      this.onChangeGender(value, setFieldValue)
                    }
                    name="gender"
                    options={[
                      { label: Tr.tr("basic:male"), value: "male" },
                      { label: Tr.tr("basic:female"), value: "female" },
                    ]}
                    value={values.gender}
                    isSearchable={false}
                    placeholder={Tr.tr("Forms:select")}
                    autoFocus={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
                <div className="err">
                  <ErrorMessage name="gender" />
                </div>
              </div>

              <Field
                name="phone"
                label={Tr.tr("Forms:phone_number")}
                type="tel"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                touched={touched}
                hint={Tr.tr("Forms:phone_hint")}
                required
                autoComplete="tel"
                maxLength={15}
              />

              <div className="InputGroup">
                <label htmlFor="" className="Required">
                  {Tr.tr("Forms:pref_lang")}
                </label>
                <div className="SelectPicker">
                  <Select
                    onBlur={() => setFieldTouched("lang", true)}
                    onChange={(value) =>
                      this.handleChangeInput("lang", value, setFieldValue)
                    }
                    name="lang"
                    options={[
                      { label: "العربية", value: "ar" },
                      { label: "English", value: "en" },
                    ]}
                    value={values.lang}
                    isSearchable={false}
                    placeholder={Tr.tr("Forms:select")}
                    autoFocus={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
                <div className="err">
                  <ErrorMessage name="lang" />
                </div>
              </div>

              <Field
                name="email"
                label={Tr.tr("Forms:email")}
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                touched={touched}
                required
              />
              <Field
                name="password"
                label={Tr.tr("Forms:password")}
                hint={Tr.tr("Forms:password_hint")}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                touched={touched}
                required
              />
              <Field
                name="password_confirmation"
                label={Tr.tr("Forms:password_confirmation")}
                hint={Tr.tr("Forms:password_hint")}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password_confirmation}
                touched={touched}
                required
              />
              <div className="InputGroup">
                <CheckBoxField
                  name="remember"
                  label={Tr.tr("Forms:not_robot")}
                  type="checkbox"
                  isChecked={values.remember}
                  checked={() => setFieldValue("remember", !values.remember)}
                />
                <div className="err">
                  <ErrorMessage name="remember" />
                </div>
              </div>
              <div className="LogBtns">
                {values.remember ? (
                  <button
                    disabled={isSubmitting}
                    style={{ opacity: isSubmitting ? 0.8 : 1 }}
                    className="Button"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <Tr tr="Forms:sign_up_load" />
                    ) : (
                      <Tr tr="Forms:sign_up" />
                    )}
                  </button>
                ) : (
                  <button
                    disabled
                    className={`Button ${styles.disabledSignUpBtn}`}
                    type="submit"
                  >
                    <Tr tr="Forms:sign_up" />
                  </button>
                )}
                <p className="DescFont">
                  <Tr tr="Forms:have_an_account" />
                </p>
                <Link to={"/sign-in"} className="Button White">
                  <Tr tr="Forms:sign_in" />
                </Link>
              </div>
              <ScrollToError />
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

Personal.defaultProps = {};

Personal.propTypes = {};

export default connect(null, { personalUserRegister })(withRouter(Personal));
