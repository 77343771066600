import {
  PERSONAL_PROFILE,
  Profile_Submit_Facility,
  PROFILE_SERVIVES,
  UPLOAD_FILE,
  APPLY_SERVICE,
  NEW_SERVICES_FOR_MAKER,
  WITHDRAWAL_APPLICATION,
  GET_WITH_DARWAL_APPLICATION,
  APPLICATION_DETAILS,
  GET_MAKER_WITH_DRAWAL,
  GET_PROVIDER_RETURNED_SERVICES,
  GET_PROVIDER_RETURNED_BY_ID,
  MAKER_APPLICATION_DETAILS,
  REJECT_WITHDRAWAL,
  ACCEPT_WITHDRAWAL,
  RETURN_TO_MAKER,
  LOAD_REJECT_RESONS,
  LOAD_OUR_SERVICES,
  LOAD_ACCEPT_APPROVAL_FORM,
  LOAD_MAKER_APPROVAL_COMMENTS,
  APPROVAL_ACCEPT,
  LOAD_APPROVAL_SERVICES,
  ACCEPT_OFFER_INFO,
  ACCEPT_OFFER,
  UPLOAD_IMAGE,
  FORM_WITH_VALUE,
  PROVIDER_SERVICE_DETAILS,
  UPDATE_SERVICES,
  GET_MAKER_REJECT_REQUESTS,
  GET_SERVICE_REJECTED_BY_ID,
  GET_ACCEPTED_REQUESTS,
  GET_APPROVAL_ACCEPTED_REQUESTS,
  GET_SERVICE_ACCEPTED_BY_MAKER,
  GET_SERVICE_ACCEPTED_BY_ID,
  LOAD_REJECT_REASONS_BY_ID,
  GET_ACCEPTED_REQUESTS_BY_ANOTHER_BANK,
  LOAD_MAKER_APPROVED_DATA,
  LOAD_APPROVAL_APPROVED_DATA,
  GET_NATIONALITIES,
} from "./types";

export const personalProfile = (query) => ({
  type: PERSONAL_PROFILE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/applied-services${query}`,
    method: "get",
  },
});

export const handleGetNationalities = () => ({
  type: GET_NATIONALITIES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}nationalities`,
    method: "get",
  },
});
export const profileSubmitFacility = () => ({
  type: Profile_Submit_Facility,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-profile`,
    method: "get",
  },
});

export const profileServices = (id) => ({
  type: PROFILE_SERVIVES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-form/${id}`,
    method: "get",
  },
});

export const formWithValue = (id) => ({
  type: FORM_WITH_VALUE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-form/details/${id}`,
    method: "get",
  },
});

export const uploadFile = (data) => ({
  type: UPLOAD_FILE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}files/upload`,
    method: "post",
    data: data,
    timeout: 12000,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  },
});

export const uploadImage = (data) => ({
  type: UPLOAD_IMAGE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}images/upload`,
    method: "post",
    data: data,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  },
});

export const applyService = (id, data) => ({
  type: APPLY_SERVICE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-form/apply/${id}`,
    method: "post",
    data: data,
  },
});

export const updateServices = (id, data) => ({
  type: UPDATE_SERVICES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-form/edit-form-request/${id}`,
    method: "post",
    data: data,
  },
});

export const getNewServicesForMaker = (id, searchParams) => ({
  type: NEW_SERVICES_FOR_MAKER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/applications/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const withdrawalApplication = (data) => ({
  type: WITHDRAWAL_APPLICATION,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/withdrawal`,
    method: "post",
    data,
  },
});

export const getMakerWithDrawal = (id, searchParams) => ({
  type: GET_MAKER_WITH_DRAWAL,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/withdrawal-applications/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const getWithDrawalApplication = (id, searchParams) => ({
  type: GET_WITH_DARWAL_APPLICATION,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-withdrawal/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const applicationDetails = (id) => ({
  type: APPLICATION_DETAILS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/application-details/${id}`,
    method: "get",
  },
});

export const getReturnedServices = () => ({
  type: GET_PROVIDER_RETURNED_SERVICES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-returned`,
    method: "get",
  },
});

export const getServiesReturnedById = (id, searchParams) => ({
  type: GET_PROVIDER_RETURNED_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/returned-applications/${id}`,
    method: "get",
    params: searchParams,
  },
});
export const makerApplicationDetails = (id) => ({
  type: MAKER_APPLICATION_DETAILS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/application-details/${id}`,
    method: "get",
  },
});

export const getApprovalWithDrawal = (id, searchParams) => ({
  type: GET_MAKER_WITH_DRAWAL,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/approval-withdrawals/${id}`,
    method: "get",
    params: searchParams,
  },
});
// Reject Withdrawal
export const rejectWithdrawal = (data) => ({
  type: REJECT_WITHDRAWAL,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/reject-maker`,
    method: "post",
    data,
  },
});

// Accept Withdrawal
export const acceptWithdrawal = (data) => ({
  type: ACCEPT_WITHDRAWAL,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/accept-maker`,
    method: "post",
    data,
  },
});

export const returnToMaker = (data) => ({
  type: RETURN_TO_MAKER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/return`,
    method: "post",
    data,
  },
});
// Reject Resons
export const loadRejectReasons = () => ({
  type: LOAD_REJECT_RESONS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/reject-reasons`,
    method: "get",
  },
});

//Provider Withdrawal
export const loadServices = (subUrl) => ({
  type: LOAD_OUR_SERVICES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-withdrawal`,
    method: "get",
  },
});

export const loadAcceptApprovalWithdrawalForm = (id) => ({
  type: LOAD_ACCEPT_APPROVAL_FORM,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-form/${id}`,
    method: "get",
  },
});

// Maker and Approval Comments
export const loadMakerApprovalComments = (id) => ({
  type: LOAD_MAKER_APPROVAL_COMMENTS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/withdrawal-comments/${id}`,
  },
});

export const approvalAccept = (id, data) => ({
  type: APPROVAL_ACCEPT,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/accept/${id}`,
    method: "post",
    data,
  },
});

//Approval Withdrawal
export const loadApprovalServices = () => ({
  type: LOAD_APPROVAL_SERVICES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-initial-accepted`,
    method: "get",
  },
});

//Accept Offer Modal
export const acceptOfferInfo = (id) => ({
  type: ACCEPT_OFFER_INFO,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/approved-data/${id}`,
    method: "get",
  },
});

//Accept Offer Button
export const acceptOffer = (id) => ({
  type: ACCEPT_OFFER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/accept-offer/${id}`,
    method: "get",
  },
});

//Cancel Request Button
export const cancelRequest = (id) => ({
  type: ACCEPT_OFFER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/services-form/cancel/${id}`,
    method: "put",
  },
});

// Provider service details
export const providerServiceDetails = (id) => ({
  type: PROVIDER_SERVICE_DETAILS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/application-details-services/${id}`,
    method: "get",
  },
});

// Reject Requests
export const getRejectRequests = () => ({
  type: GET_MAKER_REJECT_REQUESTS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-rejected`,
    method: "get",
  },
});

// Services Rejected By ID
export const getServiesRejectedById = (id, searchParams) => ({
  type: GET_SERVICE_REJECTED_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/reject-applications/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const getServiesAcceptedById = (id, searchParams) => ({
  type: GET_SERVICE_ACCEPTED_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/accepted-applications/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const getServiesAcceptedByAnotherBank = (id, searchParams) => ({
  type: GET_SERVICE_ACCEPTED_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/accepted-by-another-bank-applications/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const getAcceptedRequests = () => ({
  type: GET_ACCEPTED_REQUESTS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-accepted`,
    method: "get",
  },
});

export const getAcceptedRequestsByAnotherBank = () => ({
  type: GET_ACCEPTED_REQUESTS_BY_ANOTHER_BANK,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-accepted-by-another-bank`,
    method: "get",
  },
});

export const getApprovalAcceptedRequests = () => ({
  type: GET_APPROVAL_ACCEPTED_REQUESTS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-approved`,
    method: "get",
  },
});

export const getServiesAcceptedByMaker = (id, searchParams) => ({
  type: GET_SERVICE_ACCEPTED_BY_MAKER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/approved-applications/${id}`,
    method: "get",
    params: searchParams,
  },
});

export const loadRejectReasonsById = (id) => ({
  type: LOAD_REJECT_REASONS_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/withdrawal-reject-reasons/${id}`,
  },
});

export const loadMakerApprovedData = (id) => ({
  type: LOAD_MAKER_APPROVED_DATA,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/maker-approved-data/${id}`,
    method: "get",
  },
});

export const loadApprovalApprovedData = (id) => ({
  type: LOAD_APPROVAL_APPROVED_DATA,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/approval-approved-data/${id}`,
    method: "get",
  },
});
