export const en = {
  PERSONAL_SERVICES: "PERSONAL SERVICES",
  CORPORATE_SERVICES: "CORPORATE SERVICES",
  our_services: "Our services",
  desc:
    "We offer a complete range of products that fulfills all customers' financial needs, not limited to personal and corporate customers.",
  PERSONAL_SERVICES_DESCRIPTION:
    "We facilitate offering many services/products that suits all customers' financial needs, including but not limited to investment products and financial products",
  CORPORATE_SERVICES_DESCRIPTION:
    "We facilitate offering many services/products that suits all customers' financial needs, including but not limited to investment products and financial products",
};

export const ar = {
  PERSONAL_SERVICES: "الخدمات الشخصية",
  CORPORATE_SERVICES: "خدمات الشركات",
  our_services: "خدماتنا",
  desc:
    "نحن نقدم مجموعة كاملة من المنتجات التي تلبي جميع الاحتياجات المالية للعملاء ، ولا تقتصر على العملاء الأفراد والشركات.",
  PERSONAL_SERVICES_DESCRIPTION:
    "نحن نسهل تقديم العديد من الخدمات / المنتجات التي تناسب جميع الاحتياجات المالية للعملاء ، بما في ذلك على سبيل المثال لا الحصر المنتجات الاستثمارية والمنتجات المالية",
  CORPORATE_SERVICES_DESCRIPTION:
    "نحن نسهل تقديم العديد من الخدمات / المنتجات التي تناسب جميع الاحتياجات المالية للعملاء ، بما في ذلك على سبيل المثال لا الحصر المنتجات الاستثمارية والمنتجات المالية",
};
