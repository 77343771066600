export const LOAD_FAQS = "LOAD_FAQS";
export const CONTACT_US = "CONTACT_US";
export const SUBSCRIBE = "SUBSCRIBE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const LOAD_SETTINGS = "LOAD_SETTINGS";
export const LOAD_SEARCH_RESULTS = "LOAD_SEARCH_RESULTS";
export const LOAD_AD_DETAILS = "LOAD_AD_DETAILS";
export const LOAD_ALL_ADS = "LOAD_ALL_ADS";
export const LOAD_SLIDER_ADS = "LOAD_SLIDER_ADS";
export const LOAD_ABOUT = "LOAD_ABOUT";
