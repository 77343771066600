import React from "react";
import styles from "./NumbersCard.module.scss";

const ServiceInfo = ({
  desc, //the written text in that box
  number, //the written number
  ...props
}) => {
  return (
    <div className={styles.ServiceInfo}>
      <p className={`DescFont LatoB ${styles.Tit}`}>{desc}</p>
      <p className={`TitleFont ${styles.Desc}`}>{number}</p>
    </div>
  );
};

export default ServiceInfo;
