import React, { Component } from "react";
import styles from "./HeadSlider.module.scss";

import { Tr } from "utils/i18n";
import Modal from "react-bootstrap/Modal";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loadSliderAds } from "redux/main/actions";
import parse from "html-react-parser";

class Slider extends Component {
	state = {
		slides: null,
		show: false,
		videoUrl: "",
	};
	// return index of current owl-dot and check if it less than 9 we add 0 plus current index to be [01 ... 09] and if it greater than 9 we add current index only
	componentDidUpdate() {
		document
			.querySelectorAll(".HomeHeadSlider .owl-dot")
			.forEach(function (el, index) {
				let appendSpan = document.createElement(`dev`);
				appendSpan.innerHTML = `${index <= 9 ? "0" : ""}${index + 1}`;
				el.prepend(appendSpan);
			});
	}
	componentDidMount() {
		this.fetchSlides();
	}

	handleShow = (videoData = "") => {
		this.setState({
			show: !this.state.show,
			videoUrl: videoData,
		});
	};

	fetchSlides() {
		this.props
			.loadSliderAds()
			.then((res) => this.setState({ slides: res.data.data }));
	}

	render() {
		const { slides } = this.state;
		return (
			<div className={`${styles.HeadSlider} HomeHeadSlider`}>
				{slides && (
					<OwlCarousel
						className="owl-theme"
						// loop
						autoplay
						autoplayHoverPause
						autoplayTimeout={5000}
						autoplaySpeed={1000}
						margin={30}
						items={1}
						navText={[
							'<img src="/img/prev-arrow.png" alt="prev" />',
							'<img src="/img/next-arrow.png" alt="next" />',
						]}
						dots={true}
					>
						{slides.map((slide) => {
							return (
								<div key={slide.id} className="item">
									<Link className="TopFont" to={`/ad-details/${slide.id}`}>
										{slide.title}
									</Link>
									<p className={"DescFont WhiteFont " + styles.SliderDesc}>
										{parse(slide.content)}
									</p>
									<img src={slide.image} alt="" className={styles.img} />
									{slide.video_url && (
										<button
											href="#"
											className="Button DescFont"
											onClick={() => {
												this.handleShow(slide.video_url);
											}}
										>
											<i className="icon-play" />
											{Tr.tr("About:play")}
										</button>
									)}
								</div>
							);
						})}
					</OwlCarousel>
				)}
				<Modal
					show={this.state.show}
					onHide={this.handleShow}
					className="VideoModal"
				>
					<button className="CloseIcon" onClick={this.handleShow}>
						<i className="icon-close"></i>
					</button>
					<Modal.Body>
						<iframe src={this.state.videoUrl} title="youtube video"></iframe>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default connect(null, { loadSliderAds })(Slider);
