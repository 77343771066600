import React from 'react';


const Loader = props => {
    return props.loading ? <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
    </div>  : "";
};

Loader.propTypes = {};

export default Loader;