import React from "react";
import styles from "./EditProfile.module.scss";

import EditPersonalProfile from "./components/EditPersonalProfile/EditPersonalProfile";
import EditCorporateProfile from "./components/EditCorporateProfile/EditCorporateProfile";
import { useAuth } from "utils/useAuthHook";

const EditProfile = ({ ...props }) => {
  // show edit form based on userType [personal or corporate]
  let [userType] = useAuth();
  return (
    <div className={`SectionPadding ${styles.EditProfile}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="row">
              {userType === "personal" ? (
                <EditPersonalProfile />
              ) : userType === "corporate" ? (
                <EditCorporateProfile />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
