import React, { Component } from "react";
import styles from "./SearchDrop.module.scss";
import { withRouter } from "react-router-dom";
import { Tr } from "utils/i18n";
import qs from "query-string";

class SearchDrop extends Component {
  inputRef = React.createRef();

  componentDidMount() {
    let { search } = this.props.location;
    if (search) {
      this.inputRef.current.value = qs.parse(search).search;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (!this.props.location.pathname.includes("search")) {
        this.inputRef.current.value = "";
      }
    }
  }
  handleSubmit = (e) => {
    let {
      location: { pathname },
    } = this.props;
    e.preventDefault();
    if (this.inputRef.current.value.trim()) {
      if (pathname.includes("search")) {
        this.props.history.push(
          `${pathname}?search=${this.inputRef.current.value}`
        );
      } else {
        this.props.history.push(
          `/search/all?search=${this.inputRef.current.value}`
        );
      }
    }
  };

  render() {
    const machesRouts = ["search"];
    const isMach = machesRouts.includes(
      this.props.location.pathname.split("/")[1]
    );
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`${styles.SearchInput} ${
          this.props.isOpen || isMach ? styles.Open : ""
        } ${styles.SearchDrop}`}
      >
        <div className="container">
          <input
            type="text"
            className={styles.SrchInput}
            placeholder={Tr.tr("search")}
            ref={this.inputRef}
          />
        </div>
      </form>
    );
  }
}

export default withRouter(SearchDrop);
