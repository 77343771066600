import React, { useEffect, useState } from "react";
import styles from "./ProviderWithdrawalDetails.module.scss";
import SideBar from "components/SideBar";
import { Tr } from "utils/i18n";

import AcceptWithdrawal from "./components/AcceptWithdrawal/AcceptWithdrawal";
import RejectWithdrawal from "./components/RejectWithdrawal/RejectWithdrawal";

import { connect } from "react-redux";

import Loader from "components/Loader";

import moment from "moment";

import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";

import {
  makerApplicationDetails,
  loadMakerApprovalComments,
} from "redux/profile/actions";
import { withRouter } from "react-router-dom";
import ApplicationTd from "../../components/AplicationTd";

const ProviderWithdrawalDetails = ({ ...props }) => {
  // appear the form of two tabs accept and reject based on choice value and if the choice value is null not forms appear
  const [choice, setView] = useState(null);

  const [id, setId] = useState(props.match.params.id);
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([]);
  const [comments, setComments] = useState([]);
  const [caseID, setcaseID] = useState('');
  const [status, setStatus] = useState({});
  // Download Links
  const [downloadLinks, setDownloadLinks] = useState({});

  // Date & Time Submission
  const [dateTimeSubmission, setDateTimeSubmission] = useState("");

  // Loader
  const [loading, setLoading] = useState(true);

  const viewChoice = () => {
    setView(!choice);
  };

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    props
      .makerApplicationDetails(id)
      .then((result) => {
        if (result.data) {
          const hasType =
            result.data.data.withdrawal.type_of_facility.length > 1
              ? true
              : false;

          setLoading(false);
          setRows([
            ...result.data.data.form,
            {
              label: Tr.tr("Table:lower_service_name"),
              value: result.data.data.withdrawal.service.name,
            },
            {
              label: Tr.tr("Table:service_type"),
              value: result.data.data.withdrawal.service.type,
            },
          ]);
          setDownloadLinks(result.data.data.download_links);
          setDateTimeSubmission(result.data.data.created_at);
        }
      })
      .catch((res) => {
        if (res.error) {
          if (res.error.response.data.message) {
            setLoading(false);
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });

    // Load Comments
    props.loadMakerApprovalComments(id).then((res) => {
      setComments(res.data.data.comments);
      setcaseID(res.data.data.withdrawal.case_id)
      setStatus(res.data.data.withdrawal);
    });
  }, []);

  return (
    <div className="AddPadding">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!loading ? (
              <>
                {!message ? (
                  <>
                    <div className={styles.Tit}>
                      <p className="TitleFont">
                        <Tr tr="Profile:case_id" />
                      </p>
                      {Object.keys(downloadLinks).length > 0 && (
                        <div className={styles.DownloadLinks}>
                          {downloadLinks.document.length > 0 && (
                            <a
                              href={downloadLinks.document}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={`DescFont ${styles.Download}`}
                            >
                              <i
                                className={`icon-next ${styles.DownloadIcon}`}
                              ></i>
                              <Tr tr="Profile:download_doc" />
                            </a>
                          )}
                          {downloadLinks.hr_letter.length > 0 && (
                            <a
                              href={downloadLinks.hr_letter}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={`DescFont ${styles.Download}`}
                            >
                              <i
                                className={`icon-next ${styles.DownloadIcon}`}
                              ></i>
                              <Tr tr="serviceProvider:download_hr_letter" />
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                    <span className={`DescFont ${styles.CaseId}`}>{caseID}</span>
                    <div className={styles.TableWrapper}>
                      <div className="row d-flex justify-content-lg-between">
                        <div className="col-lg-8">
                          {!loading ? (
                            <table>
                              <tbody>
                                <ApplicationTd
                                  rows={rows}
                                  dateTimeSubmission={dateTimeSubmission}
                                />
                                <tr>
                                  <td>{Tr.tr("basic:maker_status")}</td>
                                  <td>{status && status.status}</td>
                                </tr>

                                {comments &&
                                  comments.length > 0 &&
                                  comments.map((comment) => (
                                    <tr key={comment.id}>
                                      {comment.user.type === "maker" ? (
                                        <>
                                          <td>
                                            {comment.user &&
                                              comment.user.first_name +
                                                " " +
                                                comment.user.middle_name +
                                                " " +
                                                comment.user.last_name}{" "}
                                            - {Tr.tr("basic:maker")}
                                          </td>
                                          <td>
                                            <div className={styles.CommentDate}>
                                              <div>{comment.reason}</div>
                                              <div>
                                                {moment(comments.created_at)
                                                  .locale("en")
                                                  .format("L")}
                                              </div>
                                            </div>
                                          </td>
                                        </>
                                      ) : comment.user.type === "approval" ? (
                                        <>
                                          <td>
                                            {comment.user &&
                                              comment.user.first_name +
                                                " " +
                                                comment.user.middle_name +
                                                " " +
                                                comment.user.last_name}{" "}
                                            - {Tr.tr("basic:approval")}
                                          </td>
                                          <td>
                                            <div className={styles.CommentDate}>
                                              <div>{comment.reason}</div>
                                              <div>
                                                {moment(comments.created_at)
                                                  .locale("en")
                                                  .format("L")}
                                              </div>
                                            </div>
                                          </td>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          ) : (
                            <Loader loading={loading} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.RadioGroup} ${
                        choice == null ? styles.NotRadiobtnSelected : ""
                      } ${styles.Tabs}`}
                    >
                      <div
                        className={`DescFont ${styles.Tab} ${
                          choice === "reject" ? styles.active : ""
                        }`}
                        onClick={() => setView("reject")}
                      >
                        <span className={styles.Radio}></span>
                        <Tr tr="serviceProvider:reject_case" />
                      </div>
                      <div
                        className={`DescFont ${styles.Tab} ${
                          choice === "accept" ? styles.active : ""
                        }`}
                        onClick={() => setView("accept")}
                      >
                        <span className={styles.Radio}></span>
                        <Tr tr="serviceProvider:accept_case" />
                      </div>
                    </div>
                    {choice === "reject" ? (
                      <RejectWithdrawal
                        withdrawalId={id}
                        setMessage={setMessage}
                      />
                    ) : choice === "accept" ? (
                      <AcceptWithdrawal
                        withDrawalId={id}
                        setMessage={setMessage}
                      />
                    ) : null}
                  </>
                ) : (
                  <BadRequestMessage
                    message={message}
                    redirectPage="/provider-withdrawal"
                  />
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProviderWithdrawalDetails.defaultProps = {};

ProviderWithdrawalDetails.propTypes = {};

export default connect(null, {
  makerApplicationDetails,
  loadMakerApprovalComments,
})(withRouter(ProviderWithdrawalDetails));
