import React, { Component } from "react";
import styles from "./OurVision.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Tr } from "utils/i18n";

export default class OurVision extends Component {
  // Our Vision Slider in [About] page
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };
  render() {
    const { ourMission, ourVision, imgSrc } = this.props;
    return (
      <div className={styles.VisionSlider}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <OwlCarousel
                className="owl-theme"
                rewind={true}
                rtl="true"
                margin={30}
                loop={"true"}
                nav={true}
                navText={[
                  '<i class="icon-previous"></i>',
                  '<i class="icon-next"></i>',
                ]}
                dots={false}
                responsive={this.state.responsive}
              >
                <div className="item">
                  <div>
                    <h3 className={`TitleFont ${styles.TitleFont}`}>
                      {Tr.tr("About:our_vision")}
                    </h3>
                    <p className="DescFont">{ourVision}</p>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <h3 className={`TitleFont ${styles.TitleFont}`}>
                      {Tr.tr("About:our_mission")}
                    </h3>
                    <p className="DescFont">{ourMission}</p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div className={styles.ImgBg}>
          <div className="container">
            <div className={styles.Img}>
              <img src={imgSrc} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
