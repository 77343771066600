import React from "react";
import styles from "./TabsBar.module.scss";
import { Tr } from "utils/i18n";
import { NavLink, withRouter } from "react-router-dom";
import { UsersOnlyAccess } from "routes/Middleware/AccessControls";

const SearchBar = ({ search, ...props }) => {
  const searchBarLinks = [
    {
      pathname: "/search/all",
      label: <Tr tr="Search:all" />,
    },
    {
      pathname: "/search/services",
      label: <Tr tr="Search:services" />,
    },
    {
      pathname: "/search/news",
      label: <Tr tr="Search:news" />,
    },
    {
      pathname: "/search/offers",
      label: <Tr tr="Search:offers" />,
      accessControl: true,
    },
  ];
  return (
    <div className={styles.FilterBar}>
      <div className="container">
        <ul className={styles.FilterList}>
          {searchBarLinks.map((link, index) => {
            return link.accessControl ? (
              <UsersOnlyAccess hide key={index}>
                <li>
                  <NavLink
                    to={{
                      pathname: link.pathname,
                      search: `?search=${search}`,
                    }}
                    className={`DescFont`}
                    activeClassName={styles.active}
                  >
                    {link.label}
                  </NavLink>
                </li>
              </UsersOnlyAccess>
            ) : (
              <li key={index + "_link"}>
                <NavLink
                  to={{
                    pathname: link.pathname,
                    search: `?search=${search}`,
                  }}
                  className={`DescFont`}
                  activeClassName={styles.active}
                >
                  {link.label}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(SearchBar);
