import moment from "moment";
import "moment/locale/ar";

const lang = localStorage.getItem("i18nextLng");

export const date = (date) => {
  if (lang === "en") {
    let dateObj = {
      seconds: "secs",
      second: "sec",
      minutes: "mins",
      minute: "min",
      hours: "hrs",
      hour: "h",
      days: "dys",
      day: "d",
      weeks: "wks",
      week: "wk",
      months: "mos",
      month: "mo",
      years: "yrs",
      year: "yr",
    };

    let d = moment(date)
      .locale("en")
      .fromNow()
      .replace(
        /seconds|second|minutes|minute|hours|hour|days|day|weeks|week|months|month|years|year/gi,
        function (matched) {
          return dateObj[matched];
        }
      )
      .replace(/an?/i, function () {
        return 1;
      })
      .replace("1go", function () {
        return "ago";
      })
      .replace("few", function () {
        return "";
      });

    return d;
  } else {
    let arDate = moment(date).locale("ar").fromNow();

    return arDate;
  }
};
