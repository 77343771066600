import React, { useEffect } from "react";
import TabsBar from "components/common/TabsBar/TabsBar";

import { Switch, Route } from "react-router-dom";
import qs from "query-string";
import { connect } from "react-redux";
import { getSearchResults } from "redux/main/actions";
import ServicesResults from "./components/ServicesResults";
import AllResults from "./components/AllResults";
import NewsResults from "./components/NewsResults";
import OffersResults from "./components/OffersResults";
import { Users } from "routes/Middleware/Users";
const SearchResults = (props) => {
  let search = qs.parse(props.location.search).search;
  useEffect(() => {
    search = qs.parse(props.location.search).search;
  }, [props.location.search]);

  const {
    match: { url },
  } = props;
  return (
    <div>
      <TabsBar search={search} />
      <Switch>
        <Route
          path={url + "/all"}
          render={(props) => <AllResults {...props} {...{ search }} />}
        />
        <Route
          path={url + "/services"}
          render={(props) => <ServicesResults {...props} {...{ search }} />}
        />
        <Route
          path={url + "/news"}
          render={(props) => <NewsResults {...props} {...{ search }} />}
        />
        <Users
          path={url + "/offers"}
          component={(props) => <OffersResults {...props} {...{ search }} />}
        />
      </Switch>
    </div>
  );
};

export default connect(null, {
  getSearchResults,
})(SearchResults);
