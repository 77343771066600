import React, { Component } from "react";
import styles from "./SignIn.module.scss";
import { Tr } from "utils/i18n";

import { Link, withRouter } from "react-router-dom";

import { Formik } from "formik";
import { Field, CheckBoxField } from "components/fields/index";

import { loginWebsiteUser } from "redux/auth/actions";
import { connect } from "react-redux";
import { USERS_TYPES } from "utils/constants/usersTypes";

class SignIn extends Component {
  state = {
    errorMessage: "",
  };

  redirectBasedOnType(type) {
    if (type === USERS_TYPES.Maker) {
      this.props.history.replace(`/provider-app`);
    } else if (type === USERS_TYPES.Corporate) {
      this.props.history.replace(`/profile`);
    } else if (type === USERS_TYPES.Approval) {
      this.props.history.replace(`/provider-withdrawal`);
    } else if (type === USERS_TYPES.Personal) {
      this.props.history.replace(`/profile`);
    }
  }
  render() {
    const { location } = this.props;
    return (
      <div className={`${styles.SignIn} SectionPadding`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: "",
                  password: "",
                  remember: false,
                }}
                validate={(values) => {
                  let errors = {};
                  if (!values.email.trim())
                    errors.email = Tr.tr("Forms:err_requierd", {
                      field: Tr.tr("Forms:email"),
                    });
                  else if (
                    !/^[valuesA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email.trim()
                    )
                  )
                    errors.email = Tr.tr("Forms:invalid");
                  if (!values.password)
                    errors.password = Tr.tr("Forms:err_requierd", {
                      field: Tr.tr("Forms:password"),
                    });

                  return errors;
                }}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  this.props
                    .loginWebsiteUser(values)
                    .then((result) => {
                      this.setState({ errorMessage: "" }, () => {
                        if (
                          this.props.location.state &&
                          this.props.location.state.from
                        ) {
                          let redirectLink =
                            result.data.data.type === USERS_TYPES.Personal
                              ? 2
                              : 6;
                          console.log(result.data.data.type, location.state);
                          this.props.history.replace(
                            `${location.state.from + redirectLink}`
                          );
                        } else this.redirectBasedOnType(result.data.data.type);
                      });
                    })
                    .catch((res) => {
                      setSubmitting(false);
                      try {
                        if (res.error.response.data !== undefined) {
                          setErrors(res.error.response.data.data);
                        }
                        this.setState({
                          errorMessage: res.error.response.data.message,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    });
                  // .finally(() => {
                  //   setSubmitting(false);
                  // });
                }}
              >
                {({
                  values,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form
                    action=""
                    className="position-relative"
                    onSubmit={handleSubmit}
                  >
                    <h1 className="TitleFont SubPadding">
                      <Tr tr="Forms:sign_in" />
                    </h1>
                    <div className={`GlobalErr DescFont ${styles.ErrMsg}`}>
                      {this.state.errorMessage}
                    </div>
                    <Field
                      name="email"
                      label={Tr.tr("Forms:email")}
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      touched={touched}
                      required
                    />
                    <Field
                      name="password"
                      label={Tr.tr("Forms:password")}
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      touched={touched}
                      required
                    />
                    <div className={styles.RememberRow}>
                      <CheckBoxField
                        name="remember"
                        label={Tr.tr("Forms:remember_me")}
                        type="password"
                        // onChange={handleChange}
                        checked={() => {
                          setFieldValue("remember", !values.remember);
                        }}
                      />
                      <Link to="/forget-password" className="DescFont">
                        <Tr tr="Forms:are_you_forget_password" />
                      </Link>
                    </div>
                    <div className="LogBtns">
                      <button
                        disabled={isSubmitting}
                        style={{ opacity: isSubmitting ? 0.8 : 1 }}
                        className="Button"
                        type="submit"
                      >
                        {isSubmitting ? (
                          <Tr tr="Forms:sign_in_load" />
                        ) : (
                          <Tr tr="Forms:sign_in" />
                        )}
                      </button>
                      <p className="DescFont">
                        <Tr tr="Forms:dont_have_account" />
                      </p>
                      <Link to="/sign-up" className="Button White">
                        <Tr tr="Forms:sign_up" />
                      </Link>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { loginWebsiteUser })(withRouter(SignIn));
