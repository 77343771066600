import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProviderLayout from "layout/ProviderLayout";
import { getAcceptedRequestsByAnotherBank } from "redux/profile/actions";

const AcceptedByAnotherBank = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [corporateCount, setCorporateCount] = useState([]);
  const [personalCount, setPersonalCount] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    props
      .getAcceptedRequestsByAnotherBank()
      .then(({ data: { data } }) => {
        if (data) {
          setLoading(false);
          setCorporateCount(data.corporate);
          setPersonalCount(data.personal);
        }
      })
      .catch((res) => {
        if (res.error) {
        if (res.error.response.data.message) {
          setLoading(false);
          setMessage(res.error.response.data.message);
        }}
      });
  }, []);

  return (
    <ProviderLayout
      {...{
        loading,
        corporateCount,
        personalCount,
        message,
        serviceUrl: "accepted-cases-by-another-bank",
      }}
    />
  );
};
export default connect(null, { getAcceptedRequestsByAnotherBank })(
  AcceptedByAnotherBank
);
