import React from "react";
import styles from "./ContactInfo.module.scss";
import { Tr } from "utils/i18n";
import { withChangeLang } from "components/hocs/index";

const ContactInfo = ({ ...props }) => {
  let { info, currLang } = props;
  return (
    <div className={styles.ContactInfo}>
      <div className={styles.ContactSection}>
        <p className={`SubTitle LatoB ${styles.Tit}`}>
          <Tr tr="basic:email" />
        </p>
        <ul>
          {info && info.email_one && (
            <li className={`Other ${styles.ContactEmail}`}>
              {info && info.email_one}
            </li>
          )}
          {info && info.email_two && (
            <li className={`Other ${styles.ContactEmail}`}>
              {info && info.email_two}
            </li>
          )}
        </ul>
      </div>

      <div className={styles.ContactSection}>
        <p className={`SubTitle LatoB ${styles.Tit}`}>
          <Tr tr="basic:location" />
        </p>
        <p className={`Other ${styles.Location}`}>
          {info && info[`location_${currLang}`]}
        </p>
      </div>

      <div className={styles.ContactSection}>
        <p className={`SubTitle LatoB ${styles.Tit}`}>
          <Tr tr="basic:phone" />
        </p>
        <p className={`Other ${styles.PhoneNum}`}>{info && info.phone_one}</p>
        <p className={`Other ${styles.PhoneNum}`}>{info && info.phone_two}</p>
      </div>

      <div className={styles.ContactSection}>
        <p className={`SubTitle LatoB ${styles.Tit}`}>
          <Tr tr="basic:fax" />
        </p>
        <p className={`Other ${styles.FaxNum}`}>{info && info.fax_one}</p>
        <p className={`Other ${styles.FaxNum}`}>{info && info.fax_two}</p>
      </div>
    </div>
  );
};

export default withChangeLang()(ContactInfo);
