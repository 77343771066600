import SideBar from "components/SideBar";
import React from "react";
import { Link } from "react-router-dom";
import { Tr } from "utils/i18n";
import ServiceCounterCard from "components/common/ServiceCounterCard/ServiceCounterCard";
import styles from "./ProviderLayout.module.scss";
import Loader from "components/Loader";

export default function ProviderLayout({
  loading,
  corporateCount,
  personalCount,
  message,
  serviceUrl,
}) {
  return (
    <div className={styles.ProviderApp}>
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {message}
            {!loading ? (
              <>
                {!corporateCount.length && !personalCount.length ? (
                  <p className="noData">
                    {Tr.tr("Profile:THERE_ARE_NO_SERVICES")}
                  </p>
                ) : (
                  <>
                    <div className={styles.WithdrawalSection}>
                      {corporateCount.length > 0 && (
                        <p className={`TitleFont ${styles.Tit}`}>
                          <Tr tr="serviceProvider:corporate_services" />
                        </p>
                      )}
                      <div className="row">
                        {corporateCount.length > 0
                          ? corporateCount.map((card, index) => {
                              return (
                                <div className="col-lg-4" key={card.id}>
                                  <Link
                                    to={`/${serviceUrl}/${card.id}/corporate`}
                                  >
                                    <ServiceCounterCard
                                      text={card.name}
                                      number={card.total}
                                    />
                                  </Link>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                    <div className={styles.WithdrawalSection}>
                      {personalCount.length > 0 && (
                        <p className={`TitleFont ${styles.Tit}`}>
                          <Tr tr="serviceProvider:personal_services" />
                        </p>
                      )}
                      <div className="row">
                        {personalCount.length > 0
                          ? personalCount.map((card, index) => {
                              return (
                                <div className="col-lg-4" key={card.id}>
                                  <Link
                                    to={`/${serviceUrl}/${card.id}/personal`}
                                  >
                                    <ServiceCounterCard
                                      text={card.name}
                                      number={card.total}
                                    />
                                  </Link>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
