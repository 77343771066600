import React, { useEffect } from "react";
import styles from "./Home.module.scss";

import Head from "./components/Head/Head";
import Proces from "./components/Proces/Proces";
import Services from "./components/Services/Services";
import OurClients from "./components/OurClients/OurClients";
import LatestNews from "./components/LatestNews/LatestNews";
import Testimonials from "./components/Testimonials/Testimonials";

import { connect } from "react-redux";
import { withLoadMore } from "components/hocs/index";
import { loadHomeData } from "redux/home/actions";

const Home = ({ ...props }) => {
	// useEffect(() => {
	//   window.scrollTo(0, 0);
	// });

	useEffect(() => {
		props.loadData();
	}, []);

	const { services, clients, news, testimonials } = props.dataState.data;

	const renderList = (list, component) => {
		if (list && list.length) {
			return component;
		}
		return;
	};
	return (
		<div className={styles.home}>
			<Head />
			<Proces />

			{renderList(services, <Services services={services} />)}

			{/* {renderList(clients, <OurClients clients={clients} />)} */}

			{renderList(news, <LatestNews news={news} />)}

			{renderList(testimonials, <Testimonials testimonials={testimonials} />)}
		</div>
	);
};

export default connect(null, { loadHomeData })(
	withLoadMore({ loadAction: "loadHomeData" })(Home)
);
