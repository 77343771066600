import React, { useState, useEffect } from "react";
import styles from "./ContactUs.module.scss";
import PagesHead from "components/common/PagesHead/PagesHead";
import { Tr } from "utils/i18n";
import ContactInfo from "./components/ContactInfo/ContactInfo";
import ContactForm from "./components/ContactForm/ContactForm";
import Maps from "./components/Maps/Maps";

import { connect } from "react-redux";
import { loadSettings } from "redux/main/actions";

const ContactUs = ({ ...props }) => {
  // Show Contact Form or Submit Msg based on success
  const [success, setSuccess] = useState(false);

  // Set Contact Info
  const [contactInfo, setContactInfo] = useState(null);
  const _loadSettings = () => {
    props.loadSettings().then((res) => setContactInfo(res.data.data));

  }
  useEffect(() => {
    _loadSettings()
  }, []);

  return (
    <>
      <PagesHead
        mainTitle={Tr.tr("ContactUs:get_touch")}
        paragraph={Tr.tr("ContactUs:desc")}
        imgSrc="/img/contact.png"
      />

      <div className={`AddPadding ${styles.ContactUs}`}>
        <div className="container">
          {!success ? (
            <div className="row">
              <div className="col-lg-4">
                {/* Contact Info Component */}
                <ContactInfo info={contactInfo} />
              </div>
              <div className="col-lg-8">
                {/* Contact Form Component */}
                <ContactForm setSuccess={() => setSuccess(true)} />
              </div>
            </div>
          ) : (
            <p className={`SubTitle ${styles.Success}`}>
              <Tr tr="ContactUs:hanks_for_your_submission" />
            </p>
          )}
        </div>
      </div>
      {/* Google Map */}
      <Maps info={contactInfo} />
    </>
  );
};

export default connect(null, { loadSettings })(ContactUs);
