export const en = {
  company: "COMPANY",
  support: "SUPPORT",
  social_media: "SOCIAL MEDIA",
  subscribe_now: "SUBSCRIBE NOW!",
  subscribe_desc: `El Momawel will review the client’s request and verify its validity, then send the customer’s request to all banks.`,
  email_address: "EMAIL ADDRESS",
  terms_conditions: "TERMS AND CONDITIONS",
  privacy_policy: "PRIVACY POLICY",
  copy_rights: "© 2019 EL MOMAWEL Inc. All Rights Reserved",
  about_us: "about us",
  our_serv: "our services",
  our_clients: "our clients",
  contact_us: "contact us",
  faq: "FAQs",
  error_invalid_email: "Error! Invalid email!",
  subscribed_SUCCESS_MESSAGE: "You have successfully subscribed!",
};

export const ar = {
  company: "الشركة",
  support: "الدعم",
  social_media: "وسائل التواصل",
  subscribe_now: "إشترك الآن!",
  subscribe_desc: `ستقوم “الممول” بمراجعة طلب العميل والتحقق من صحته ، ثم ترسل طلب العميل إلى جميع البنوك.`,
  email_address: "البريد الإلكترونى",
  terms_conditions: "الشروط والأحكام",
  privacy_policy: "سياسة الخصوصية",
  copy_rights: "© 2019 EL MOMAWEL Inc. جميع الحقوق محفوظة",
  about_us: "من نحن",
  our_serv: "خدماتنا",
  our_clients: "عملائنا",
  contact_us: "اتصل بنا",
  faq: "أسئلة شائعة ومتكررة",
  error_invalid_email: "خطأ! بريد إلكتروني خاطئ!",
  subscribed_SUCCESS_MESSAGE: "لقد تم اشتراكك بنجاح!",
};
