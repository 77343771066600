import React from "react";
import styles from "./Services.module.scss";
import { Tr } from "utils/i18n";

import { Link } from "react-router-dom";

const Services = ({ ...props }) => {
  return (
    <div className={`${styles.Services} SectionPadding`}>
      <div className="container">
        <h2 className={`SubTitle SubPadding Blue`}>
          <Tr tr="basic:services" />
        </h2>
        <div className="row">
          {props.services && props.services.personal.length > 0 && (
            <div className="col-12 col-md-6">
              <div className={styles.ServiceBox}>
                <div className={styles.ServiceHead}>
                  <img
                    src="../img/personal.png"
                    className={styles.Img}
                    alt="personal service"
                  />
                  <Link
                    to="/our-services"
                    className={`Button DescFont ${styles.Button}`}
                  >
                    {Tr.tr("basic:explore")}
                  </Link>
                </div>
                <p className={`SubTitle ${styles.Tit}`}>
                  {Tr.tr("basic:personal")}
                </p>
                <ul>
                  {props.services.personal.map((service, index) => (
                    <li className="Other" key={index}>
                      {service.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {props.services && props.services.corporate.length > 0 && (
            <div className="col-12 col-md-6">
              <div className={styles.ServiceBox}>
                <div className={styles.ServiceHead}>
                  <img
                    src="../img/personal.png"
                    className={styles.Img}
                    alt="personal service"
                  />
                  <Link
                    to="/our-services"
                    className={`Button DescFont ${styles.Button}`}
                  >
                    {Tr.tr("basic:explore")}
                  </Link>
                </div>
                <p className={`SubTitle ${styles.Tit}`}>
                  {Tr.tr("basic:corporate")}
                </p>
                <ul>
                  {props.services.corporate.map((service, index) => (
                    <li className="Other" key={index}>
                      {service.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
