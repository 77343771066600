import React, { useState } from "react";
import { Tr } from "../utils/i18n";
import SideBarTabs from "./common/SideBarTabs/SideBarTabs";
import SideBarCommon from "components/common/SideBar/SideBar";
import Modal from "./common/ModalComponent/ModalComponent";
import { useHistory, withRouter } from "react-router-dom";
import { useAuth } from "utils/useAuthHook";
import { connect } from "react-redux";
import { signOut } from "redux/auth/actions";
import { USERS_TYPES } from "utils/constants/usersTypes";

const SideBar = (props) => {
  const history = useHistory();
  let [userType] = useAuth();

  // Show or Hide state based on show value
  const [show, setShow] = useState(false);

  // Show modal if show value is true [setShow(true)]
  const handleShow = () => {
    setShow(true);
  };

  // Hide modal if show value is false [setShow(false)] [Sign Out Modal]
  const handleClose = () => {
    setShow(false);
  };

  const logOut = () => {
    props.signOut();
    handleClose();
    history.replace("/sign-in");
  };

  const personalSideBar = [
    {
      iconName: "icon-independence",
      tabName: Tr.tr("Profile:personal_facility_request"),
      pathName: "/profile",
      subRoutes: ["personal-request-details", "edit-request"],
    },
    {
      iconName: "icon-check",
      tabName: Tr.tr("Profile:submit_personal_facility"),
      pathName: "/profile-submit-facility",
      subRoutes: ["profile-services"],
    },
    {
      iconName: "icon-offer",
      tabName: Tr.tr("Profile:Bank_Offers"),
      pathName: "/profile-bank-offers",
      subRoutes: ["profile-bank-details"],
    },
    {
      iconName: "icon-bank-news",
      tabName: Tr.tr("Profile:Bank_News"),
      pathName: "/profile-bank-news",
    },
    {
      iconName: "icon-edite-profile",
      tabName: Tr.tr("Profile:edit_profile"),
      pathName: "/edit-profile",
    },
  ];

  const corporateSideBar = [
    {
      iconName: "icon-corporate",
      tabName: Tr.tr("Profile:corporate_facility_request"),
      pathName: "/profile",
      subRoutes: ["personal-request-details", "edit-request"],
    },
    {
      iconName: "icon-check",
      tabName: Tr.tr("Profile:submit_corporate_facility"),
      pathName: "/profile-submit-facility",
      subRoutes: ["profile-services"],
    },
    {
      iconName: "icon-offer",
      tabName: Tr.tr("Profile:Bank_Offers"),
      pathName: "/profile-bank-offers",
      subRoutes: ["profile-bank-details"],
    },
    {
      iconName: "icon-bank-news",
      tabName: Tr.tr("Profile:Bank_News"),
      pathName: "/profile-bank-news",
    },
    {
      iconName: "icon-edite-profile",
      tabName: Tr.tr("Profile:edit_profile"),
      pathName: "/edit-profile",
    },
  ];

  const makerSideBar = [
    {
      iconName: "icon-applications",
      tabName: Tr.tr("serviceProvider:applications"),
      pathName: "/provider-app",
      subRoutes: ["provider-select-service", "provider-select-service-details"],
    },
    {
      iconName: "icon-withdrawal-cases",
      tabName: Tr.tr("serviceProvider:withdrawal"),
      pathName: "/provider-withdrawal",
      subRoutes: ["my-cases", "provider-withdrawal-details"],
    },
    {
      iconName: "icon-returned-cases",
      tabName: Tr.tr("serviceProvider:returned_cases"),
      pathName: "/provider-returned",
      subRoutes: ["returned-cases", "provider-returned-details"],
    },
    {
      iconName: "icon-returned-cases",
      iconImage: `/img/Rejected-cases.svg`,
      iconImageHover: `/img/Rejected-cases-red.svg`,
      tabName: Tr.tr("serviceProvider:reject_requests"),
      pathName: "/provider-rejected",
      subRoutes: ["reject-cases", "rejected-request-details"],
    },
    {
      iconName: "icon-returned-cases",
      iconImage: `/img/accepted-cases.svg`,
      iconImageHover: `/img/accepted-cases-red.svg`,
      tabName: Tr.tr("serviceProvider:accepted_requests"),
      pathName: "/provider-accepted",
      subRoutes: ["accepted-cases", "accepted-request-details"],
    },
    //
    {
      iconName: "icon-returned-cases",
      iconImage: `/img/accepted-cases.svg`,
      iconImageHover: `/img/accepted-cases-red.svg`,
      tabName: Tr.tr("serviceProvider:accepted_requests_by_another"),
      pathName: "/accepted-by-another-bank",
      subRoutes: [
        "accepted-cases-by-another-bank",
        "accepted-request-details-by-another-bank",
      ],
    },
  ];

  const approvalSideBar = [
    {
      iconName: "icon-withdrawal-cases",
      tabName: Tr.tr("serviceProvider:withdrawal"),
      pathName: "/provider-withdrawal",
      subRoutes: ["my-cases", "approval-provider-withdrawal-details"],
    },
    {
      iconName: "icon-withdrawal-cases",
      iconImage: `/img/accepted-cases.svg`,
      iconImageHover: `/img/accepted-cases-red.svg`,
      tabName: Tr.tr("serviceProvider:accepted_requests"),
      pathName: "/approval-accepted-requests",
      subRoutes: ["approval-accepted-cases", "approval-accepted-case-details"],
    },
  ];

  let routes = null;

  if (userType === USERS_TYPES.Personal) {
    routes = personalSideBar;
  }
  if (userType === USERS_TYPES.Corporate) {
    routes = corporateSideBar;
  }
  if (userType === USERS_TYPES.Maker) {
    routes = makerSideBar;
  }
  if (userType === USERS_TYPES.Approval) {
    routes = approvalSideBar;
  }

  return (
    <div className="col-lg-3">
      <SideBarCommon routes={routes !== null && routes}>
        <div onClick={handleShow}>
          <SideBarTabs
            iconName="icon-sign-out"
            tabName={Tr.tr("sign_out")}
            type="span"
            iconImage
            iconImageHover
          />
        </div>
      </SideBarCommon>
      {/* Sign Out Modal */}
      <Modal show={show} close={handleClose}>
        <span className="TitleFont SignOutModal">
          <Tr tr="Profile:signout_question" />
        </span>
        <button className="Button White ModalLeftButton" onClick={handleClose}>
          {Tr.tr("basic:cancel")}
        </button>
        <button className="Button ModalRightButton" onClick={logOut}>
          {Tr.tr("basic:sign_out")}
        </button>
      </Modal>
    </div>
  );
};

SideBar.propTypes = {};

export default connect(null, { signOut })(withRouter(SideBar));
