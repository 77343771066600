import { all } from "redux-saga/effects";
import { createRequestInstance, watchRequests } from "redux-saga-requests";
import { createDriver } from "redux-saga-requests-axios";
import { onErrorSaga } from "redux/sagas/interceptors/onError";
import { onRequestSaga } from "./sagas/interceptors/onRequest";

export default function* rootSaga(axiosHttpDriver) {
  yield createRequestInstance({
    driver: createDriver(axiosHttpDriver),
    onError: onErrorSaga,
    onRequest: onRequestSaga
  });
  yield all([watchRequests()]);
}
