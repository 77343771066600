import React, { useEffect, useState } from "react";
import styles from "./PrivacyPolicy.module.scss";
import { Tr } from "utils/i18n";
import PagesHead from "components/common/PagesHead/PagesHead";
import parse from "html-react-parser";
import httpDriver from "utils/httpDriver";

const getData = async () => {
	const { data } = await httpDriver.get("pages/all");
	return data.data.policy;
};

const PrivacyPolicy = ({ ...props }) => {
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getData().then((res) => {
			setData(res);
			setIsLoading(false);
		});
	}, []);

	if (isLoading) return <h2>Loading...</h2>;
	return (
		<>
			<PagesHead
				mainTitle={Tr.tr("PrivacyPolicy:privacy_policy")}
				paragraph={data.title}
				imgSrc={data.image}
			/>

			<div className={styles.PrivacyPolicy}>
				<div className="container">{parse(data.body || "")}</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
