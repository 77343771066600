import React from "react";
import styles from "./TableTitle.module.scss";
import { Tr } from "utils/i18n";

const TableTitle = ({ serviceName, type, ...props }) => {
  return (
    <div className={styles.TableTitle}>
      <p className={`TitleFont ${styles.Tit}`}>
        {Tr.tr("serviceProvider:app_loan_service", {
          serviceName: serviceName,
          serviceType: Tr.tr(`serviceProvider:${type}`),
        })}
      </p>
    </div>
  );
};

export default TableTitle;
