// ESSENTIALS
import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// MIDDLEWARES
import { Auth } from "./Middleware/Auth";
import { Maker } from "./Middleware/Maker";
import { Providers } from "./Middleware/Providers";
import { Approval } from "./Middleware/Approval";
import { Guest } from "./Middleware/Guest";

// PAGES
import {
  /* PLOP_INJECT_IMPORT */
  AdDetails,
  AcceptedByAnotherBank,
  ApprovalAcceptedRequests,
  ProviderAccepted,
  RejectRequests,
  ProviderSelectServiceDetails,
  ProviderReturned,
  ProfileSubmitFacility,
  ProfileServices,
  EditProfile,
  PersonalRequestDetails,
  ChangePassword,
  ResetPassword,
  ForgetPassword,
  ProviderWithdrawalDetails,
  ProviderWithdrawal,
  ProviderSelectService,
  ProviderApp,
  SignUp,
  SignIn,
  ProfileBankNews,
  ProfileBankDetails,
  ProfileBankOffers,
  Profile,
  ContactUs,
  NewsPageDetails,
  TermsConditions,
  PrivacyPolicy,
  ServicesDetails,
  Faq,
  Search,
  Page404,
  OurServices,
  OurClients,
  NewsPage,
  About,
  Home,
} from "pages/index";
import MyCases from "pages/ProviderSelectService/MyCases";
import ReturnedCases from "pages/ProviderSelectService/ReturnedCases";
import RejectCases from "pages/RejectRequests/RejectCases";
import RejectRequestsDetails from "pages/RejectRequests/RejectRequestsDetails";

import ApprovalProviderWithdrawalDetails from "pages/ProviderWithdrawalDetails/ApprovalProviderWithdrawalDetails";
import AcceptedCases from "pages/ProviderAccepted/AcceptedCases";
import ApprovalAcceptedCases from "pages/ApprovalAcceptedRequests/ApprovalAcceptedCases";
import AcceptedCasesByAnotherBank from "pages/AcceptedByAnotherBank/AcceptedCasesByAnotherBank";

export default () => (
  <Fragment>
    <Switch>
      {/* Auth */}
      <Route exact strict path="/" component={Home} />
      {/* PLOP_INJECT_ROUTE */}
      <Route exact strict path="/ad-details/:id" component={AdDetails} />
      <Route
        exact
        strict
        path="/accepted-by-another-bank"
        component={AcceptedByAnotherBank}
      />
      <Route
        exact
        strict
        path="/approval-accepted-requests"
        component={ApprovalAcceptedRequests}
      />
      <Route
        exact
        strict
        path="/provider-accepted"
        component={ProviderAccepted}
      />
      <Route
        exact
        strict
        path="/provider-rejected"
        component={RejectRequests}
      />
      <Route
        exact
        strict
        path="/provider-select-service-details/:id"
        component={ProviderSelectServiceDetails}
      />
      <Maker
        exact
        strict
        path="/provider-returned"
        component={ProviderReturned}
      />
      <Auth
        exact
        strict
        path="/profile-submit-facility"
        component={ProfileSubmitFacility}
      />
      <Auth
        exact
        strict
        path="/profile-services/:id"
        component={ProfileServices}
      />
      <Auth
        exact
        strict
        path="/edit-request/:applicationId"
        component={ProfileServices}
      />
      <Route exact strict path="/change-password" component={ChangePassword} />
      <Auth exact strict path="/edit-profile" component={EditProfile} />
      <Auth
        exact
        strict
        path="/personal-request-details/:id"
        component={PersonalRequestDetails}
      />
      <Maker
        exact
        strict
        path="/provider-withdrawal-details/:id"
        component={ProviderWithdrawalDetails}
      />
      <Maker
        exact
        strict
        path="/provider-returned-details/:id"
        component={ProviderWithdrawalDetails}
      />
      <Maker
        exact
        strict
        path="/rejected-request-details/:id"
        component={RejectRequestsDetails}
      />
      <Maker
        exact
        strict
        path="/accepted-request-details/:id"
        component={RejectRequestsDetails}
      />
      <Maker
        exact
        strict
        path="/accepted-request-details-by-another-bank/:id"
        component={RejectRequestsDetails}
      />
      <Approval
        exact
        strict
        path="/approval-provider-withdrawal-details/:id"
        component={ApprovalProviderWithdrawalDetails}
      />
      <Approval
        exact
        strict
        path="/approval-accepted-case-details/:id"
        component={ApprovalProviderWithdrawalDetails}
      />
      <Guest exact strict path="/reset-password" component={ResetPassword} />
      <Guest exact strict path="/forget-password" component={ForgetPassword} />
      <Guest exact strict path="/sign-up" component={SignUp} />
      <Guest exact strict path="/sign-in" component={SignIn} />
      <Auth exact strict path="/profile" component={Profile} />
      <Route
        exact
        strict
        path="/provider-withdrawal"
        component={ProviderWithdrawal}
      />
      <Maker
        exact
        strict
        path="/provider-select-service/:id/:type"
        component={ProviderSelectService}
      />
      <Providers exact strict path="/my-cases/:id/:type" component={MyCases} />
      <Maker
        exact
        strict
        path="/returned-cases/:id/:type"
        component={ReturnedCases}
      />
      <Maker
        exact
        strict
        path="/reject-cases/:id/:type"
        component={RejectCases}
      />
      <Maker
        exact
        strict
        path="/accepted-cases/:id/:type"
        component={AcceptedCases}
      />
      <Maker
        exact
        strict
        path="/accepted-cases-by-another-bank/:id/:type"
        component={AcceptedCasesByAnotherBank}
      />
      <Approval
        exact
        strict
        path="/approval-accepted-cases/:id/:type"
        component={ApprovalAcceptedCases}
      />

      <Maker exact strict path="/provider-app" component={ProviderApp} />
      <Route
        exact
        strict
        path="/profile-bank-news"
        component={ProfileBankNews}
      />
      <Route
        exact
        strict
        path="/profile-bank-details"
        component={ProfileBankDetails}
      />
      <Route
        exact
        strict
        path="/profile-bank-offers"
        component={ProfileBankOffers}
      />
      <Route exact strict path="/sign-up" component={SignUp} />
      <Route exact strict path="/sign-in" component={SignIn} />
      <Route exact strict path="/contact-us" component={ContactUs} />
      <Route
        exact
        strict
        path="/news-page-details"
        component={NewsPageDetails}
      />
      <Route
        exact
        strict
        path="/terms-conditions"
        component={TermsConditions}
      />
      <Route exact strict path="/privacy-policy" component={PrivacyPolicy} />
      <Route
        exact
        strict
        path="/services-details"
        component={ServicesDetails}
      />
      <Route exact strict path="/faq" component={Faq} />
      <Route path="/search" component={Search} />
      <Route exact strict path="/our-services" component={OurServices} />
      <Route exact strict path="/our-clients" component={OurClients} />
      <Route exact strict path="/news-page" component={NewsPage} />
      <Route exact strict path="/about" component={About} />
      <Route exact strict path="/not-found" component={Page404} />
      <Route exact strict component={Page404} />
    </Switch>

    {/* Modal routes */}
    {/* <Route
      render={({ location }) => {
        const hash = qs.parse(location.hash.substr(1));
        return <Modals {...hash} />;
      }}
    /> */}
  </Fragment>
);
