import React from "react";
import styles from "./CoreValues.module.scss";
import { Tr } from "utils/i18n";

const CoreValues = ({ coreValues }) => {
  return (
    <div className={styles.CoreValuesContent}>
      <div className="container">
        <h2 className={`TitleFont ${styles.TitleFont}`}>
          <Tr tr="About:core_values" />
        </h2>
        <div className="row">
          {coreValues.map((value) => (
            <div key={value.id} className="col-6 col-md-4 col-lg-3">
              <div className={styles.CoreValues}>
                <img
                  src={value.image}
                  className={styles.Img}
                  alt="relationships"
                />
                <p className={`SubTitle ${styles.Tit}`}>{value.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
