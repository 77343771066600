import React, { useState } from "react";
import styles from "./ForgetPassword.module.scss";
import { Tr } from "utils/i18n";

import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Field } from "components/fields/index";
import { forgetPassword } from "redux/auth/actions";
const ForgetPassword = ({ ...props }) => {
  const dispatch = useDispatch();
  // Show Forget password Form or Msg when send btn is clicked based on sent value
  const [sent, setSent] = useState(false);
  const [responseError, setresponseError] = useState("");
  return (
    <div className={`${styles.ForgetPassword} SectionPadding`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            {!sent ? (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: "",
                }}
                validate={(values) => {
                  let errors = {};

                  if (!values.email || !values.email.trim())
                    errors.email = Tr.tr("Forms:err_requierd", {
                      field: Tr.tr("Forms:email"),
                    });
                  else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = Tr.tr("Forms:invalid");
                  }

                  return errors;
                }}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setresponseError("");
                  dispatch(forgetPassword({ email: values.email }))
                    .then((res) => {
                      setSent(true);
                    })
                    .catch(({ error }) => {
                      setresponseError(error.response.data.message);
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    action=""
                    className="position-relative"
                    onSubmit={handleSubmit}
                  >
                    <h1 className="TitleFont SubPadding">
                      <Tr tr="Forms:forget_password" />
                    </h1>
                    <div className="GlobalErr DescFont">
                      {/* <Tr tr="Forms:please_confirm_the_sent_email" /> */}
                      <p style={{ color: "red" }}>{responseError}</p>
                    </div>
                    <Field
                      name="email"
                      label={Tr.tr("Forms:email")}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      touched={touched}
                      required
                    />

                    <div className="LogBtns">
                      <button
                        className="Button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <Tr tr="Forms:send" />
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <div className={styles.Success}>
                <div>
                  <h1 className="TitleFont">
                    <Tr tr="Forms:forget_password" />
                  </h1>
                  <h1 className="TitleFont">
                    <Tr tr="Forms:email_confirmed" />
                  </h1>
                  <p className="DescFont">
                    <Tr tr="Forms:you_will_receive_an_email_shortly" />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
