import React from "react";
import styles from "./ClientsBox.module.scss";

const ClientsBox = ({
  imgSrc, //logo of the client
  ...props
}) => {
  return (
    <div className={styles.ClientBox}>
      <img src={imgSrc} alt="client box"  />
    </div>
  );
};

ClientsBox.defaultProps = {};

ClientsBox.propTypes = {};

export default ClientsBox;
