// LIBS
import React, { useState } from "react";
import InputRange from "react-input-range-rtl";
import { connect } from "react-redux";
import { Formik } from "formik";
import Select from "react-select";
import { Link } from "react-router-dom";

//STYLE
import "react-input-range/lib/css/index.css";
import styles from "./RequestService.module.scss";

//COMPONENTS
import { changeQuickForm } from "redux/home/actions";
import { Tr } from "utils/i18n";
import { AuthAccess } from "routes/Middleware/AccessControls";
import history from "utils/history";
import { USERS_TYPES } from "utils/constants/usersTypes";
import Modal from "components/common/ModalComponent/ModalComponent";

const RequestServiceForm = ({
	currDirection,
	facilityOptions,
	corporate,
	...props
}) => {
	const {
		changeQuickForm,
		quickForm,
		authUserType,
		is_applied,
		is_deactivated,
	} = props;
	// 2 = personal page, 6 = corporate page
	const REDIRECT_SERVICE = authUserType === USERS_TYPES.Personal ? 2 : 6;

	const disableSubmitting =
		authUserType === USERS_TYPES.Approval || authUserType === USERS_TYPES.Maker;

	const changeHandler = (field, value, cb) => {
		// changeQuickForm(field, value);
		cb(field, value);
	};
	const onChangeSelect = (field, value, setField) => {
		setField(field, { value: `${value.value}`, label: value.label });
	};

	// Show or Hide state based on show value
	const [show, setShow] = useState(false);

	// Show modal if show value is true [setShow(true)]
	const handleShow = () => {
		setShow(true);
	};

	// Hide modal if show value is false [setShow(false)] [Sign Out Modal]
	const handleClose = () => {
		setShow(false);
	};
	const colourStyles = {
		option: (provided, state) => ({
			...provided,
			color: state.isDisabled ? "#ccc" : "#000",
		}),
	};
	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={{
					type_of_facility: "",
					amount: 0,
					monthly_income: 0,
				}}
				validate={(values) => {
					let errors = {};
					// Object.keys(quickForm).map((key) => {
					if (!values["type_of_facility"])
						errors["type_of_facility"] = Tr.tr("Forms:err_requierd", {
							field: "type_of_facility",
						});
					// });
					return errors;
				}}
				onSubmit={(values) => {
					Object.keys(values).map((input) => {
						changeQuickForm(input, values[input]);
					});
					history.push(`/profile-services/${REDIRECT_SERVICE}`);
				}}
			>
				{({
					values,
					touched,
					handleBlur,
					isSubmitting,
					errors,
					handleSubmit,
					setFieldValue,
					setFieldTouched,
				}) => (
					<form onSubmit={handleSubmit}>
						{console.log(errors)}
						<div className="InputGroup">
							<label htmlFor="" className="Required">
								{Tr.tr("Home:type_required")}
							</label>
							<Select
								onBlur={() => setFieldTouched("type_of_facility", true)}
								onChange={(value) => {
									onChangeSelect("type_of_facility", value, setFieldValue);
								}}
								options={
									facilityOptions
										? facilityOptions.map((v) => {
												return {
													value: v.value,
													label: v.label,
													isDisabled: !v.active,
												};
										  })
										: []
								}
								styles={colourStyles}
								name="type_of_facility"
								value={values.type_of_facility}
								isSearchable={false}
								placeholder={Tr.tr("Forms:select")}
								autoFocus={false}
								className="react-select-container"
								classNamePrefix="react-select"
							/>
							{errors &&
								errors.type_of_facility &&
								touched.type_of_facility && (
									<div className="err">{Tr.tr("Forms:err_requierd")}</div>
								)}
						</div>
						<p className={`DescFont ${styles.DescFont}`}>
							<Tr tr="Home:amount_required" />
						</p>
						{/* <div className={styles.RangeContainer}>
              <InputRange
                name="amount"
                // maxValue={1000000000}
                // minValue={5000}
                direction={currDirection}
                onChange={(val) => {
                  changeHandler("amount", val, setFieldValue);
                }}
                onBlur={handleBlur}
                value={values.amount}
                touched={touched}
              />
              <p className={styles.RangeNumbers}>
                <span className="Font10">5000</span>
                <span className="Font10">1000000000</span>
              </p>
            </div> */}
						<input
							className="Input text-center"
							type="number"
							// min={5000}
							// max={1000000000}
							onChange={(e) => {
								changeHandler("amount", e.target.value, setFieldValue);
							}}
							onKeyPress={(e) => {
								if (e.target.value.length >= 9) {
									e.preventDefault();
								}
							}}
							value={values.amount}
						/>
						{!corporate && (
							<>
								<p className={`DescFont ${styles.DescFont}`}>
									<Tr tr="Home:income" />
								</p>
								{/* <div className={styles.RangeContainer}>
                  <InputRange
                    name="monthly_income"
                    maxValue={1000000000}
                    minValue={5000}
                    direction={currDirection}
                    onChange={(val) => {
                      changeHandler("monthly_income", val, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    value={values.monthly_income}
                    touched={touched}
                  />
                  <p className={styles.RangeNumbers}>
                    <span className="Font10">5000</span>
                    <span className="Font10">1000000000</span>
                  </p>
                </div> */}
								<input
									className="Input text-center"
									type="number"
									value={values.monthly_income}
									// min={5000}
									// max={1000000000}
									onChange={(e) => {
										changeHandler(
											"monthly_income",
											e.target.value,
											setFieldValue
										);
									}}
									onKeyPress={(e) => {
										if (e.target.value.length >= 9) {
											e.preventDefault();
										}
									}}
								/>
							</>
						)}
						<AuthAccess redirectTo={`/profile-services/`}>
							<button
								type={is_applied || is_deactivated ? "button" : "submit"}
								disabled={disableSubmitting}
								className={`Button DescFont ${styles.Submit} ${
									disableSubmitting && styles.disabledServices
								}`}
								onClick={() =>
									(isSubmitting ||
										disableSubmitting ||
										is_applied ||
										is_deactivated) &&
									handleShow
								}
							>
								{Tr.tr("Home:submit")}
							</button>
						</AuthAccess>
					</form>
				)}
			</Formik>
			{/* Applied Service Modal */}
			<Modal show={show} close={handleClose}>
				<span className="TitleFont SignOutModal">
					{is_applied ? (
						<Tr tr="Profile:APPLIED_SERVICE" />
					) : is_deactivated ? (
						<Tr tr="Table:deactive_service" />
					) : (
						""
					)}
				</span>
				<button className="Button White ModalLeftButton" onClick={handleClose}>
					{Tr.tr("basic:cancel")}
				</button>
				<Link
					to={`/personal-request-details/${is_applied}`}
					className="Button ModalRightButton"
				>
					{Tr.tr("Profile:VIEW_THE_REQUEST")}
				</Link>
			</Modal>
		</>
	);
};

const mapStateToProps = ({
	home: {
		quickForm,
		data: { is_applied, is_deactivated },
	},
	auth: { type: authUserType },
}) => ({
	quickForm,
	authUserType,
	is_applied,
	is_deactivated,
});

export default connect(mapStateToProps, { changeQuickForm })(
	RequestServiceForm
);
