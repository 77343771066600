export const en = {
  our_clients: "Our clients",
  desc:
    " We deal with all the financial service providers working in Egypt, whom offer all financial products/services (Investment and Lending facilities) to Individuals and Corporate customers",
};

export const ar = {
  our_clients: "عملائنا",
  desc:
    "نتعامل مع جميع مزودي الخدمات المالية العاملين في مصر ، والذين يقدمون جميع المنتجات / الخدمات المالية (تسهيلات الاستثمار والإقراض) للأفراد والشركات.",
};
