import React, { Component } from "react";
import styles from "./ChangePassword.module.scss";
import { Tr } from "utils/i18n";

import { Link } from "react-router-dom";

import { Formik } from "formik";
import { Field } from "components/fields/index";

import AvatarEdite from "../../components/common/AvatarEdite/AvatarEdite";

import { connect } from "react-redux";
import { changePassword } from "redux/personalProfile/actions";

class ChangePassword extends Component {
  state = {
    errorMessage: "",
    invalidPass: "",
  };

  render() {
    return (
      <div className={`SectionPadding ${styles.ChangePassword}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="row">
                <div className="col-lg-3">
                  {/* User Change Profile Pic */}
                  <AvatarEdite notEditable />
                </div>
                <div className="col-lg-9">
                  <div className={styles.EditForm}>
                    <Formik
                      initialValues={{
                        old_password: "",
                        password: "",
                        password_confirmation: "",
                      }}
                      validate={(values) => {
                        let errors = {};
                        if (!values.old_password) {
                          errors.old_password = Tr.tr("Forms:err_requierd", {
                            field: Tr.tr("Forms:current_password"),
                          });
                        }
                        // else if (values.old_password < 8) {
                        //   errors.old_password = Tr.tr("Forms:err_min", {
                        //     num: 8,
                        //   });
                        // }
                        if (!values.password) {
                          errors.password = Tr.tr("Forms:err_requierd", {
                            field: Tr.tr("Forms:new_password"),
                          });
                        } else if (
                          !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}$/.test(
                            values.password
                          )
                        ) {
                          errors.password = Tr.tr(
                            "Forms:INVALID_PASSWORD_CONTENT"
                          );
                        }

                        if (!values.password_confirmation) {
                          errors.password_confirmation = Tr.tr(
                            "Forms:err_requierd",
                            {
                              field: Tr.tr("Forms:confirm_new_password"),
                            }
                          );
                        } else if (
                          values.password_confirmation !== values.password
                        ) {
                          errors.password_confirmation = Tr.tr(
                            "Forms:doesnt_match"
                          );
                        }
                        console.log(errors);
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, setErrors }) => {
                        this.props
                          .changePassword(values)
                          .then((result) => {
                            this.props.history.goBack();
                            this.setState({
                              errorMessage: "",
                              invalidPass: "",
                            });
                          })
                          .catch((res) => {
                            try {
                              if (res.error.response.data !== undefined) {
                                setErrors(res.error.response.data.data);
                              }
                              if (res.error.response.data.data === null) {
                                setErrors(res.error.response.data.message);
                                this.setState({
                                  invalidPass: res.error.response.data.message,
                                });
                              }
                              this.setState({
                                errorMessage: res.error.response.data.message,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          })
                          .finally(() => {
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form action="" onSubmit={handleSubmit}>
                          <div className="InputGroup">
                            <Field
                              name="old_password"
                              label={Tr.tr("Forms:current_password")}
                              type="password"
                              value={values.old_password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              touched={touched}
                              required
                              invalidPass // send this prop to fix style input group based on this prop in DeafultField component
                            />
                            {this.state.invalidPass && (
                              <div className="err">
                                {this.state.invalidPass}
                              </div>
                            )}
                          </div>
                          <Field
                            name="password"
                            label={Tr.tr("Forms:new_password")}
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            required
                          />
                          <Field
                            name="password_confirmation"
                            label={Tr.tr("Forms:confirm_new_password")}
                            type="password"
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            required
                          />
                          <div className="LogBtns">
                            <Link to="/edit-profile" className="Button White">
                              <Tr tr="basic:back" />
                            </Link>
                            <button className="Button" type="submit">
                              <Tr tr="Forms:change_password" />
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { changePassword })(ChangePassword);
