import React from "react";
import PropTypes from "prop-types";
import styles from "./ShareIcon.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import env from "env";
const ShareIcon = ({
  addtionalClass,
  id,
  itemUrl,
  getDropdownStatus,
  ...props
}) => {
  const SHARE_URL = `${env.BASE_URL}${itemUrl}`;
  return (
    <div className={`HeaderShareIcon ${styles.SocialMedia} ${addtionalClass}`}>
      <Dropdown
        onToggle={(status) => getDropdownStatus && getDropdownStatus(status)}
      >
        <Dropdown.Toggle
          as="span"
          bsPrefix={`ShareButton ${styles.ShareButton}`}
        >
          <i className={`icon-share ${styles.ShareIcon}`}></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/* <Dropdown.Item href="#/action-1">
            <a href="#" className={`icon-instagram ${styles.Icon}`} />
          </Dropdown.Item> */}
          <Dropdown.Item>
            <TwitterShareButton url={SHARE_URL}>
              <i className={`icon-twitter ${styles.Icon}`} />
            </TwitterShareButton>
          </Dropdown.Item>
          <Dropdown.Item>
            <LinkedinShareButton url={SHARE_URL}>
              <i className={`icon-linkedin ${styles.Icon}`} />
            </LinkedinShareButton>
          </Dropdown.Item>
          <Dropdown.Item>
            <FacebookShareButton url={SHARE_URL}>
              <i className={`icon-facebook ${styles.Icon}`} />
            </FacebookShareButton>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

ShareIcon.propTypes = {
  id: PropTypes.number,
};

export default ShareIcon;
