import React from "react";
import PropTypes from "prop-types";
import styles from "./Page500.module.scss";
import { Tr } from "utils/i18n";

const Page500 = ({ ...props }) => {
  return (
    <div className="container">
      <div className={styles.Page500}>
        <img src="../img/logo500.png" className={styles.Logo} alt="500" />
        <p className={styles.ErrorNum}>500</p>
        <p className={`TitleFont ${styles.Tit}`}>
          <Tr tr="Page500:SERVER_ERROR" />
        </p>
      </div>
    </div>
  );
};
Page500.defaultProps = {};

Page500.propTypes = {};

export default Page500;
