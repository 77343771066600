import React, { useEffect, useState } from "react";
import styles from "./ProviderSelectServiceDetails.module.scss";

import SideBar from "components/SideBar";
import { Tr } from "utils/i18n";

import { connect, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  providerServiceDetails,
  withdrawalApplication,
} from "redux/profile/actions";
import ApplicationTd from "../../components/AplicationTd";

import Loader from "components/Loader";

import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";

const ProviderSelectServiceDetails = ({ ...props }) => {
  const dispatch = useDispatch();
  const id = props.match.params.id;
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([]);
  // Download Links
  const [downloadLinks, setDownloadLinks] = useState({});

  // Date & Time Submission
  const [dateTimeSubmission, setDateTimeSubmission] = useState("");

  // Loader
  const [loading, setLoading] = useState(true);

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    props
      .providerServiceDetails(id)
      .then((result) => {
        if (result.data) {
          setLoading(false);
          const dataToRemove = ["company_name","commercial_register_number","contact_person_name","phone_number","document", "full_name", "date_of_birth", "phone_number", "national_id_number", "hr_letter"]
          const newData = result.data.data.form.filter(item => {
            return !dataToRemove.includes(item.name)
          })
          setRows([
            ...newData,
            {
              label: Tr.tr("Table:lower_service_name"),
              value: result.data.data.user_service.service.name,
            },
            {
              label: Tr.tr("Table:service_type"),
              value: result.data.data.user_service.service.type,
            },
          ]);
          setDownloadLinks(result.data.data.download_links);
          setDateTimeSubmission(result.data.data.user_service.created_at);
        }
      })
      .catch((res) => {
        if (res.error) {
          if (res.error.response.data.message) {
            setLoading(false);
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });
  }, []);

  return (
    <div className="AddPadding">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!message ? (
              <>
                <div className={styles.Tit}>
                  <p className="TitleFont">
                    <Tr tr="Profile:case_id" />
                  </p>
                  {/* {Object.keys(downloadLinks).length > 0 && (
                    <div className={styles.DownloadLinks}>
                      {downloadLinks.document.length > 0 && (
                        <a
                          href={downloadLinks.document}
                          rel="noopener noreferrer"
                          target="_blank"
                          className={`DescFont ${styles.Download}`}
                        >
                          <i className={`icon-next ${styles.DownloadIcon}`}></i>
                          <Tr tr="Profile:download_doc" />
                        </a>
                      )}
                      {downloadLinks.hr_letter.length > 0 && (
                        <a
                          href={downloadLinks.hr_letter}
                          rel="noopener noreferrer"
                          target="_blank"
                          className={`DescFont ${styles.Download}`}
                        >
                          <i className={`icon-next ${styles.DownloadIcon}`}></i>

                          <Tr tr="serviceProvider:download_hr_letter" />
                        </a>
                      )}
                    </div>
                  )} */}
                </div>
                <span className={`DescFont ${styles.CaseId}`}>{id}</span>
                <div className={styles.TableWrapper}>
                  <div className="row d-flex justify-content-lg-between">
                    <div className="col-lg-8">
                      {!loading ? (
                        <>
                          <table>
                            <tbody>
                              <ApplicationTd
                                rows={rows}
                                dateTimeSubmission={dateTimeSubmission}
                              />
                            </tbody>
                          </table>
                          <button
                            className={`Button DescFont ${styles.Button} mb-2`}
                            onClick={() => {
                              dispatch(withdrawalApplication({ ids: [id] }));
                              props.history.goBack();
                            }}
                          >
                            {Tr.tr("serviceProvider:withdraw")}
                          </button>
                        </>
                      ) : (
                        <Loader loading={loading} />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <BadRequestMessage
                message={message}
                redirectPage="/provider-app"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { providerServiceDetails })(
  withRouter(ProviderSelectServiceDetails)
);
