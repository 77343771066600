import React from "react";
import PropTypes from "prop-types";
import {
    Field as FormikField,
    FastField as FormikFastField,
} from "formik";
import {DEFULT_MAX_INPUT_LENGTH} from "utils/constants/basic";

function Field({dependent, ...props}) {
    return (
        <>
            {dependent ? (
                <FormikField
                    maxLength={
                        props.component === "textarea" ? null : DEFULT_MAX_INPUT_LENGTH
                    }
                    {...props}
                />
            ) : (
                <FormikFastField
                    maxLength={
                        props.component === "textarea" ? null : DEFULT_MAX_INPUT_LENGTH
                    }
                    {...props}
                />
            )}

            {/* {!hideError && (
        <ErrorMessage
          name={props.name}
          render={
            renderError
              ? renderError
              : errorMessage => <div>{errorMessage}</div>
          }
          {...restErrorProps}
        />
      )} */}
        </>
    );
}

Field.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string
};

Field.defaultProps = {
    type: "text"
};

export {Field};
