import React, { Component } from "react";
import { Tr } from "utils/i18n";
import PagesHead from "components/common/PagesHead/PagesHead";
import PersonalServices from "./components/PersonalServices/PersonalServices";
import CorporateServices from "./components/CorporateServices/CorporateServices";
import { connect } from "react-redux";
import { loadServices } from "redux/ourServices/actions";

import Loader from "../../components/Loader";

class OurServices extends Component {
	state = {
		personalServices: [],
		corporateServices: [],
		loading: true,
	};

	componentDidMount() {
		this.props.loadServices().then((result) => {
			this.setState({
				personalServices: result.data.data.personal,
				corporateServices: result.data.data.corporate,
				loading: false,
			});
		});
	}

	render() {
		const personalServices = this.state.personalServices;
		const corporateServices = this.state.corporateServices;
		return (
			<>
				<PagesHead
					mainTitle={Tr.tr("OurServices:our_services")}
					paragraph={Tr.tr("OurServices:desc")}
					imgSrc="/img/services.png"
				/>

				{!this.state.loading ? (
					<>
						<PersonalServices
							personalServices={personalServices}
							loading={this.state.loading}
						/>

						<CorporateServices
							corporateServices={corporateServices}
							loading={this.state.loading}
						/>
					</>
				) : (
					<div className="container">
						<div className={`AddPadding`}>
							<Loader loading={this.state.loading} />
						</div>
					</div>
				)}
			</>
		);
	}
}

export default connect(null, { loadServices })(OurServices);
