import React from "react";
import styles from "./LocalizationDropDown.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Tr } from "utils/i18n";
import { LanguageDropDown } from "components";
import { withChangeLang } from "components/hocs/withChangeLang";

const LocalizationDropDown = ({ currLang, classes, ...props }) => {
  let lang;
  setTimeout(() => {
    lang = localStorage.getItem("i18nextLng");
  });
  return (
    <Dropdown className={styles.LocalizationDropDown}>
      <Dropdown.Toggle as="span" bsPrefix={classes.DropBtn}>
        {/* <i className={`icon-language ${classes.NavItem}`}></i> */}
        {/* <div className={`${classes.NavItem} ${styles.LangIcon}`}></div> */}
        <div className={`${classes.NavItem} ${styles.LanguageIcon}`}>
          <img src="/img/lang-icon.svg" alt="lang icon" />
          <img src="/img/lang-icon-hover.svg" alt="lang icon" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu alignRight={lang === "ar" ? false : true}>
        <div className={styles.Inner}>
          <p className={`OtherB ${styles.OtherB}`}>{Tr.tr("basic:language")}</p>
          <LanguageDropDown />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withChangeLang()(LocalizationDropDown);
