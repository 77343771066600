import React, { useState, useEffect } from "react";
import styles from "./ProfileBankDetails.module.scss";

import SideBar from "components/SideBar";
import OfferDetails from "./components/OfferDetails/OfferDetails";

import { connect } from "react-redux";
import { getOfferById } from "redux/personalOffers/actions";

import { date } from "utils/date";

const ProfileBankDetails = ({ ...props }) => {
  const [offerDetails, setOfferDetails] = useState({});

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    props.getOfferById(id).then((res) => {
      setOfferDetails(res.data.data);
    });
  }, []);

  let data = offerDetails;

  // call date() from utils to make Date Abbreviations
  let theDate = date(data.created_at);

  return (
    <div className={`AddPadding ${styles.ProfileBankDetails}`}>
      <div className="container">
        <div className="row">
          <SideBar />
          {offerDetails && offerDetails.bank && (
            <div className="col-lg-9">
              <OfferDetails
                date={theDate}
                name={offerDetails.bank.name}
                offerTitle={offerDetails.title}
                offerDesc={offerDetails.description}
                {...offerDetails}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProfileBankDetails.defaultProps = {};

ProfileBankDetails.propTypes = {};

export default connect(null, { getOfferById })(ProfileBankDetails);
