import React, { useEffect, useState } from "react";
import PagesHead from "components/common/PagesHead/PagesHead";
import OurVision from "./components/OurVision/OurVision";
import CoreValues from "./components/CoreValues/CoreValues";
import OurTeam from "./components/OurTeam/OurTeam";
import { Tr } from "utils/i18n";
import { loadAboutPage } from "redux/main/actions";
import { connect } from "react-redux";
import Loader from "components/Loader";

const About = ({ ...props }) => {
  const [data, setdata] = useState(null);
  useEffect(() => {
    props.loadAboutPage().then((res) => setdata(res.data.data));
  }, []);
  return (
    <React.Fragment>
      {(data && (
        <>
          <PagesHead
            addtionalClass="TitleFontM"
            buttonType="button"
            mainTitle={Tr.tr("About:about_us")}
            title={data.about_us.title}
            paragraph={data.about_us.description}
            imgSrc={data.about_us.image_video || "/img/header-video.png"}
            withButton
            buttonContent={Tr.tr("About:play")}
            videoUrl={data.about_us.video_url}
          />
          <OurVision
            ourMission={data.about_us.our_mission_description}
            ourVision={data.about_us.our_vision_description}
            imgSrc={data.about_us.image}
          />

          <CoreValues coreValues={data.core_values} />

          <OurTeam team={data.teams} />
        </>
      )) || <Loader />}
    </React.Fragment>
  );
};

export default connect(null, { loadAboutPage })(About);
