import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAcceptedRequests } from "redux/profile/actions";
import ProviderLayout from "layout/ProviderLayout/index";

const ProviderAccepted = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [corporateCount, setCorporateCount] = useState([]);
  const [personalCount, setPersonalCount] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    props
      .getAcceptedRequests()
      .then(({ data: { data } }) => {
        if (data) {
          setLoading(false);
          setCorporateCount(data.corporate);
          setPersonalCount(data.personal);
        }
      })
      .catch((res) => {
        if (res.error) {
        if (res.error.response.data.message) {
          setLoading(false);
          setMessage(res.error.response.data.message);
        }}
      });
  }, []);

  return (
    <ProviderLayout
      {...{
        loading,
        corporateCount,
        personalCount,
        message,
        serviceUrl: "accepted-cases",
      }}
    />
  );
};

export default connect(null, { getAcceptedRequests })(ProviderAccepted);
