import React from "react";
import styles from "./ServiceCounterCard.module.scss";

const WithdrawalCases = ({
  text, //text written of the card
  number, //number written in the card
  ...props
}) => {
  return (
    <div className={styles.WithdrawalCases}>
      <p className={`DescFont ${styles.Tit}`}>{text}</p>
      <p className={`TitleFont ${styles.Desc}`}>{number}</p>
    </div>
  );
};

export default WithdrawalCases;
