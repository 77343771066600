import React, { useState, useEffect } from "react";
import styles from "./ProfileBankOffers.module.scss";

import SideBar from "components/SideBar";
import Offers from "components/common/Offers/Offers";

import { connect } from "react-redux";
import { withLoadMore } from "components/hocs/index";
import { loadPersonalOffers } from "redux/personalOffers/actions";

import { date } from "utils/date";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { Response } from "../../utils/Response";
import { usePagination } from "utils/usePagination";

import { Tr } from "utils/i18n";

const ProfileBankOffers = ({ ...props }) => {
  const [offers, setOffers] = useState([]);
  const [response, setResponse] = useState(Response);
  const { page, loading, setLoading, onPaginate } = usePagination();

  useEffect(() => {
    props
      .loadPersonalOffers("?page=" + page)
      .then((res) => {
        setLoading(false);
        setOffers(res.data.data.data);
        setResponse(res.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <div className="container">
      <div className={styles.ProfileBankOffers}>
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!loading ? (
              <>
                {offers && offers.length > 0 ? (
                  <div className="row">
                    {/* Loop over Offers card and appear them based on Array Number */}
                    {offers.map((offer, index) => {
                      return (
                        <div className="col-md-6" key={index}>
                          <Offers
                            imgSrc={offer.bank.logo}
                            date={date(offer.created_at)}
                            name={offer.bank.name}
                            // number="100.000"
                            // percentage="1%"
                            title={offer.title}
                            desc={offer.description}
                            requestPath={`/profile-bank-details?id=${offer.id}`}
                          />
                        </div>
                      );
                    })}
                    <div className="col-12">
                      <Pagination response={response} onPaginate={onPaginate} />
                    </div>
                  </div>
                ) : (
                  <div className="noData">
                    {Tr.tr("basic:there_are_no_data")}
                  </div>
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { loadPersonalOffers })(
  withLoadMore({ loadAction: "loadPersonalOffers" })(ProfileBankOffers)
);
