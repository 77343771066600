import React from "react";
import { useAuth } from "utils/useAuthHook";
import { withRouter } from "react-router-dom";
import { USERS_TYPES } from "utils/constants/usersTypes";

export const AuthAccess = withRouter(
  ({ children, hide, redirectTo, ...props }) => {
    /**
     *if user logged in return the "children" prop
     *if user not logged in redirect to sign in on click, if pass "hide" prop return null
     */
    let [, token] = useAuth();

    return token
      ? children
      : hide
      ? null
      : React.cloneElement(children, {
          onClick: (e) => {
            e.preventDefault();
            props.history.replace({
              pathname: "/sign-in",
              state: { from: redirectTo },
            });
          },
        });
  }
);

export const UsersOnlyAccess = withRouter(({ children, hide, ...props }) => {
  /**
   *if user type == "personal" || "corporate" render the children, if "hide" prop passed: render nothing,else not add "disabled" property to children
   */
  let [userType, token] = useAuth();
  if (token) {
    return userType === USERS_TYPES.Personal ||
      userType === USERS_TYPES.Corporate
      ? children
      : hide
      ? null
      : React.cloneElement(children, {
          disabled: true,
        });
  } else {
    return hide
      ? null
      : React.cloneElement(children, {
          onClick: (e) => {
            e.preventDefault();
            props.history.replace("/sign-in");
          },
        });
  }
});
