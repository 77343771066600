import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loadServices, loadApprovalServices } from "redux/profile/actions";
import ProviderLayout from "layout/ProviderLayout";
import { useAuth } from "utils/useAuthHook";
import { USERS_TYPES } from "utils/constants/usersTypes";

const ProviderWithdrawal = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [corporateCount, setCorporateCount] = useState([]);
  const [personalCount, setPersonalCount] = useState([]);
  const [message, setMessage] = useState("");
  const [userType] = useAuth();

  const action = () => {
    if (userType === USERS_TYPES.Approval) {
      return props.loadApprovalServices();
    } else {
      return props.loadServices();
    }
  };

  useEffect(() => {
    action()
      .then((result) => {
        if (result.data) {
          setLoading(false);
          setPersonalCount(result.data.data.personal);
          setCorporateCount(result.data.data.corporate);
        }
      })
      .catch((res) => {
        if (res.error) {
        if (res.error.response.data.message) {
          setLoading(false);
          setMessage(res.error.response.data.message);
        }}
      });
  }, []);

  return (
    <ProviderLayout
      {...{
        loading,
        corporateCount,
        personalCount,
        message,
        serviceUrl: "my-cases",
      }}
    />
  );
};

export default connect(null, { loadServices, loadApprovalServices })(
  ProviderWithdrawal
);
