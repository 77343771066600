import * as T from "./types";

// Load Faqs
export const loadFaqs = () => ({
	type: T.LOAD_FAQS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/faqs`,
		method: "get",
	},
});

// Contact Us
export const contactUs = (data) => ({
	type: T.CONTACT_US,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}contact-us`,
		method: "post",
		data,
	},
});

// Subscribe
export const subscribe = (data) => ({
	type: T.SUBSCRIBE,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}subscribe`,
		method: "post",
		data,
	},
});

export const uploadImage = (data) => ({
	type: T.UPLOAD_IMAGE,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}images/upload`,
		method: "post",
		data,
	},
});

// Load Settings [Footer Social, Contact Us Info, Google Map In Contact Us]
export const loadSettings = () => ({
	type: T.LOAD_SETTINGS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/settings`,
		method: "get",
	},
	meta: {
		takeLatest: false,
	},
});

export const getSearchResults = (name) => ({
	type: T.LOAD_SEARCH_RESULTS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/search`,
		method: "get",
		params: { name },
	},
});

export const getServicesSearchResults = (name) => ({
	type: T.LOAD_SEARCH_RESULTS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/search-services`,
		method: "get",
		params: { name },
	},
});

export const getNewsSearchResults = (name, page) => ({
	type: T.LOAD_SEARCH_RESULTS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/search-news`,
		method: "get",
		params: { name, page },
	},
});

export const getOffersSearchResults = (name, page) => ({
	type: T.LOAD_SEARCH_RESULTS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/search-offers`,
		method: "get",
		params: { name, page },
	},
});

export const loadAllAds = (page) => ({
	type: T.LOAD_ALL_ADS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/header-slider`,
		type: "get",
		params: { page },
	},
});

export const loadSliderAds = () => ({
	type: T.LOAD_SLIDER_ADS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/header-slider-slider`,
		method: "get",
	},
});

export const loadAdDetails = (id) => ({
	type: T.LOAD_AD_DETAILS,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/header-slides/${id}`,
		method: "get",
	},
});

export const loadAboutPage = () => ({
	type: T.LOAD_ABOUT,
	request: {
		url: `${process.env.REACT_APP_API_BASE_URL}pages/about-us`,
		method: "get",
	},
});
