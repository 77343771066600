import React from "react";
import PropTypes from "prop-types";
import { Field } from "./_Field";

function CheckBoxCustomFieldComponent({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  name,
  checked,
  isChecked,
  hint,
  required,
  NotRobot,
  ...props
}) {
  return (
    <>
      <label className={`CheckBox ${required ? "Required" : ""} ${NotRobot}`}>
        <input
          type="checkbox"
          name={name}
          className="check-input d-none"
          onChange={checked}
          checked={isChecked}
        />
        <span className="check-span"></span>
        <p className={`CheckLabel DescFont`}>{props.label}</p>
      </label>
      <div className="Hint">{hint}</div>
    </>
  );
}

function CheckBoxField({ ...props }) {
  return <Field {...props} component={CheckBoxCustomFieldComponent} />;
}

CheckBoxField.propTypes = {
  name: PropTypes.string.isRequired,
};

export { CheckBoxField };
