import React, { Component } from "react";
import styles from "./Maps.module.scss";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { withChangeLang } from "components/hocs/index";

export class Maps extends Component {
  state = {
    stores: [
      {
        latitude: 47.3084488,
        longitude: -122.2140121,
        name: {
          // windowTitle: "USA",
          windowContent: "2200 Western Ave, Suite 200, Seattle, WA 98121",
        },
      },
    ],
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  componentDidUpdate(prevProps) {
    if (prevProps.info !== this.props.info) {
      let { currLang } = this.props;
      console.log(currLang);
      this.setState({
        stores: [
          {
            latitude: this.props.info && Number(this.props.info.location_lat),
            longitude: this.props.info && Number(this.props.info.location_lang),
            name: {
              // windowTitle: "USA",
              windowContent:
                this.props.info && this.props.info[`location_${currLang}`],
            },
          },
        ],
      });
    }
  }

  onMarkerClick = (props, marker, e) => {
    props.icon.url === "/img/marker.png"
      ? this.setState({
          showingInfoWindow: true,
          activeMarker: marker,
          selectedPlace: props,
        })
      : this.setState({
          showingInfoWindow: false,
          activeMarker: {},
        });
  };

  closeInfoWindow = () => {
    this.setState({
      showingInfoWindow: false,
      activeMarker: {},
    });
  };

  displayMarkers = () => {
    return (
      this.state.stores &&
      this.state.stores.map((store, index) => {
        return (
          <Marker
            onClick={this.onMarkerClick}
            key={index}
            id={index}
            position={{
              lat: store.latitude,
              lng: store.longitude,
            }}
            icon={{
              url:
                this.state.activeMarker.id == index
                  ? "/img/red-marker.png"
                  : "/img/marker.png",
              scaledSize: new this.props.google.maps.Size(30, 36),
            }}
            name={store.name}
          />
        );
      })
    );
  };

  render() {
    return (
      <div className={styles.ContactMap}>
        <Map
          google={this.props.google}
          zoom={8}
          styles={this.props.mapStyles}
          initialCenter={{ lat: 47.444, lng: -122.176 }}
        >
          {this.displayMarkers()}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.closeInfoWindow}
          >
            <div className="InfoWindow">
              <p className="SubTitle">
                {this.state.selectedPlace.name &&
                  this.state.selectedPlace.name.windowTitle}
              </p>
              <p className="Other">
                {this.state.selectedPlace.name &&
                  this.state.selectedPlace.name.windowContent}
              </p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

Maps.defaultProps = {
  mapStyles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ],
};

export default withChangeLang()(
  GoogleApiWrapper({
    apiKey: "AIzaSyC5uC_mExFIMSehvCgsjegxcF7mTpKmI4w",
  })(Maps)
);
