import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getNewsSearchResults } from "redux/main/actions";
import { Link } from "react-router-dom";
import { Tr } from "utils/i18n";
import ResultCount from "./ResultCount";
import styles from "../Search.module.scss";
import NewsBox from "components/common/NewsPage/NewsPage";
import { date } from "utils/date";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { Response } from "utils/Response";
import { usePagination } from "utils/usePagination";

function NewsResults({ search, ...props }) {
  const [news, setnews] = useState(null);
  const [count, setcount] = useState(0);
  const [response, setResponse] = useState(Response);
  const { page, loading, setLoading, onPaginate } = usePagination();
  useEffect(() => {
    setLoading(true);
    props
      .getNewsSearchResults(search, page)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data.news);
        setnews(res.data.data.news.data);
        setcount(res.data.data.count);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [search, page]);
  if (loading) {
    return <Loader loading={loading} />;
  }
  return (
    <div className="container">
      {/* RESULTS FOUND BAR */}
      <ResultCount {...{ count }} />

      <div className={styles.SectionPadding}>
        <div className={styles.Tit}>
          <p className={`TitleFont ${styles.TitleFont}`}>
            <Tr tr="Search:news" />
          </p>
          <Link className={`DescFont ${styles.SeeAll}`} to="/news-page">
            <Tr tr="Search:see_all" />
          </Link>
        </div>
        <>
          {news && news.length ? (
            <div className="row">
              {/* show corporate services cards based on Arary Number */}
              {news.map((item, index) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={index}>
                    <NewsBox
                      key={index}
                      requestPath={`/news-page-details?id=${item.id}`}
                      date={date(item.created_at)}
                      imgSrc={item.image}
                      desc={item.title}
                      id={item.id}
                    />
                  </div>
                );
              })}
              <div className="col-12">
                <Pagination response={response} onPaginate={onPaginate} />
              </div>
            </div>
          ) : (
            <div className={styles.NoResultsFound}>
              {Tr.tr("Search:no_results")}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default connect(null, { getNewsSearchResults })(NewsResults);
