import React, { useEffect, useState } from "react";
import styles from "./NewsPage.module.scss";
import NewsBox from "components/common/NewsPage/NewsPage";
import NewsPageSlider from "./components/NewsPageSlider/NewsPageSlider";
import { connect } from "react-redux";
import { withLoadMore } from "components/hocs/index";
import { laodNews } from "redux/news/actions";
import { date } from "utils/date";
import { Response } from "../../utils/Response";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { usePagination } from "utils/usePagination";
import { Tr } from "utils/i18n";

const NewsPage = (props) => {
  const [news, setNews] = useState([]);
  const [response, setResponse] = useState(Response);
  const { page, loading, setLoading, onPaginate } = usePagination();

  useEffect(() => {
    props
      .laodNews("?page=" + page)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data);
        setNews(res.data.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <>
      <NewsPageSlider requestPath="/news-page-details" />

      <div className={styles.NewsPageCards}>
        <div className="container">
          {!loading ? (
            <>
              {news && news.length > 0 ? (
                <div className="row">
                  {/* Loop over NewsBox card and appear them based on Array Number */}
                  {news.map((news, index) => (
                    <div className="col-lg-4" key={index}>
                      <NewsBox
                        key={index}
                        requestPath={`/news-page-details?id=${news.id}`}
                        date={date(news.created_at)}
                        imgSrc={
                          news.image !== null
                            ? news.image
                            : "/img/latestnews.png"
                        }
                        desc={news.content}
                        id={news.id}
                      />
                    </div>
                  ))}
                  <div className="col-12">
                    <Pagination response={response} onPaginate={onPaginate} />
                  </div>
                </div>
              ) : (
                <div className="noData">{Tr.tr("basic:there_are_no_data")}</div>
              )}
            </>
          ) : (
            <Loader loading={loading} />
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, { laodNews })(
  withLoadMore({ loadAction: "laodNews" })(NewsPage)
);
