import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getServicesSearchResults } from "redux/main/actions";
import CreditCard from "components/common/CreditCard/CreditCard";
import { Tr } from "utils/i18n";
import styles from "../Search.module.scss";
import { Link } from "react-router-dom";
import ResultCount from "./ResultCount";
import Loader from "components/Loader";
import { useAuth } from "utils/useAuthHook";
import { USERS_TYPES } from "utils/constants/usersTypes";

function ServicesResults({ search, ...props }) {
  const [services, setservices] = useState(null);
  const [count, setcount] = useState(0);
  const [loading, setloading] = useState(false);

  let [userType] = useAuth();

  useEffect(() => {
    setloading(true);
    props.getServicesSearchResults(search).then((res) => {
      setloading(false);
      setcount(res.data.data.count);
      setservices(res.data.data.services);
    });
  }, [search]);

  if (services && !services.personal.length && !services.corporate.length) {
    return (
      <div className="container">
        <ResultCount {...{ count }} />
        <div className={styles.SectionPadding}>
          <div className={styles.Tit}>
            <p className={`TitleFont ${styles.TitleFont}`}>
              <Tr tr="Search:services" />
            </p>
            <Link className={`DescFont ${styles.SeeAll}`} to="/our-services">
              <Tr tr="Search:see_all" />
            </Link>
          </div>
          <div className={styles.NoResultsFound}>
            {Tr.tr("Search:no_results")}
          </div>
        </div>
      </div>
    );
  }
  if (loading) {
    return <Loader loading={loading} />;
  }
  return (
    <div className="container">
      {/* Results count */}
      <ResultCount {...{ count }} />
      <>
        <div className={styles.SectionPadding}>
          <div className={styles.Tit}>
            <p className={`TitleFont ${styles.TitleFont}`}>
              <Tr tr="Search:services" />
            </p>
            <Link className={`DescFont ${styles.SeeAll}`} to="/our-services">
              <Tr tr="Search:see_all" />
            </Link>
          </div>
          {userType !== USERS_TYPES.Corporate && (
            <div className={styles.PersonalServices}>
              <p className={`TitleFont ${styles.Title}`}>
                {Tr.tr("basic:personal")}
              </p>
              <div className="row">
                <>
                  {services && services.personal.length ? (
                    services.personal.map((service, index) => {
                      return (
                        <div
                          className="col-12 col-md-6 col-lg-4"
                          key={service.id}
                        >
                          <CreditCard
                            key={index}
                            requestPath={`/profile-services/${service.id}`}
                            detailsPath={`/profile-services/${service.id}`}
                            imgSrc={service.image}
                            title={service.name}
                            desc={service.description}
                            isApplied={service.is_applied}
                            userServiceId={service.user_service_id}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12">
                      <div className={styles.NoResultsFound}>
                        {Tr.tr("basic:no_results_found")}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
          {userType !== USERS_TYPES.Personal && (
            <div className={styles.CorporateServices}>
              <p className={`TitleFont ${styles.Title}`}>
                {Tr.tr("basic:corporate")}
              </p>
              <div className="row">
                <>
                  {services && services.corporate.length ? (
                    services.corporate.map((service, index) => {
                      return (
                        <div
                          className="col-12 col-md-6 col-lg-4"
                          key={service.id}
                        >
                          <CreditCard
                            key={index}
                            requestPath={`/profile-services/${service.id}`}
                            detailsPath={`/profile-services/${service.id}`}
                            imgSrc={service.image}
                            title={service.name}
                            desc={service.description}
                            isApplied={service.is_applied}
                            userServiceId={service.user_service_id}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12">
                      <div className={styles.NoResultsFound}>
                        {Tr.tr("basic:no_results_found")}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default connect(null, { getServicesSearchResults })(ServicesResults);
