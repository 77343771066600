import * as T from "./types";

export const loadPersonalOffers = (page) => ({
  type: T.LOAD_PERSONAL_OFFERS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/offers${page}`,
    method: "get",
  },
});

export const getOfferById = (id) => ({
  type: T.GET_OFFER_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/offers/${id}`,
  },
});
