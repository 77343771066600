import React, { useState } from "react";
import styles from "./PagesHead.module.scss";

import { Tr } from "utils/i18n";

import { Link } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

const PagesHead = ({
  mainTitle, //main title of the page (aboutus,ourservices,...)
  title, //title of the page (the red title)
  paragraph, //description included in the header
  imgSrc, //the image of the header
  buttonType, //type which says if it's a button or a link
  buttonContent, //text written in the button
  withButton, //tells us if this header contains a button or not
  path, //the path that the link will redirects to
  addtionalClass, //prop that gives an additional styling (sent if needed)
  isApplied, // props which says if this service is applied before or not
  videoUrl,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className={`${styles.Header} ${addtionalClass}`}
        style={{ paddingBottom: withButton ? "25px" : "0" }}
      >
        <div className={styles.HeaderBg} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              {mainTitle && (
                <p className={`TitleFont ${styles.titleFont}`}>{mainTitle}</p>
              )}

              {title && <p className={styles.Tit}>{title}</p>}

              {paragraph && (
                <p className={`DescFont ${styles.Desc}`}>{paragraph}</p>
              )}

              <div className={styles.AboutUsVideo}>
                <div className={styles.Img}>
                  <img src={imgSrc} alt="" />
                </div>
                {buttonType === "button" ? (
                  <button
                    href="#"
                    className={`Button DescFont ${styles.Play}`}
                    onClick={handleShow}
                  >
                    <i className="icon-play" />
                    {buttonContent}
                  </button>
                ) : buttonType === "link" ? (
                  isApplied === 0 ? (
                    <Link
                      to={path}
                      className={`Button DescFont ${styles.Play}`}
                    >
                      {buttonContent}
                    </Link>
                  ) : (
                    <Link
                      to={path}
                      className={`Button DescFont ${styles.Play} ${styles.disabledServices}`}
                      title={Tr.tr("Profile:APPLIED_SERVICE")}
                      onClick={(e) => e.preventDefault()}
                    >
                      {buttonContent}
                    </Link>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="VideoModal">
        <button className="CloseIcon" onClick={handleClose}>
          <i className="icon-close"></i>
        </button>
        <Modal.Body>
          <iframe src={videoUrl} title="youtube video"></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

PagesHead.defaultProps = {};

PagesHead.propTypes = {};

export default PagesHead;
