export const PERSONAL_PROFILE = "PERSONAL_PROFILE";
export const Profile_Submit_Facility = "Profile_Submit_Facility";
export const PROFILE_SERVIVES = "PROFILE_SERVIVES";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const APPLY_SERVICE = "APPLY_SERVICE";
export const NEW_SERVICES_FOR_MAKER = "NEW_SERVICES_FOR_MAKER";

export const WITHDRAWAL_APPLICATION = "WITHDRAWAL_APPLICATION";
export const GET_WITH_DARWAL_APPLICATION = "GET_WITH_DARWAL_APPLICATION";
export const APPLICATION_DETAILS = "APPLICATION_DETAILS";
export const GET_MAKER_WITH_DRAWAL = "GET_MAKER_WITH_DRAWAL";
export const GET_PROVIDER_RETURNED_SERVICES = "GET_PROVIDER_RETURNED_SERVICES";
export const GET_PROVIDER_RETURNED_BY_ID = "GET_PROVIDER_RETURNED_BY_ID";
export const MAKER_APPLICATION_DETAILS = "MAKER_APPLICATION_DETAILS";
export const GET_MAKER_REJECT_REQUESTS = "GET_MAKER_REJECT_REQUESTS";
export const GET_SERVICE_REJECTED_BY_ID = "GET_SERVICE_REJECTED_BY_ID";
export const GET_SERVICE_ACCEPTED_BY_ID = "GET_SERVICE_ACCEPTED_BY_ID";

export const REJECT_WITHDRAWAL = "REJECT_WITHDRAWAL";
export const ACCEPT_WITHDRAWAL = "ACCEPT_WITHDRAWAL";

export const RETURN_TO_MAKER = "RETURN_TO_MAKER";
export const LOAD_REJECT_RESONS = "LOAD_REJECT_RESONS";

export const LOAD_OUR_SERVICES = "LOAD_OUR_SERVICES";
export const LOAD_ACCEPT_APPROVAL_FORM = "LOAD_ACCEPT_APPROVAL_FORM";

export const LOAD_MAKER_APPROVAL_COMMENTS = "LOAD_MAKER_APPROVAL_COMMENTS";
export const APPROVAL_ACCEPT = "APPROVAL_ACCEPT";

export const LOAD_APPROVAL_SERVICES = "LOAD_APPROVAL_SERVICES";

export const ACCEPT_OFFER_INFO = "ACCEPT_OFFER_INFO";

export const ACCEPT_OFFER = "ACCEPT_OFFER";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const FORM_WITH_VALUE = "FORM_WITH_VALUE";

export const PROVIDER_SERVICE_DETAILS = "PROVIDER_SERVICE_DETAILS";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const  GET_NATIONALITIES = " GET_NATIONALITIES";

export const GET_ACCEPTED_REQUESTS = "GET_ACCEPTED_REQUESTS";

export const GET_ACCEPTED_REQUESTS_BY_ANOTHER_BANK =
  "GET_ACCEPTED_REQUESTS_BY_ANOTHER_BANK";

export const GET_APPROVAL_ACCEPTED_REQUESTS = "GET_APPROVAL_ACCEPTED_REQUESTS";

export const GET_SERVICE_ACCEPTED_BY_MAKER = "GET_SERVICE_ACCEPTED_BY_MAKER";
export const LOAD_REJECT_REASONS_BY_ID = "LOAD_REJECT_REASONS_BY_ID";

export const LOAD_MAKER_APPROVED_DATA = "LOAD_MAKER_APPROVED_DATA";
export const LOAD_APPROVAL_APPROVED_DATA = "LOAD_APPROVAL_APPROVED_DATA";
