import React, { useState, useEffect } from "react";
import styles from "./ProfileBankNews.module.scss";
import NewsBox from "components/common/NewsPage/NewsPage";
import { laodNews } from "redux/news/actions";
import { connect } from "react-redux";
import { withLoadMore } from "components/hocs/index";

import SideBar from "components/SideBar";

import { date } from "utils/date";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { Response } from "../../utils/Response";
import { usePagination } from "utils/usePagination";

import { Tr } from "utils/i18n";

const ProfileBankNews = ({ ...props }) => {
  const [news, setNews] = useState([]);
  const [response, setResponse] = useState(Response);

  const { page, loading, setLoading, onPaginate } = usePagination();

  useEffect(() => {
    props
      .laodNews("?page=" + page)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data);
        setNews(res.data.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <div className="container">
      <div className={styles.ProfileBankNews}>
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!loading ? (
              <>
                {news.length > 0 ? (
                  <div className="row">
                    {/* Loop over NewsBox card and appear them based on Array Number */}
                    {news.map((news, index) => {
                      return (
                        <div className="col-md-6" key={index}>
                          <NewsBox
                            key={index}
                            requestPath={"/news-page-details?id=" + news.id}
                            date={date(news.created_at)}
                            imgSrc={news.image}
                            desc={news.title}
                            id={news.id}
                          />
                        </div>
                      );
                    })}
                    <div className="col-12">
                      <Pagination response={response} onPaginate={onPaginate} />
                    </div>
                  </div>
                ) : (
                  <div className="noData">
                    {Tr.tr("basic:there_are_no_data")}
                  </div>
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileBankNews.defaultProps = {};

ProfileBankNews.propTypes = {};

export default connect(null, { laodNews })(
  withLoadMore({
    loadAction: "laodNews",
  })(ProfileBankNews)
);
