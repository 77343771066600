import React, { useEffect, useState } from "react";
import styles from "./RequestTable.module.scss";

import { Link } from "react-router-dom";
import axios from "axios";
import httpDriver from "utils/httpDriver";

import Table from "react-bootstrap/Table";
import { Tr } from "utils/i18n";
import { connect } from "react-redux";
import {
	getWithDrawalApplication,
	applicationDetails,
	acceptOfferInfo,
	acceptOffer,
	cancelRequest,
} from "redux/profile/actions";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { trimWhiteSpace } from "utils/trimWhiteSpace";

import Modal from "components/common/ModalComponent/ModalComponent";

import Loader from "components/Loader";

import { Response } from "utils/Response";

import Pagination from "components/Pagination";

import { withChangeLang } from "components/hocs/index";

import { useAuth } from "utils/useAuthHook";
import { usePagination } from "utils/usePagination";

import { timeFormat } from "utils/dateTimeFormat";

const RequestTable = ({ ...props }) => {
	let lang = localStorage.getItem("i18nextLng");

	const [id, setId] = useState(props.match.params.id);
	const [withDrawal, setWithDrawal] = useState([]);
	const [message, setMessage] = useState("");
	const [request, setrequest] = useState({});
	const [form, setform] = useState({});
	const [acceptOffer, setAcceptOffer] = useState({});
	const [rejected, setRejected] = useState({});
	const [contactBankInfo, setContactBankInfo] = useState({});
	// Loader
	// const [loading, setLoading] = useState(true);
	const [response, setResponse] = useState(Response);

	const { page, loading, setLoading, onPaginate } = usePagination();
	// user type
	let [userType] = useAuth();

	// after accept offer
	const [contactBank, setContactBank] = useState(null);

	// closed
	const [closed, setClosed] = useState(null);

	// Service Id
	const [serviceId, setServiceId] = useState(null);

	// Service Status
	const [serviceStatus, setServiceStatus] = useState("");
	const [buttonStatus, setbuttonStatus] = useState(true);
	const [isAccepted, setIsAccepted] = useState(NaN);
	// sort
	const [order, setorder] = useState("");
	const [type, settype] = useState("asc");
	const onSort = (order, type) => {
		setorder(order);
		settype(type);
	};

	useEffect(() => {
		props
			.applicationDetails(id)
			.then((result) => {
				if (result.data) {
					if (result.data.data) {
						setLoading(false);
						setrequest(result.data.data.request);
						setform(result.data.data.form);
						setServiceId(result.data.data.request.service.id);
						setClosed(result.data.data.request.closed);
						setServiceStatus(result.data.data.request.service.status_key);
					}
				}
			})
			.catch((res) => {
				setLoading(false);
				if (res.error.response.status === 400) {
					setMessage(res.error.response.data.message);
				}
			});

		props
			.getWithDrawalApplication(id, { type, order, page })
			.then((result) => {
				if (result.data) {
					if (result.data.data) {
						setResponse(result.data.data);
						setWithDrawal(result.data.data.data);
						for (let index = 0; index < result.data.data.data.length; index++) {
							const element = result.data.data.data[index];
							if (element.offer_accepted === 1) setIsAccepted(element.id);
						}
					}
				}
			})
			.catch((res) => {
				// console.log(res)
				// if(res.error.response.data.message){
				//   setMessage(res.error.response.data.message);
				// }
			});
	}, [setWithDrawal, setMessage, setId, page, order, type]);

	// Show or Hide state based on show value
	const [show, setShow] = useState(false);

	// Show modal if show value is true [setShow(true)]
	const handleShow = (id) => {
		setShow(true);
		setbuttonStatus(false);
		props.acceptOfferInfo(id).then((result) => {
			setAcceptOffer(result.data.data.form);
		});
	};

	// Hide modal if show value is false [setShow(false)] [Sign Out Modal]
	const handleClose = () => {
		setShow(false);
	};

	// Show or Hide state based on showBankModal value
	const [showBankModal, setShowBankModal] = useState(false);

	// Show modal if show value is true [setShowBankModal(true)]
	const handleShowBankModal = (id) => {
		setShowBankModal(true);
		props.acceptOfferInfo(id).then((result) => {
			setContactBankInfo(result.data.data.maker);
		});
	};

	// Hide modal if show value is false [setShowBankModal(false)] [show bank modal]
	const handleCloseBankModal = () => {
		setShowBankModal(false);
	};

	const [rejectionData, setRejectionData] = useState([]);
	// Show or Hide state based on showCancelModal value
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [cancelModalId, setCancelModalId] = useState(null);
	const [activeBtn, setActiveBtn] = useState([-1]);
	// Show modal if show value is true [setShowCancelModal(true)]
	const handleShowCancelModal = (id) => {
		setShowCancelModal(true);
		setCancelModalId(id);
	};

	// Get Rejection Data
	const getRejectedData = async (id) => {
		const { data } = await httpDriver.get(
			`website-users/reject-reasons/${id}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem("api_token")}`,
				},
			}
		);
		// console.log(data.data);
		setRejectionData(data.data);
	};

	const cancelRequestHandler = (id) => {
		props.cancelRequest(id).then((res) => {
			props.history.push("/profile");
		});
	};

	// Hide modal if show value is false [setShowCancelModal(false)] [show cancel modal]
	const handleCloseCancelModal = () => {
		setShowCancelModal(false);
	};

	// Accept Offer Button
	const acceptOfferHandler = (rowId) => {
		props.acceptOffer(rowId).then((result) => {
			setLoading(false);
			setContactBank(result);
			props
				.getWithDrawalApplication(id, { type, order, page })
				.then((result) => {
					if (result.data) {
						if (result.data.data) {
							setLoading(false);
							setWithDrawal(result.data.data.data);
							setResponse(result.data.data);
							setContactBank(result.data.data.data.status_key);
							setClosed(rowId);
							setIsAccepted(rowId);
						}
					}
				});
		});
	};

	const tableHeader = [
		{ label: Tr.tr("Table:withdrawal_date_time"), key: "created_at" },
		{ label: Tr.tr("Table:bank"), key: "name" },
		// { label: Tr.tr("Table:approved_amount"), key: "" },
		// { label: Tr.tr("Table:loan_tenor"), key: "" },
		{ label: Tr.tr("Table:status"), key: "status" },
	];
	const sortCondetion = (column) => order === column.key && type === "desc";
	return (
		<>
			<div className={styles.RequestInfo}>
				<div className={styles.RequestInfoHead}>
					<div className={`TitleFont ${styles.Title}`}>
						{userType === "personal"
							? Tr.tr("Table:personal_facility")
							: Tr.tr("Table:corporate_services")}
					</div>
					{request.status_key === "new" && (
						<div className={styles.RequestInfoButtons}>
							{serviceStatus !== "DEACTIVE" ? (
								<Link to={"/edit-request/" + id} className={`Button`}>
									{Tr.tr("Table:edit_request")}
								</Link>
							) : (
								<Link
									to={"/edit-request/" + id}
									className={`Button ${styles.disabledServices}`}
									title={Tr.tr("Table:deactive_service")}
									onClick={(e) => e.preventDefault()}
								>
									{Tr.tr("Table:edit_request")}
								</Link>
							)}
							<button
								className={`Button White`}
								onClick={() => handleShowCancelModal(request.id)}
							>
								{Tr.tr("Table:cancel_request")}
							</button>
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-lg-5">
						<div className={styles.Info}>
							<span className={styles.InfoName}>
								{Tr.tr("Table:case_number")} :{" "}
							</span>
							<span className="DescFont">{request.id}</span>
						</div>
					</div>
					<div className="col-lg-7">
						<div className={styles.Info}>
							<span className={styles.InfoName}>
								{Tr.tr("Table:customer_full_name")} :{" "}
							</span>
							<span className="DescFont">
								{userType === "personal" ? form.full_name : form.company_name}
							</span>
						</div>
					</div>
					<div className="col-lg-5">
						<div className={styles.Info}>
							<span className={styles.InfoName}>
								{Tr.tr("Table:service_name")} :{" "}
							</span>
							<span className="DescFont">
								{request.service && request.service.name
									? request.service.name
									: "-"}
							</span>
						</div>
					</div>

					<div className="col-lg-5">
						<div className={styles.Info}>
							<span className={styles.InfoName}>
								{Tr.tr("Table:type_of_facility")} :{" "}
							</span>
							<span className="DescFont">
								{request.type_of_facility || "-"}
							</span>
						</div>
					</div>
					<div className="col-lg-7">
						<div className={styles.Info}>
							<span className={styles.InfoName}>
								{Tr.tr("Table:amount_of_facility")} :{" "}
							</span>
							<span className="DescFont">{request.amount || "-"}</span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.RequestTable}>
				{!loading ? (
					<>
						<Table className={styles.Table}>
							<thead>
								<tr>
									{tableHeader.map((column, index) => {
										return (
											<th key={index}>
												<div
													onClick={
														sortCondetion(column)
															? () => onSort(column.key, "asc")
															: () => onSort(column.key, "desc")
													}
												>
													<span>{column.label}</span>
													{sortCondetion(column) ? (
														<i className={"arrow_up  icon-dropdown "}></i>
													) : (
														<i className={"icon-dropdown"}></i>
													)}
												</div>
											</th>
										);
									})}
									<th></th>
								</tr>
							</thead>
							<tbody>
								{contactBank === "approved" || withDrawal.length > 0 ? (
									withDrawal.map((item, index) => (
										<tr key={index}>
											<td>
												<div>
													{moment(item.created_at)
														.locale("en")
														.format("YYYY-MM-DD")}
												</div>
												<div>{timeFormat(item.created_at)}</div>
											</td>
											<td>{item.bank.name}</td>
											{/* <td>
                        {item.approved_amount ? item.approved_amount : "-"}
                      </td> */}
											{/* <td>
                        {lang === "en"
                          ? moment(item.created_at).locale("en").fromNow(true)
                          : moment(item.created_at).locale("ar").fromNow(true)}
                      </td> */}
											<td>
												{item.status_key !== "approved" ? (
													<div
														className={`${styles.Status} ${trimWhiteSpace(
															item.status_key
														)}`}
													>
														<span className={styles.Dot}></span>
														<span>{item.status}</span>
													</div>
												) : (
													<div
														className={`${styles.Status} ${trimWhiteSpace(
															item.status_key
														)}`}
													>
														<span className={styles.Dot}></span>
														<span>{item.status}</span>
													</div>
												)}
											</td>
											{item.status_key === "rejected" && (
												<td>
													<button
														className={`${styles.ApprovedIcon} Button`}
														onClick={() => {
															setShow(true);
															getRejectedData(item.id);
														}}
														style={{
															textTransform: "capitalize",
														}}
													>
														{Tr.tr("Table:view_reasons")}
													</button>
												</td>
											)}
											<td
												style={{
													width: item.status_key === "approved" && 300,
													display: item.status_key === "approved" && "flex",
													justifyContent:
														item.status_key === "approved" && "space-between",
												}}
											>
												{item.status_key === "approved" && (
													<>
														<button
															className={`${styles.ApprovedIcon} Button`}
															onClick={() => {
																setActiveBtn([...activeBtn, item.id]);
																handleShow(item.id);
															}}
															style={{
																width: "49%",
																textTransform: "capitalize",
															}}
															disabled={
																isNaN(isAccepted)
																	? false
																	: item.id === isAccepted
																	? false
																	: true
															}
														>
															{Tr.tr("Table:view_offer")}
														</button>
														{item.offer_accepted !== 1 && closed !== item.id ? (
															<button
																className={`Button`}
																onClick={() => {
																	acceptOfferHandler(item.id);
																	setActiveBtn([item.id]);
																}}
																style={{
																	width: "49%",
																	textTransform: "capitalize",
																}}
																disabled={
																	!activeBtn.includes(item.id) &&
																	closed !== item.id
																}
															>
																{Tr.tr("Table:accept_offer")}
															</button>
														) : (
															<button
																className={`Button`}
																onClick={() => handleShowBankModal(item.id)}
																style={{
																	width: "49%",
																	textTransform: "capitalize",
																}}
															>
																{Tr.tr("Table:contact_bank")}
															</button>
														)}
													</>
												)}
											</td>
										</tr>
									))
								) : (
									<>
										{(request.status_key === "rejected" && (
											<tr>
												<td colSpan="5" className={styles.Unfortunately}>
													<div className={styles.NoBankWithdrawal}>
														<h1 className={`TitleFont`}>
															{Tr.tr("Table:unfortunately")}
														</h1>
														{request.service.is_applied !== 0 ? (
															<p
																className={`DescFont ${styles.ResubmittedService}`}
															>
																{Tr.tr("Profile:RESUBMITTED_SERVICE")}
															</p>
														) : (
															<p className={`DescFont`}>
																{Tr.tr("Table:resubmit_your_request")}
															</p>
														)}
														{request.service.is_applied !== 0 ? (
															""
														) : (
															<Link
																to={`/profile-services/${serviceId}`}
																className={`Button DescFont ${styles.Resubmit}`}
															>
																{Tr.tr("Table:resubmit")}
															</Link>
														)}
													</div>
												</td>
											</tr>
										)) || (
											<tr>
												<td colSpan="5" className={styles.NoAvailableData}>
													{Tr.tr("basic:no_available_data")}
												</td>
											</tr>
										)}
									</>
								)}
							</tbody>
						</Table>
						<Pagination response={response} onPaginate={onPaginate} />
					</>
				) : (
					<Loader loading={loading} />
				)}
				{/* Accept Offer */}
				<Modal show={show} close={handleClose}>
					{acceptOffer && rejectionData.length === 0 && (
						<span className="TitleFont SignOutModal">
							<Tr tr="Table:bank_offer" />
						</span>
					)}
					{rejectionData.length != 0 && (
						<span className="TitleFont SignOutModal">
							<Tr tr="Table:rejection_reasons" />
						</span>
					)}
					<div
						className={styles.AcceptOfferContent}
						style={{
							marginTop: 20,
						}}
					>
						<table>
							<tbody>
								{acceptOffer &&
									acceptOffer.length > 0 &&
									acceptOffer.map((offer, index) => (
										<tr key={index}>
											<td>{offer.label}:</td>
											<td>{offer.value}</td>
										</tr>
									))}
								{rejectionData &&
									rejectionData.map(({ reason }, index) => {
										if (index + 1 === rejectionData.length) {
											return (
												<>
													<tr key={index}>
														<td>
															<span
																className="TitleFont SignOutModal"
																style={{
																	fontSize: 18,
																}}
															>
																<Tr tr="Table:rejection_comment" />
															</span>
														</td>
													</tr>
													<tr key={index}>
														<td>{reason}</td>
													</tr>
												</>
											);
										} else {
											return (
												<tr key={index}>
													<td>
														{index + 1}. {reason}
													</td>
												</tr>
											);
										}
									})}
							</tbody>
						</table>
					</div>
				</Modal>
				{/* Contact Bank Buuton Modal */}
				<Modal show={showBankModal} close={handleCloseBankModal}>
					<span className="TitleFont SignOutModal">
						<Tr tr="Table:contact_bank" />
					</span>
					<div className={styles.AcceptBankContent}>
						<div className={styles.top}>
							<div className={styles.BankInfo}>
								<div className={styles.NameTitle}>
									{Tr.tr("Table:bank_name")}:
								</div>
								<div className={styles.NameValue}>
									{contactBankInfo && contactBankInfo.bank_name}
								</div>
							</div>
							<div className={styles.BankInfo}>
								<div className={styles.NameTitle}>
									{Tr.tr("Table:maker_name")}:
								</div>
								<div className={styles.NameValue}>
									{contactBankInfo && contactBankInfo.name}
								</div>
							</div>
						</div>
						<div className={styles.bottom}>
							<div className={styles.BankInfo}>
								<div className={styles.NameTitle}>
									{Tr.tr("Table:maker_mobile_number")}:
								</div>
								<div className={styles.NameValue}>
									{contactBankInfo && contactBankInfo.phone}
								</div>
							</div>
						</div>
					</div>
				</Modal>
				{/* Cancel Request Modal */}
				<Modal show={showCancelModal} close={handleCloseCancelModal}>
					<span className="TitleFont SignOutModal">
						<Tr tr="Forms:cancel_request_question" />
					</span>
					<button
						className="Button White ModalLeftButton"
						onClick={handleCloseCancelModal}
					>
						{Tr.tr("basic:cancel")}
					</button>
					<button
						className="Button ModalRightButton"
						onClick={() => cancelRequestHandler(cancelModalId)}
					>
						{Tr.tr("Forms:confirm_cancel")}
					</button>
				</Modal>
			</div>
		</>
	);
};

RequestTable.defaultProps = {};

RequestTable.propTypes = {};

export default connect(null, {
	getWithDrawalApplication,
	applicationDetails,
	acceptOfferInfo,
	acceptOffer,
	cancelRequest,
})(withRouter(withChangeLang()(RequestTable)));
