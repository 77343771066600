import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOffersSearchResults } from "redux/main/actions";
import Offers from "components/common/Offers/Offers";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
import styles from "../Search.module.scss";
import ResultCount from "./ResultCount";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { Response } from "utils/Response";
import { usePagination } from "utils/usePagination";

function OffersResults({ search, ...props }) {
  const [offers, setoffers] = useState(null);
  const [count, setcount] = useState(0);
  const [response, setResponse] = useState(Response);
  const { page, loading, setLoading, onPaginate } = usePagination();

  useEffect(() => {
    props
      .getOffersSearchResults(search, page)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data.offers);
        setoffers(res.data.data.offers.data);
        setcount(res.data.data.count);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [search, page]);

  if (loading) {
    return <Loader loading={loading} />;
  }
  return (
    <div className="container">
      {/* RESULTS FOUND BAR */}
      <ResultCount {...{ count }} />

      <div className={styles.SectionPadding}>
        <div className={styles.Tit}>
          <p className={`TitleFont ${styles.TitleFont}`}>
            <Tr tr="Search:offers" />
          </p>
          <Link
            className={`DescFont ${styles.SeeAll}`}
            to="/profile-bank-offers"
          >
            <Tr tr="Search:see_all" />
          </Link>
        </div>
        <>
          {offers && offers.length ? (
            <div className="row">
              {offers.map((offer, index) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={index}>
                    <Offers
                      key={index}
                      imgSrc={offer.bank.logo}
                      name={offer.title}
                      // number="100.000"
                      // percentage="1%"
                      title={offer.title}
                      desc={offer.description}
                      requestPath={`/profile-bank-details?id=${offer.id}`}
                    />
                  </div>
                );
              })}
              <div className="col-12">
                <Pagination response={response} onPaginate={onPaginate} />
              </div>
            </div>
          ) : (
            <div className={styles.NoResultsFound}>
              {Tr.tr("Search:no_results")}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default connect(null, { getOffersSearchResults })(OffersResults);
