import React, { useState } from "react";
import styles from "./CreditCard.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
import { UsersOnlyAccess } from "routes/Middleware/AccessControls";
import history from "utils/history";

import Modal from "components/common/ModalComponent/ModalComponent";

const CreditCard = ({
  imgSrc, //image of the box
  detailsPath, //the link that the title will redirects to (box-details)
  title, //the title of the box (next to the image)
  desc, //the description of the box
  requestPath, //the link that the box will redirects to (REQUEST-SERVICE)
  isApplied, // check the service is applied before or not [0] not applied [1] applied
  userServiceId, //the user service id when click on view request in applied service modal to view the request
  ...props
}) => {
  // Show or Hide state based on show value
  const [show, setShow] = useState(false);

  // Show modal if show value is true [setShow(true)]
  const handleShow = () => {
    setShow(true);
  };

  // Hide modal if show value is false [setShow(false)] [Sign Out Modal]
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={styles.Wrapper}>
      <div className={styles.CreditCard}>
        <div className={styles.Tit}>
          <img src={imgSrc} alt="service" />
          <Link to={detailsPath} className="SubTitle">
            {title}
          </Link>
        </div>
        <p className={`DescFont ${styles.Description}`}>{desc}</p>
        <UsersOnlyAccess>
          <button
            className={`Button White DescFont ${styles.Button}`}
            onClick={
              isApplied
                ? handleShow
                : () => {
                    history.push(requestPath);
                  }
            }
            // disabled={isApplied}
            title={isApplied ? Tr.tr("Profile:APPLIED_SERVICE") : ""}
          >
            <Tr tr="basic:Request_service" />
          </button>
        </UsersOnlyAccess>
      </div>
      <Modal show={show} close={handleClose}>
        <span className="TitleFont SignOutModal">
          <Tr tr="Profile:APPLIED_SERVICE" />
        </span>
        <button className="Button White ModalLeftButton" onClick={handleClose}>
          {Tr.tr("basic:cancel")}
        </button>
        <Link
          to={`/personal-request-details/${userServiceId}`}
          className="Button ModalRightButton"
        >
          {Tr.tr("Profile:VIEW_THE_REQUEST")}
        </Link>
      </Modal>
    </div>
  );
};

export default CreditCard;
