import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "./_Field";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "formik";
import ar from "date-fns/locale/ar-SA";
const currLang = localStorage.getItem("i18nextLng");

function DateCustomFieldComponent({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  hint,
  required,
  ...props
}) {
  const [startDate, setStartDate] = useState(new Date());
  const handleChange = (date) => {
    setStartDate(date);
  };
  return (
    <div className="InputGroup">
      <label htmlFor="" className={`Label ${required ? "Required" : ""}`}>
        {label}
      </label>
      <div className="DatePicker">
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          dateFormat="dd/MM/yyyy"
          className="Input"
          name={field.name}
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={121}
          id={field.name}
          locale={currLang === "ar" && ar}
          {...field}
          {...props}
        />
        <i className="icon-calendar"></i>
      </div>
      <div className="Hint">{hint}</div>
      <div className="err">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
}

function DateField({ ...props }) {
  return <Field {...props} component={DateCustomFieldComponent} />;
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
};

export { DateField };
