import React from "react";
import styles from "./NoSubmition.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";

const NoSubmition = ({ ...props }) => {
  return (
    <div className={styles.NoSubmition}>
      <div className={`TitleFont ${styles.NoSubmitionTitle}`}>
        <Tr tr="Profile:not_submit_any_facility" />
      </div>
      <Link
        to="/profile-submit-facility"
        className={`Button ${styles.ApplyBtn}`}
      >
        <Tr tr="Profile:apply_for_personal_facility" />
      </Link>
    </div>
  );
};

export default NoSubmition;
