import React from "react";
import styles from "./Offers.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
const Offers = ({
  imgSrc, //the logo of the box date
  name, //the  name of the company that offers the offer
  number, //the value that of the offer
  date, //date of the box
  percentage, //the percentage of the offer
  desc, // the description of the offer
  requestPath, //the link that the offer will be redirected to
  title, // the title og the offer
  ...props
}) => {
  // console.log("requestPath", requestPath);
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Offers}>
        <div className={styles.OffersContent}>
          <div className={styles.Img}>
            <img src={imgSrc} alt="offer" />
          </div>
          {/* <p className={`OtherBl ${styles.Desc}`}>
            {date && <span className={`Other ${styles.Date}`}>{date}</span>}
            <span>{name}</span> IS OFFERING
            <span className="Orange">{number} </span>
            LOAN FOR <span className="Orange">{percentage}</span> INTEREST RATE
          </p> */}
          <p className={`OtherBl ${styles.Desc}`}>
            {date && <span className={`Other ${styles.Date}`}>{date}</span>}
            <span>{title} </span>
          </p>
        </div>
        <Link to={requestPath} className={`Button White ${styles.Button}`}>
          <Tr tr="basic:explore" />
        </Link>
      </div>
    </div>
  );
};

export default Offers;
