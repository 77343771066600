import { QUICK_FORM_CHANGED, LOAD_HOME_DATA, RESET_QUICK_FORM } from "./types";
import { success } from "redux-saga-requests";

const INITIAL_STATE = {
  quickForm: {
    type_of_facility: "",
    amount: null,
    monthly_income: null,
  },
  data: {
    personal_type_of_facility: null,
    corporate_type_of_facility: null,
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUICK_FORM_CHANGED:
      return {
        ...state,
        quickForm: {
          ...state.quickForm,
          [action.payload.prop]: action.payload.value,
        },
      };
    case success(LOAD_HOME_DATA):
      return { ...state, data: action.data.data };
    case RESET_QUICK_FORM: {
      return {
        ...state,
        quickForm: {
          type_of_facility: "",
          amount: null,
          monthly_income: null,
        },
      };
    }
    default:
      return state;
  }
};
