// ESSENTIAL
import React, { useEffect, useState } from "react";
import styles from "../ProviderWithdrawalDetails/ProviderWithdrawalDetails.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import history from "utils/history";

// COMPONENTS
import SideBar from "components/SideBar";
import { Tr } from "utils/i18n";
import Loader from "components/Loader";
import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";
import ApplicationTd from "../../components/AplicationTd";

//ACTIONS
import {
  makerApplicationDetails,
  loadMakerApprovalComments,
  loadRejectReasonsById,
  loadMakerApprovedData,
} from "redux/profile/actions";

const RejectRequestsDetails = ({ ...props }) => {
  // appear the form of two tabs accept and reject based on choice value and if the choice value is null not forms appear
  const [choice, setView] = useState(null);

  const [id, setId] = useState(props.match.params.id);
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([]);
  const [comments, setComments] = useState([]);
  const [reasons, setreasons] = useState(null);
  const [status, setStatus] = useState({});
  const [makerApprovedData, setmakerApprovedData] = useState(null);
  const [downloadLinks, setDownloadLinks] = useState({});
  const [dateTimeSubmission, setDateTimeSubmission] = useState("");
  const [loading, setLoading] = useState(true);

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  const isRejectPage = history.location.pathname.includes(
    "rejected-request-details"
  );

  const loadRejectReasons = () => {
    isRejectPage &&
      props.loadRejectReasonsById(id).then((res) => setreasons(res.data.data));
  };

  const loadMakerApprovedData = () => {
    props.loadMakerApprovedData(id).then((res) => {
      setmakerApprovedData(res.data.data.form);
      setLoading(false);
    });
  };

  const loadComments = () => {
    props.loadMakerApprovalComments(id).then((res) => {
      setComments(res.data.data.comments);
      setStatus(res.data.data.withdrawal);
    });
  };

  useEffect(() => {
    props
      .makerApplicationDetails(id)
      .then((result) => {
        if (result.data) {
          setLoading(false);

          setRows([
            ...result.data.data.form,
            {
              label: Tr.tr("Table:lower_service_name"),
              value: result.data.data.withdrawal.service.name,
            },
            {
              label: Tr.tr("Table:service_type"),
              value: result.data.data.withdrawal.service.type,
            },
          ]);
          setDownloadLinks(result.data.data.download_links);
          setDateTimeSubmission(result.data.data.created_at);
          if (result.data.data.withdrawal.status_key === "approved")
            loadMakerApprovedData();
        }
      })
      .catch((res) => {
        if (res.error) {
          if (res.error.response.data.message) {
            setLoading(false);
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });

    // Load Comments
    loadComments();

    // load reasons
    loadRejectReasons();
  }, []);

  return (
    <div className="AddPadding">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!loading ? (
              <>
                {!message ? (
                  <>
                    <div className={styles.Tit}>
                      <p className="TitleFont">
                        <Tr tr="Profile:case_id" />
                      </p>
                      {Object.keys(downloadLinks).length > 0 && (
                        <div className={styles.DownloadLinks}>
                          {downloadLinks.document.length > 0 && (
                            <a
                              href={downloadLinks.document}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={`DescFont ${styles.Download}`}
                            >
                              <i
                                className={`icon-next ${styles.DownloadIcon}`}
                              ></i>
                              <Tr tr="Profile:download_doc" />
                            </a>
                          )}
                          {downloadLinks.hr_letter.length > 0 && (
                            <a
                              href={downloadLinks.hr_letter}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={`DescFont ${styles.Download}`}
                            >
                              <i
                                className={`icon-next ${styles.DownloadIcon}`}
                              ></i>
                              <Tr tr="serviceProvider:download_hr_letter" />
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                    <span className={`DescFont ${styles.CaseId}`}>{status.case_id}</span>
                    <div className={styles.TableWrapper}>
                      <div className="row d-flex justify-content-lg-between">
                        <div className="col-lg-8">
                          {!loading ? (
                            <table>
                              <tbody>
                                <ApplicationTd
                                  rows={rows}
                                  dateTimeSubmission={dateTimeSubmission}
                                />
                                <tr>
                                  <td>{Tr.tr("basic:maker_status")}</td>
                                  <td>{status && status.status}</td>
                                </tr>
                                {comments &&
                                  comments.length > 0 &&
                                  comments.map((comment) => (
                                    <tr key={comment.id}>
                                      {comment.user.type === "maker" ? (
                                        <>
                                          <td>
                                            {comment.user &&
                                              comment.user.first_name +
                                                " " +
                                                comment.user.middle_name +
                                                " " +
                                                comment.user.last_name}{" "}
                                            - {Tr.tr("basic:maker")}
                                          </td>
                                          <td>
                                            <div className={styles.CommentDate}>
                                              <div>{comment.reason}</div>
                                              <div>
                                                {moment(comments.created_at)
                                                  .locale("en")
                                                  .format("L")}
                                              </div>
                                            </div>
                                          </td>
                                        </>
                                      ) : comment.user.type === "approval" ? (
                                        <>
                                          <td>
                                            {comment.user &&
                                              comment.user.first_name +
                                                " " +
                                                comment.user.middle_name +
                                                " " +
                                                comment.user.last_name}{" "}
                                            - {Tr.tr("basic:approval")}
                                          </td>
                                          <td>
                                            <div className={styles.CommentDate}>
                                              <div>{comment.reason}</div>
                                              <div>
                                                {moment(comments.created_at)
                                                  .locale("en")
                                                  .format("L")}
                                              </div>
                                            </div>
                                          </td>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  ))}
                                {/* reject reasons */}
                                {isRejectPage ? (
                                  <tr>
                                    <td>{Tr.tr("Forms:reason")}</td>
                                    <td>
                                      {reasons &&
                                        reasons
                                          .map((reason) => {
                                            return reason.reason;
                                          })
                                          .join(", ")}
                                    </td>
                                  </tr>
                                ) : null}
                                {/* maker approval data */}
                                {makerApprovedData &&
                                  makerApprovedData.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.label}</td>
                                      <td>{item.value}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          ) : (
                            <Loader loading={loading} />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <BadRequestMessage
                    message={message}
                    redirectPage="/provider-rejected"
                  />
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  makerApplicationDetails,
  loadMakerApprovalComments,
  loadRejectReasonsById,
  loadMakerApprovedData,
})(withRouter(RejectRequestsDetails));
