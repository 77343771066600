import * as T from "./types";

export const loadHomeData = () => ({
  type: T.LOAD_HOME_DATA,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}pages/home`,
    method: "get",
  },
});

export const changeQuickForm = (prop, value) => ({
  type: T.QUICK_FORM_CHANGED,
  payload: { prop, value },
});

export const resetQuickForm = () => ({
  type: T.RESET_QUICK_FORM,
});
