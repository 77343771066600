export const en = {
	terms_conditions: "Terms and Conditions",
	desc: 'Please read the terms and conditions of use set out below carefully before using this Web Site (the "Site"). The use of this Site and any transactions conducted on it are governed by these terms and conditions ("Terms") and the Privacy and Security Statement. If you have any questions regarding these Terms, please contact us every day from 9 am to 7 pm, email us via "Contact ElMomawel" icon on the Home page, or visit us at our main office. References to "you", "your" and "yours" are references to the person(s) accessing the Site. References to "we", "us" and "our" are references to "ElMomawel Co.".',
	terms_conditions_content_title: "WEBSITE TERMS AND CONDITIONS",
	terms_conditions_content_p:
		"These terms tell you the rules for using our website www.elmomawel.com (“our site”). Please read these terms of use carefully before you start to use the site.",
	terms_conditions_content_title2: "ABOUT US",
	terms_conditions_content_p2:
		'www.elmomawel.com is a site operated by ElMomawel for financial consultancy and marketing Limited Liability Co LLC. ("We"). We are registered in Egypt under company registry number 138723 and have our registered office at the address mentioned in the website. To contact us, please email info@elmomawel.com.',
	terms_conditions_content_title3: "BY USING OUR SITE YOU ACCEPT THESE TERMS",
	terms_conditions_content_p3:
		"By using our site, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our site. We recommend that you print a copy of these terms for future reference.",
	terms_conditions_content_title4: "OTHER APPLICABLE TERMS",
	terms_conditions_content_p4:
		"These terms of use refer to the following additional terms, which also apply to your use of our site:",
	terms_conditions_content_p4_sub:
		"• Our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing and you warrant that all data provided by you is accurate; and",
	terms_conditions_content_title5: "WE MAY MAKE CHANGES TO THESE TERMS",
	terms_conditions_content_p5:
		"We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time. These terms were most recently updated on 2019.",
	terms_conditions_content_title6: "WE MAY MAKE CHANGES TO OUR SITE",
	terms_conditions_content_p6:
		"We may update and change our site from time to time to reflect changes to our products and services, our users' needs and our business priorities.",
	terms_conditions_content_title7: "WE MAY SUSPEND OR WITHDRAW OUR SITE",
	terms_conditions_content_p7:
		"We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will not be liable to you if for any reason our site is unavailable at any time or for any period although will try to give you reasonable notice of any suspension or withdrawal. You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.",
	terms_conditions_content_title8: "YOUR ACCOUNT DETAILS",
	terms_conditions_content_p8:
		"If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use. If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at info@elmomawel.com",
	terms_conditions_content_title9: "HOW YOU MAY USE MATERIAL ON OUR SITE",
	terms_conditions_content_p9:
		"We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organization to content posted on our site. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged. You must not use any part of the content on our site for commercial purposes without obtaining a license to do so from us or our licensors. If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.",
	terms_conditions_content_title10: "DO NOT RELY ON INFORMATION ON THIS SITE",
	terms_conditions_content_p10:
		"The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site. Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied that the content on our site is accurate, complete or up to date.",
	terms_conditions_content_title11:
		"USER-GENERATED CONTENT IS NOT APPROVED BY US",
	terms_conditions_content_p11:
		"This website may include information and materials uploaded by other users of the site, including location listings. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values. We make no representations, warranties or guarantees, whether express or implied, that the locating listings and property details on our site are accurate, complete or up to date.",
	terms_conditions_content_title12:
		"WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO",
	terms_conditions_content_p12:
		"Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources.",
	terms_conditions_content_title13: "OUR LIABILITY",
	terms_conditions_content_title13_sub:
		"If you are a consumer or a business user:",
	terms_conditions_content_p13:
		"• We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.",
	terms_conditions_content_title14: "If you are a business user:",
	terms_conditions_content_p14:
		"• We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it;",
	terms_conditions_content_p14_sub:
		"• We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:",
	terms_conditions_content_p14_sub2:
		"o Use of, or inability to use, our site; or",
	terms_conditions_content_p14_sub3:
		"o Use of or reliance on any content displayed on our site;",
	terms_conditions_content_title15: "• We will not be liable for:",
	terms_conditions_content_p15:
		"o Loss of profits, sales, business, or revenue;",
	terms_conditions_content_p15_sub: "o Business interruption;",
	terms_conditions_content_p15_sub2: "o Non-performing loans or settlements;",
	terms_conditions_content_p15_sub3: "o Loss of anticipated savings;",
	terms_conditions_content_p15_sub4:
		"o Loss of business opportunity, goodwill or reputation; or",
	terms_conditions_content_p15_sub5:
		"o Any indirect or consequential loss or damage.",
	terms_conditions_content_p15_sub6: "If you are a consumer user:",
	terms_conditions_content_p15_sub7:
		"• You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.",
	terms_conditions_content_title16: "VIRUSES",
	terms_conditions_content_p16:
		"We are not responsible for viruses and you must not introduce them. We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programs and platform to access our site. You should use your own virus protection software. You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.",
	terms_conditions_content_title17: "LINKING TO OUR SITE",
	terms_conditions_content_p17:
		"You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our site in any website that is not owned by you. Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page. We reserve the right to withdraw linking permission without notice. If you wish to link to or make any use of content on our site other than that set out above, please contact info@elmomawel.com.",
	terms_conditions_content_title18: "APPLICABLE LAW",
	terms_conditions_content_p18:
		"Please note that these terms of use, their subject matter and their formation (and any non-contractual disputes or claims), are governed by the Egyptian law. You and we both agree that the courts of Egypt will have exclusive jurisdiction.",
};

export const ar = {
	terms_conditions: "الشروط و الأحكام",
	desc: 'يرجى قراءة شروط وأحكام الاستخدام الموضحة أدناه بعناية قبل استخدام موقع الويب هذا ("الموقع"). يخضع استخدام هذا الموقع وأية معاملات يتم إجراؤها عليه لهذه الشروط والأحكام ("الشروط") وبيان الخصوصية والأمان. إذا كان لديك أي أسئلة بخصوص هذه الشروط ، يرجى الاتصال بنا كل يوم من الساعة 9 صباحًا حتى 7 مساءً ، أو مراسلتنا عبر البريد الإلكتروني عبر أيقونة "اتصل بالممول" في الصفحة الرئيسية ، أو قم بزيارتنا في مكتبنا الرئيسي. الإشارات إلى "أنت" و "لك" و "لك" هي إشارات إلى الشخص (الأشخاص) الذي يقوم بالوصول إلى الموقع. الإشارات إلى "نحن" و "لنا" و "لنا" هي إشارات إلى "شركة الممول".',
	terms_conditions_content_title: "أحكام وشروط الموقع",
	terms_conditions_content_p:
		'تخبرك هذه الشروط بقواعد استخدام موقعنا www.elmomawel.com ("موقعنا"). يرجى قراءة شروط الاستخدام هذه بعناية قبل البدء في استخدام الموقع.',
	terms_conditions_content_title2: "من نحن",
	terms_conditions_content_p2:
		'www.elmomawel.com هو موقع تديره شركة الممول للاستشارات المالية والتسويق ذات المسؤولية المحدودة. ("نحن"). نحن مسجلون في مصر تحت رقم سجل الشركة 138723 ولدينا مكتبنا المسجل على العنوان المذكور في الموقع. للتواصل معنا ، يرجى إرسال بريد إلكتروني إلى info@elmomawel.com.',
	terms_conditions_content_title3:
		"باستخدام موقعنا ، فإنك توافق على هذه الشروط",
	terms_conditions_content_p3:
		"باستخدام موقعنا ، فإنك تؤكد أنك تقبل شروط الاستخدام هذه وأنك توافق على الامتثال لها. إذا كنت لا توافق على هذه الشروط ، فلا يجوز لك استخدام موقعنا. نوصي بطباعة نسخة من هذه الشروط للرجوع إليها في المستقبل.",
	terms_conditions_content_title4: "الشروط الأخرى المعمول بها",
	terms_conditions_content_p4:
		"تشير شروط الاستخدام هذه إلى الشروط الإضافية التالية ، والتي تنطبق أيضًا على استخدامك لموقعنا:",
	terms_conditions_content_p4_sub:
		"• سياسة الخصوصية الخاصة بنا ، والتي تحدد الشروط التي بموجبها نعالج أي بيانات شخصية نجمعها منك ، أو التي تقدمها لنا. باستخدام موقعنا ، فإنك توافق على هذه المعالجة وتتعهد بأن جميع البيانات التي تقدمها دقيقة ؛ و",
	terms_conditions_content_title5: "قد نجري تغييرات على هذه الشروط",
	terms_conditions_content_p5:
		"نقوم بتعديل هذه الشروط من وقت لآخر. في كل مرة ترغب في استخدام موقعنا ، يرجى مراجعة هذه الشروط للتأكد من فهمك للشروط التي تنطبق في ذلك الوقت. تم تحديث هذه الشروط مؤخرًا في عام 2019.",
	terms_conditions_content_title6: "قد نجري تغييرات على موقعنا",
	terms_conditions_content_p6:
		"قد نقوم بتحديث وتغيير موقعنا من وقت لآخر لتعكس التغييرات التي تطرأ على منتجاتنا وخدماتنا واحتياجات مستخدمينا وأولويات أعمالنا.",
	terms_conditions_content_title7: "يجوز لنا تعليق موقعنا أو سحبه",
	terms_conditions_content_p7:
		"نحن لا نضمن أن يكون موقعنا ، أو أي محتوى عليه ، متاحًا دائمًا أو غير متقطع. يجوز لنا تعليق أو سحب أو تقييد توافر كل أو أي جزء من موقعنا لأسباب تجارية وتشغيلية. لن نكون مسؤولين تجاهك إذا كان موقعنا غير متاح لأي سبب من الأسباب في أي وقت أو لأي فترة على الرغم من أننا سنحاول إعطائك إشعارًا معقولاً بأي تعليق أو سحب. أنت مسؤول أيضًا عن ضمان أن جميع الأشخاص الذين يدخلون إلى موقعنا من خلال اتصالك بالإنترنت على دراية بشروط الاستخدام هذه وغيرها من الشروط والأحكام السارية ، وأنهم يمتثلون لها.",
	terms_conditions_content_title8: "تفاصيل حسابك",
	terms_conditions_content_p8:
		"إذا اخترت أو تم تزويدك برمز تعريف المستخدم أو كلمة المرور أو أي جزء آخر من المعلومات كجزء من إجراءاتنا الأمنية ، فيجب عليك التعامل مع هذه المعلومات على أنها سرية. يجب ألا تفصح عنها لأي طرف ثالث. لدينا الحق في تعطيل أي رمز تعريف أو كلمة مرور للمستخدم ، سواء اخترتها أنت أو خصصناها ، في أي وقت ، إذا كان في رأينا المعقول أنك فشلت في الامتثال لأي من أحكام شروط الاستخدام هذه. إذا كنت تعلم أو تشك في أن أي شخص آخر غيرك يعرف رمز تعريف المستخدم أو كلمة المرور الخاصة بك ، فيجب عليك إخطارنا على الفور على info@elmomawel.com",
	terms_conditions_content_title9: "كيف يمكنك استخدام المواد على موقعنا",
	terms_conditions_content_p9:
		"نحن المالك أو المرخص له لجميع حقوق الملكية الفكرية في موقعنا ، وفي المواد المنشورة عليه. هذه الأعمال محمية بقوانين ومعاهدات حقوق النشر حول العالم. محجوزة عن هذه الحقوق. يمكنك طباعة نسخة واحدة وتنزيل مقتطفات من أي صفحة (صفحات) من موقعنا لاستخدامك الشخصي ويمكنك لفت انتباه الآخرين داخل مؤسستك إلى المحتوى المنشور على موقعنا. لا يجوز لك تعديل النسخ الورقية أو الرقمية لأي مواد قمت بطباعتها أو تنزيلها بأي شكل من الأشكال ، ولا يجوز لك استخدام أي رسوم توضيحية أو صور فوتوغرافية أو مقاطع فيديو أو تسلسلات صوتية أو أي رسومات منفصلة عن أي نص مرفق. يجب دائمًا الاعتراف بوضعنا (وحالة أي مساهمين محددين) كمؤلفين للمحتوى على موقعنا. يجب ألا تستخدم أي جزء من المحتوى على موقعنا لأغراض تجارية دون الحصول على ترخيص للقيام بذلك منا أو من المرخصين لدينا. إذا قمت بطباعة أو نسخ أو تنزيل أي جزء من موقعنا في انتهاك لشروط الاستخدام هذه ، فسيتوقف حقك في استخدام موقعنا على الفور ويجب عليك ، حسب خيارنا ، إعادة أو إتلاف أي نسخ من المواد التي قمت بإنشائها.",
	terms_conditions_content_title10:
		"لا تعتمد على المعلومات الواردة في هذا الموقع",
	terms_conditions_content_p10:
		"يتم توفير المحتوى الموجود على موقعنا للحصول على معلومات عامة فقط. لا يُقصد بها أن ترقى إلى مستوى النصيحة التي يجب أن تعتمد عليها. يجب عليك الحصول على مشورة مهنية أو متخصصة قبل اتخاذ أي إجراء أو الامتناع عنه على أساس المحتوى الموجود على موقعنا. على الرغم من أننا نبذل جهودًا معقولة لتحديث المعلومات الموجودة على موقعنا ، فإننا لا نقدم أي إقرارات أو ضمانات أو ضمانات ، سواء كانت صريحة أو ضمنية ، بأن المحتوى الموجود على موقعنا دقيق أو كامل أو محدث.",
	terms_conditions_content_title11:
		"المحتوى الذي ينشئه المستخدم لا توافق عليه الولايات المتحدة",
	terms_conditions_content_p11:
		"قد يتضمن هذا الموقع معلومات ومواد تم تحميلها من قبل مستخدمين آخرين للموقع ، بما في ذلك قوائم الموقع. لم يتم التحقق من هذه المعلومات وهذه المواد أو الموافقة عليها من قبلنا. الآراء التي عبر عنها المستخدمون الآخرون على موقعنا لا تمثل وجهات نظرنا أو قيمنا. نحن لا نقدم أي تعهدات أو ضمانات أو ضمانات ، سواء كانت صريحة أو ضمنية ، بأن قوائم تحديد الموقع وتفاصيل الممتلكات على موقعنا دقيقة أو كاملة أو محدثة.",
	terms_conditions_content_title12:
		"نحن لسنا مسؤولين عن المواقع الإلكترونية التي نرتبط بها",
	terms_conditions_content_p12:
		"عندما يحتوي موقعنا على روابط لمواقع وموارد أخرى مقدمة من أطراف ثالثة ، يتم توفير هذه الروابط لمعلوماتك فقط. لا ينبغي تفسير هذه الروابط على أنها موافقة منا على تلك المواقع المرتبطة أو المعلومات التي قد تحصل عليها منها. ليس لدينا سيطرة على محتويات تلك المواقع أو الموارد.",
	terms_conditions_content_title13: "مسؤوليتنا",
	terms_conditions_content_title13_sub: "إذا كنت مستهلكًا أو مستخدمًا تجاريًا:",
	terms_conditions_content_p13:
		"• نحن لا نستبعد أو نحد بأي شكل من الأشكال مسؤوليتنا تجاهك حيث يكون القيام بذلك غير قانوني. يشمل ذلك المسؤولية عن الوفاة أو الإصابة الشخصية الناجمة عن إهمالنا أو إهمال موظفينا أو وكلائنا أو مقاولينا من الباطن وعن الاحتيال أو التحريف الاحتيالي.",
	terms_conditions_content_title14: "إذا كنت من مستخدمي الأعمال:",
	terms_conditions_content_p14:
		"• نستثني جميع الشروط أو الضمانات أو الإقرارات الضمنية أو غيرها من الشروط التي قد تنطبق على موقعنا أو أي محتوى عليه ؛",
	terms_conditions_content_p14_sub:
		"• لن نكون مسؤولين تجاهك عن أي خسارة أو ضرر ، سواء في العقد ، أو الضرر (بما في ذلك الإهمال) ، أو خرق الواجب القانوني ، أو غير ذلك ، حتى لو كان متوقعًا ، ينشأ بموجب أو فيما يتعلق بما يلي:",
	terms_conditions_content_p14_sub2:
		"o استخدام أو عدم القدرة على استخدام موقعنا ؛ أو",
	terms_conditions_content_p14_sub3:
		"o استخدام أو الاعتماد على أي محتوى معروض على موقعنا ؛",
	terms_conditions_content_title15: "• لن نتحمل المسؤولية عن:",
	terms_conditions_content_p15:
		"o خسارة الأرباح أو المبيعات أو الأعمال أو الإيرادات ؛",
	terms_conditions_content_p15_sub: "o تعطل الأعمال.",
	terms_conditions_content_p15_sub2: "o القروض أو التسويات المتعثرة.",
	terms_conditions_content_p15_sub3: "o خسارة المدخرات المتوقعة ؛",
	terms_conditions_content_p15_sub4:
		"o فقدان فرص العمل أو الشهرة أو السمعة ؛ أو",
	terms_conditions_content_p15_sub5: "o أي خسارة أو ضرر غير مباشر أو لاحق.",
	terms_conditions_content_p15_sub6: "إذا كنت مستخدمًا مستهلكًا:",
	terms_conditions_content_p15_sub7:
		"• أنت توافق على عدم استخدام موقعنا لأي أغراض تجارية أو تجارية ، ولا نتحمل أي مسؤولية تجاهك عن أي خسارة في الربح أو خسارة في العمل أو توقف العمل أو خسارة فرصة عمل.",
	terms_conditions_content_title16: "الفيروسات",
	terms_conditions_content_p16:
		"نحن لسنا مسؤولين عن الفيروسات ولا يجب عليك إدخالها. نحن لا نضمن أن يكون موقعنا آمنًا أو خاليًا من الأخطاء أو الفيروسات. أنت مسؤول عن تكوين تكنولوجيا المعلومات وبرامج الكمبيوتر والنظام الأساسي للوصول إلى موقعنا. يجب عليك استخدام برنامج الحماية من الفيروسات الخاص بك. يجب ألا تسيء استخدام موقعنا عن طريق إدخال فيروسات أو أحصنة طروادة أو ديدان أو قنابل منطقية أو مواد أخرى ضارة أو ضارة تقنيًا. يجب ألا تحاول الوصول غير المصرح به إلى موقعنا أو الخادم الذي تم تخزين موقعنا عليه أو أي خادم أو كمبيوتر أو قاعدة بيانات متصلة بموقعنا. يجب ألا تهاجم موقعنا عن طريق هجوم رفض الخدمة أو هجوم رفض الخدمة الموزع. سنقوم بالإبلاغ عن أي انتهاك من هذا القبيل إلى سلطات إنفاذ القانون ذات الصلة وسوف نتعاون مع تلك السلطات من خلال الكشف عن هويتك لهم. في حالة حدوث مثل هذا الانتهاك ، سيتوقف حقك في استخدام موقعنا على الفور.",
	terms_conditions_content_title17: "الارتباط بموقعنا",
	terms_conditions_content_p17:
		"يمكنك الارتباط بصفحتنا الرئيسية ، بشرط أن تقوم بذلك بطريقة عادلة وقانونية ولا تضر بسمعتنا أو تستغلها. يجب ألا تنشئ رابطًا بطريقة توحي بأي شكل من أشكال الارتباط أو الموافقة أو التأييد من جانبنا في حالة عدم وجود ذلك. يجب ألا تنشئ رابطًا إلى موقعنا في أي موقع لا تملكه أنت. لا يجوز تأطير موقعنا على أي موقع آخر ، ولا يجوز لك إنشاء رابط لأي جزء من موقعنا بخلاف الصفحة الرئيسية. نحن نحتفظ بالحق في سحب إذن الربط دون إشعار. إذا كنت ترغب في الارتباط أو استخدام أي محتوى على موقعنا بخلاف ما هو مذكور أعلاه ، فيرجى الاتصال info@elmomawel.com.",
	terms_conditions_content_title18: "القانون الواجب التطبيق",
	terms_conditions_content_p18:
		"يرجى ملاحظة أن شروط الاستخدام هذه وموضوعها وصياغتها (وأي نزاعات أو مطالبات غير تعاقدية) ، تخضع للقانون المصري. نتفق نحن وأنت على أن محاكم مصر سيكون لها الاختصاص الحصري.",
};
