import React, { useState, useEffect } from "react";
import styles from "./Faq.module.scss";
import Accordion from "react-bootstrap/Accordion";
import { Tr } from "utils/i18n";
import PagesHead from "components/common/PagesHead/PagesHead";

import { connect } from "react-redux";
import { loadFaqs } from "redux/main/actions";

import Loader from "../../components/Loader";

const Faq = (props) => {
  // To Cahnge the SandwichIcon based on class active
  const [activeIndex, setActive] = useState(null);
  // FAQs
  const [faqs, setFaqs] = useState([]);
  // Loader
  const [loading, setLoading] = useState(true);
  // return true or false [active or not] of the index of current collapse that i clicked on
  const handleActiveAccordion = (index) => {
    const ifHassClass = document
      .querySelectorAll(`.${styles.CollapseHeader}`)
      [index].classList.contains(`${styles.active}`);

    ifHassClass ? setActive(null) : setActive(index);
  };

  useEffect(() => {
    props.loadFaqs().then((result) => {
      setLoading(false);
      setFaqs(result.data.data.data);
    });
  }, []);

  return (
    <>
      <PagesHead
        mainTitle={Tr.tr("basic:questions")}
        paragraph={Tr.tr("faq:desc")}
        imgSrc="/img/faq.png"
      />
      <div className="container">
        <div className={`AddPadding ${styles.Faq}`}>
          <div className="row">
            <div className="col-12 col-lg-2">
              <p className={`TitleFont ${styles.Tit}`}>
                <Tr tr="faq:faq" />
              </p>
            </div>
            <div className="col-12 col-lg-8">
              {!loading ? (
                <>
                  {faqs && faqs.length > 0 ? (
                    <Accordion>
                      {/* ADD CLASS SHOW TO COLLAPSE TO ROTATE THE SandwichIcon */}
                      {faqs.map((faq, index) => (
                        <div className={styles.Collapse} key={index}>
                          <Accordion.Toggle
                            as="span"
                            eventKey={index}
                            className={`${styles.CollapseHeader} ${
                              activeIndex == index ? styles.active : ""
                            }`}
                            onClick={() => handleActiveAccordion(index)}
                          >
                            <span className="SubTitle">{faq.question}</span>
                            <div className={styles.SandwichIcon}>
                              <span></span>
                              <span></span>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={index}>
                            <div className={`DescFont ${styles.CollapseDesc}`}>
                              {faq.answer}
                            </div>
                          </Accordion.Collapse>
                        </div>
                      ))}
                    </Accordion>
                  ) : (
                    <div className="noData">
                      {Tr.tr("basic:there_are_no_data")}
                    </div>
                  )}
                </>
              ) : (
                <Loader loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { loadFaqs })(Faq);
