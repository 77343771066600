import React, { Component } from "react";
import styles from "./ServicesDetails.module.scss";
import { Tr } from "utils/i18n";
import PagesHead from "components/common/PagesHead/PagesHead";
import { connect } from "react-redux";
import { getServiceById } from "redux/ourServices/actions";

class ServicesDetails extends Component {
  state = {
    serviceDetails: [],
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const id = params.get("id");
    this.props.getServiceById(id).then((res) => {
      this.setState({ serviceDetails: res.data.data });
    });
  }

  render() {
    let data = this.state.serviceDetails;
    return (
      <>
        <PagesHead
          addtionalClass={"TitleFontM"}
          // mainTitle={Tr.tr("ServicesDetails:credit_card")}
          mainTitle={data.name}
          paragraph={data.excerpt}
          imgSrc={data.image}
          withButton
          buttonContent={Tr.tr("ServicesDetails:request_service")}
          buttonType="link"
          path={`/profile-services/${data.id}`}
          isApplied={data.is_applied}
        />

        <div className={styles.ServicesDetailsParagraph}>
          <div className="container">
            <p className="DescFont">{data.description}</p>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { getServiceById })(ServicesDetails);
