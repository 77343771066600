import React, { Component } from "react";

import { withRouter, Link } from "react-router-dom";
import styles from "./Header.module.scss";
import SignDropDown from "./components/SignDropDown/SignDropDown";
import LocalizationDropDown from "./components/LocalizationDropDown/LocalizationDropDown";
import SearchDrop from "./components/SearchDrop/SearchDrop";
import NavItems from "./components/NavItems/NavItems";
import Loader from "./components/Loader/Loader";
import { isTabletView } from "utils/device";
import { connect } from "react-redux";
import { Tr } from "utils/i18n";

import { loadMyProfile } from "redux/personalProfile/actions";

class Header extends Component {
  state = {
    isSearchOpen: false,
    isAuth: true,
    isSideOpen: false,
    userAvatar: null,
  };

  componentDidMount() {
    let auth = localStorage.getItem("auth");
    let authParser = auth && JSON.parse(auth);
    auth &&
      (authParser.user.type === "personal" ||
        authParser.user.type === "corporate") &&
      this.props.loadMyProfile().then((res) => {
        this.setState({ userAvatar: res.data.data.avatar });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ isSearchOpen: false });
    }
  }

  getProfileLink(type) {
    if (type === "maker") {
      return "/provider-app";
    } else if (type === "approval") {
      return "/provider-withdrawal";
    } else if (type === "personal") {
      return "/profile";
    } else if (type === "corporate") {
      return "/profile";
    }
  }

  searchToggleHandler = () => {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
    this.setState({ isSideOpen: false });
  };

  sidebarToggleHandler = () => {
    this.setState({ isSideOpen: !this.state.isSideOpen });
    this.setState({ isSearchOpen: false });
  };

  // close side bar in responsive when click on nav items links
  closeSideBar = () => {
    this.setState({
      isSideOpen: false,
    });
  };

  render() {
    // check pass to active search btn
    const machesRouts = ["search"];
    const isMach = machesRouts.includes(
      this.props.location.pathname.split("/")[1]
    );
    const { user } = this.props;
    // const windowWidth = window.innerWidth;
    return (
      <>
        <Loader />
        {isTabletView() ? (
          <>
            <div className={styles.ResponsiveHeader}>
              <div
                className={`${styles.Responsive} ${
                  this.state.isSideOpen ? styles.open : ""
                }`}
              >
                <div className="container">
                  {user && (
                    <div className={styles.UserArea}>
                      <Link
                        to={this.getProfileLink(user.type)}
                        className={styles.User}
                        onClick={this.closeSideBar}
                      >
                        <img src={user.avatar} alt={user.first_name} />
                      </Link>
                      <Link
                        to={this.getProfileLink(user.type)}
                        className={styles.UserName}
                        onClick={this.closeSideBar}
                      >
                        {Tr.tr("Profile:PERSONAL_PROFILE")}
                      </Link>
                    </div>
                  )}
                  <NavItems
                    responsive
                    classes={{
                      NavItem: styles.NavItem,
                      DropBtn: styles.DropBtn,
                    }}
                    closeSideBar={this.closeSideBar}
                  />
                </div>
              </div>
              <div className="container">
                <div className={styles.Inner}>
                  <div className={styles.LeftSide}>
                    <Link
                      to="/"
                      className={styles.Logo}
                      onClick={this.closeSideBar}
                    >
                      <img src="/img/logo.png" alt="logo" />
                    </Link>
                  </div>
                  <div className={styles.RightSide}>
                    <span
                      className={`${styles.SearchIcon} ${styles.NavItem}`}
                      onClick={this.searchToggleHandler}
                      style={{
                        color:
                          isMach || this.state.isSearchOpen ? "#e22523" : "",
                      }}
                    >
                      <img src="/img/search-icon.svg" alt="search icon" />
                      <img src="/img/search-icon-hover.svg" alt="search icon" />
                    </span>
                    <LocalizationDropDown
                      classes={{
                        NavItem: styles.NavItem,
                        DropBtn: styles.DropBtn,
                      }}
                    />
                    <div
                      className={`${styles.NavToggler} ${
                        this.state.isSideOpen ? styles.active : ""
                      }`}
                      onClick={this.sidebarToggleHandler}
                    >
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SearchDrop isOpen={this.state.isSearchOpen} />
          </>
        ) : (
          <>
            <header className={`${styles.Header}  ${styles.Sticky}`}>
              <div className="container">
                <div className={`${styles.Nav} d-none d-lg-flex`}>
                  <div className={styles.LeftSide}>
                    <Link to="/" className={styles.Logo}>
                      <img src="/img/logo.png" alt="logo" />
                    </Link>
                    {/* NavItems list */}
                    <NavItems />
                  </div>
                  <div className={styles.RightSide}>
                    <span
                      className={`${styles.SearchIcon} ${styles.NavItem}`}
                      onClick={this.searchToggleHandler}
                      style={{
                        color:
                          isMach || this.state.isSearchOpen ? "#e22523" : null,
                      }}
                    >
                      <img src="/img/search-icon.svg" alt="search icon" />
                      <img src="/img/search-icon-hover.svg" alt="search icon" />
                    </span>
                    <LocalizationDropDown
                      classes={{
                        NavItem: styles.NavItem,
                        DropBtn: styles.DropBtn,
                      }}
                    />
                    {!user ? (
                      <SignDropDown
                        classes={{
                          NavItem: styles.NavItem,
                          DropBtn: styles.DropBtn,
                        }}
                      />
                    ) : null}
                    {user && (
                      <Link
                        to={this.getProfileLink(user.type)}
                        className={styles.User}
                      >
                        <img src={user.avatar} alt={user.first_name} />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <SearchDrop isOpen={this.state.isSearchOpen} />
            </header>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ auth: { user } }) => ({ user });
export default connect(mapStateToProps, { loadMyProfile })(withRouter(Header));
