import React from "react";
import styles from "./ServiceTable.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { withChangeLang } from "components/hocs/withChangeLang";

import Pagination from "components/Pagination";
import Loader from "components/Loader";

const ServiceTable = ({
  currLang,
  reloadData,
  response,
  onPaginate,
  loading,
  type,
  order,
  onSort,
  ...props
}) => {
  const tableHeader = [
    { label: Tr.tr("Table:case_id"), key: "id" },
    { label: Tr.tr("Table:month_sal"), key: "monthly_income" },
    { label: Tr.tr("Table:service_name"), key: "" },
    { label: Tr.tr("Table:type_of_facility"), key: "type_of_facility" },
    { label: Tr.tr("Table:amount_fac"), key: "amount" },
  ];
  const sortCondetion = (column) => order === column.key && type === "desc";

  return (
    <>
      {!loading ? (
        <div className={styles.PersonalTable}>
          <>
            <Table className={styles.Table}>
              <thead>
                <tr>
                  {tableHeader.map((column, index) => {
                    return (
                      <th key={index}>
                        <div
                          onClick={
                            sortCondetion(column)
                              ? () => onSort(column.key, "asc")
                              : () => onSort(column.key, "desc")
                          }
                        >
                          <span>{column.label}</span>
                          {sortCondetion(column) ? (
                            <i className={"arrow_up  icon-dropdown "}></i>
                          ) : (
                            <i className={"icon-dropdown"}></i>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {props.rows.length ? (
                  props.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{row.id}</td>
                      <td>
                        {row["monthly_income"] ? row["monthly_income"] : "-"}
                      </td>
                      <td>{row.service.name ? row.service.name : "-"}</td>
                      <td>
                        {row.type_of_facility ? row.type_of_facility : "-"}
                      </td>
                      <td>
                        <div className={styles.ArrowColumn}>
                          {row["amount"] ? row["amount"] : "-"}
                          <div className={styles.ArrowLink}>
                            <Link
                              to={`/provider-select-service-details/${row.id}`}
                            >
                              <i className="icon-dropdown"></i>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className={styles.NoAvailableData}>
                      {Tr.tr("basic:no_available_data")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination response={response} onPaginate={onPaginate} />
          </>
        </div>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};

ServiceTable.defaultProps = {};

ServiceTable.propTypes = {};

export default withChangeLang()(ServiceTable);
