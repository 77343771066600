import React, { Component } from "react";
import styles from "./EditPersonalProfile.module.scss";
import { Tr } from "utils/i18n";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { loadMyProfile, editMyProfile } from "redux/personalProfile/actions";

import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

import { Formik, ErrorMessage } from "formik";
import { Field } from "components/fields/index";
import AvatarEdite from "components/common/AvatarEdite/AvatarEdite";
import Loader from "../../../../components/Loader";
import ScrollToError from "components/common/ScrollToError";
import ar from "date-fns/locale/ar-SA";
const currLang = localStorage.getItem("i18nextLng");
const SUCCESS_MESSAGE = Tr.tr("Profile:edit_profile_SUCCESS_MESSAGE");

class EditPersonalProfile extends Component {
  state = {
    profileData: null,
    startDate: null,
    gender: null,
    isEditSuccess: false,
    loading: true,
  };

  componentDidMount() {
    this.props.loadMyProfile().then((res) => {
      this.setState({
        profileData: res.data.data,
        gender: res.data.data.gender,
        loading: false,
        startDate: new Date(res.data.data.date_of_birth),
      });
      let data = JSON.parse(localStorage.getItem("auth"));
      data.user.avatar = res.data.data.avatar;
      localStorage.setItem("auth", JSON.stringify(data));
    });
  }

  resetState = () => {
    setTimeout(() => this.setState({ isEditSuccess: false }), 3000);
  };

  onChangeGender(selected, setFieldValue) {
    setFieldValue("gender", selected);
    this.setState({ gender: selected.value });
  }

  onChangeBirthDate(selected, setFieldValue) {
    setFieldValue("date_of_birth", selected);
    this.setState({ startDate: selected });
  }

  getUpdatedAvatar = (avatar) => {
    this.setState((prevState) => ({
      profileData: { ...prevState.profileData, avatar },
    }));
  };

  // to got to top of the page after submitting data
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  handleChangeInput(name, value, setFieldValue) {
    setFieldValue(name, value);
  }

  render() {
    const user = this.state.profileData;
    const loading = this.state.loading;
    const dateFormat = "YYYY-MM-DD";
    return !loading ? (
      <>
        <div className="col-lg-4 col-xl-3">
          {/* User Edit Profile Pic */}
          {user && <AvatarEdite getUpdatedAvatar={this.getUpdatedAvatar} />}
        </div>
        <div className="col-lg-8 col-xl-9">
          <div className={styles.EditPersonalProfile}>
            <div className={styles.EditForm}>
              {user && (
                <Formik
                  initialValues={{
                    first_name: user.first_name,
                    middle_name: user.middle_name,
                    last_name: user.last_name,
                    date_of_birth: user.date_of_birth,
                    gender: user.gender,
                    phone: user.phone,
                    email: user.email,
                    avatar: user.avatar,
                    lang: { label: user.lang === "en" ? "English" : "العربية", value: user.lang },
                  }}
                  validate={(values) => {
                    let errors = {};
                    if (!values.date_of_birth)
                      errors.date_of_birth = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:date_picker"),
                      });
                    if (!values.first_name.trim())
                      errors.first_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:first_name"),
                      });
                    if (!values.middle_name.trim())
                      errors.middle_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:middle_name"),
                      });
                    if (!values.last_name.trim())
                      errors.last_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:last_name"),
                      });
                    if (!values.gender)
                      errors.gender = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:gender"),
                      });
                    if (!values.lang)
                      errors.lang = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:pref_lang"),
                      });
                    if (!values.phone.trim())
                      errors.phone = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:phone_number"),
                      });
                    else if (values.phone.length < 9) {
                      errors.phone = Tr.tr("Forms:err_min", {
                        num: 9 + Tr.tr("Forms:digit"),
                      });
                    } else if (!/^[0-9]+$/.test(values.phone)) {
                      errors.phone = Tr.tr("Forms:err_invalid_phone");
                    }
                    if (!values.email)
                      errors.email = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:email"),
                      });
                    else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = Tr.tr("Forms:invalid");
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    let val = { ...values };
                    // val.gender = val.gender.value;
                    val.lang = val.lang.value;
                    val.gender = this.state.gender;
                    val.date_of_birth = val.date_of_birth
                      .toString()
                      .includes("GMT")
                      ? moment(val.date_of_birth)
                          .locale("en")
                          .format(dateFormat)
                      : val.date_of_birth;
                    console.log(val.date_of_birth);
                    if (user.avatar.includes("storage")) {
                      val.avatar = user.avatar.split("storage/")[1];
                    } else {
                      val.avatar = user.avatar;
                    }
                    ///TO::DO will transform birth date before send to back end
                    this.props
                      .editMyProfile(val)
                      .then((res) => {
                        this.setState(
                          {
                            errorMessage: SUCCESS_MESSAGE,
                            isEditSuccess: true,
                          },
                          () => {
                            this.scrollUp();
                            this.resetState();
                          }
                        );
                      })
                      .catch((res) => {
                        try {
                          if (res.error.response.data !== undefined) {
                            setErrors(res.error.response.data.data);
                          }
                          this.setState({
                            errorMessage: res.error.response.data.message,
                            isEditSuccess: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      })
                      .finally(() => {
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    setFieldTouched,
                  }) => (
                    <form action="" onSubmit={handleSubmit}>
                      {this.state.isEditSuccess && (
                        <div className={styles.UpdatedSuccessfully}>
                          {this.state.errorMessage}
                        </div>
                      )}
                      <Field
                        name="first_name"
                        label={Tr.tr("Forms:first_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        touched={touched}
                        required
                      />

                      <Field
                        name="middle_name"
                        label={Tr.tr("Forms:middle_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        value={values.middle_name}
                        required
                      />

                      <Field
                        name="last_name"
                        label={Tr.tr("Forms:last_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        touched={touched}
                        required
                      />

                      <div className="InputGroup">
                        <label htmlFor="" className="Required">
                          {Tr.tr("Forms:date_picker")}
                        </label>
                        <div className="DatePicker">
                          <DatePicker
                            name="date_of_birth"
                            label={Tr.tr("Forms:date_picker")}
                            type="date"
                            selected={this.state.startDate}
                            onChange={(value) =>
                              this.onChangeBirthDate(value, setFieldValue)
                            }
                            value={values.date_of_birth}
                            className="Input"
                            dateFormat="yyyy-MM-dd"
                            placeholderText={Tr.tr("basic:date_format")}
                            autoComplete="off"
                            onKeyDown={(e) => e.preventDefault()}
                            maxDate={new Date().setDate(
                              new Date().getDate() - 1
                            )}
                            required
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={121}
                            locale={currLang === "ar" && ar}
                          />
                          <i className="icon-calendar"></i>
                        </div>
                        <div className="err">
                          <ErrorMessage name="date_of_birth" />
                        </div>
                      </div>

                      <div className="InputGroup">
                        <label htmlFor="" className="Required">
                          {Tr.tr("Forms:gender")}
                        </label>
                        <div className="SelectPicker">
                          <Select
                            name="gender"
                            label={Tr.tr("Forms:gender")}
                            placeholder="Select"
                            options={[
                              { label: Tr.tr("basic:male"), value: "male" },
                              { label: Tr.tr("basic:female"), value: "female" },
                            ]}
                            // value={values.gender}
                            onBlur={() => setFieldTouched("gender", true)}
                            onChange={(value) =>
                              this.onChangeGender(value, setFieldValue)
                            }
                            defaultValue={
                              user.gender === "male"
                                ? {
                                    label: Tr.tr("basic:male"),
                                    value: user.gender,
                                  }
                                : {
                                    label: Tr.tr("basic:female"),
                                    value: user.gender,
                                  }
                            }
                            isSearchable={false}
                            autoFocus={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            required
                          />
                        </div>
                        <div className="err">
                          <ErrorMessage name="gender" />
                        </div>
                      </div>

                      <Field
                        name="phone"
                        label={Tr.tr("Forms:phone_number")}
                        type="tel"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        touched={touched}
                        hint={Tr.tr("Forms:phone_hint")}
                        required
                        maxLength={15}
                      />
                      <div className="InputGroup">
                        <label htmlFor="" className="Required">
                          {Tr.tr("Forms:pref_lang")}
                        </label>
                        <div className="SelectPicker">
                          <Select
                            onBlur={() => setFieldTouched("lang", true)}
                            onChange={(value) =>
                              this.handleChangeInput(
                                "lang",
                                value,
                                setFieldValue
                              )
                            }
                            name="lang"
                            options={[
                              { label: "العربية", value: "ar" },
                              { label: "English", value: "en" },
                            ]}
                            value={values.lang}
                            isSearchable={false}
                            placeholder={Tr.tr("Forms:select")}
                            autoFocus={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        </div>
                        <div className="err">
                          <ErrorMessage name="lang" />
                        </div>
                      </div>

                      <Field
                        name="email"
                        label={Tr.tr("Forms:email")}
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        touched={touched}
                        required
                      />
                      <Link
                        to="/change-password"
                        className={`DescFont ${styles.ChangePass}`}
                      >
                        {Tr.tr("Forms:change_password")}
                      </Link>
                      <div className="LogBtns">
                        <Link to="/profile" className="Button">
                          <Tr tr="Forms:back_to_profile" />
                        </Link>
                        <button
                          disabled={isSubmitting}
                          style={{ opacity: isSubmitting ? 0.8 : 1 }}
                          className="Button White"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <Tr tr="basic:save_changes_load" />
                          ) : (
                            <Tr tr="basic:save_changes" />
                          )}
                        </button>
                      </div>
                      <ScrollToError />
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </>
    ) : (
      <Loader loading={loading} />
    );
  }
}

export default connect(null, { loadMyProfile, editMyProfile })(
  EditPersonalProfile
);
