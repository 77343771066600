import * as T from "./types";

export const laodNews = (page) => ({
  type: T.LOAD_NEWS,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}pages/news${page}`,
    method: "get",
  },
});

export const laodNewsSlider = () => ({
  type: T.LOAD_NEWS_SLIDER,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}pages/news-slider`,
    method: "get",
  },
});

export const getNewById = (id) => ({
  type: T.GET_NEW_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}pages/news/${id}`,
    method: "get",
  },
  params: { id },
});
