import React, { useEffect, useState } from "react";
import styles from "./ProfileSubmitFacility.module.scss";
import { Link, withRouter } from "react-router-dom";

import SideBar from "components/SideBar";
import { Tr } from "utils/i18n";
import { profileSubmitFacility } from "redux/profile/actions";
import { connect } from "react-redux";

import Loader from "../../components/Loader";

import { useAuth } from "utils/useAuthHook";

import Modal from "components/common/ModalComponent/ModalComponent";

const ProfileSubmitFacility = ({ ...props }) => {
  // Show or Hide state based on show value
  const [message, setMessage] = useState("");
  const [banks, setBanks] = useState({});
  const [userServiceId, setUserServiceId] = useState(null);

  // Loader
  const [loading, setLoading] = useState(true);

  // user type
  let [userType] = useAuth();

  // Show or Hide state based on show value
  const [show, setShow] = useState(false);

  // Show modal if show value is true [setShow(true)]
  const handleShow = (userServiceId) => {
    setShow(true);
    setUserServiceId(userServiceId);
  };

  // Hide modal if show value is false [setShow(false)] [Sign Out Modal]
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    props
      .profileSubmitFacility()
      .then((result) => {
        if (result.data) {
          if (result.data.data) {
            setLoading(false);
            setBanks(result.data.data);
          }
        }
      })
      .catch((res) => {
        if (res.error.response) {
          setLoading(false);
          setMessage(res.error.response.data.message);
        }
      });
  }, []);

  /**
   * @param {'normal' | 'islamic'} bankType
   */
  const renderBanks = (bankType) => {
    return (
      banks[bankType] &&
      banks[bankType].data.length > 0 && (
        <>
          <p className={`TitleFont ${styles.Tit} ${styles.BankLabel}`}>
            {banks[bankType].label}
          </p>
          <div className={styles.WithdrawalSection} key={banks[bankType].label}>
            <div className="row">
              {banks[bankType].data.map((singleBank) => (
                <div className="col-md-6 col-lg-4" key={singleBank.id}>
                  {singleBank.is_applied === 0 ? (
                    <Link
                      key={singleBank.id}
                      className={`SquareButton ${styles.Services}`}
                      to={`profile-services/${singleBank.id}`}
                    >
                      {singleBank.name}
                    </Link>
                  ) : (
                    <span
                      className={`SquareButton`}
                      onClick={() => handleShow(singleBank.user_service_id)}
                    >
                      {singleBank.name}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )
    );
  };
  return (
    <div className={styles.ProfileSubmitFacility}>
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            <div>{message}</div>
            {!loading ? (
              <>
                {banks.normal &&
                !banks.normal.data.length > 0 &&
                banks.islamic &&
                !banks.islamic.data.length > 0 ? (
                  <p className="noData">
                    {Tr.tr("Profile:THERE_ARE_NO_SERVICES")}
                  </p>
                ) : (
                  <>
                    {renderBanks("normal")}
                    {renderBanks("islamic")}
                  </>
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
      {/* Applied Service Modal */}
      <Modal show={show} close={handleClose}>
        <span className="TitleFont SignOutModal">
          <Tr tr="Profile:APPLIED_SERVICE" />
        </span>
        <button className="Button White ModalLeftButton" onClick={handleClose}>
          {Tr.tr("basic:cancel")}
        </button>
        <Link
          to={`/personal-request-details/${userServiceId}`}
          className="Button ModalRightButton"
        >
          {Tr.tr("Profile:VIEW_THE_REQUEST")}
        </Link>
      </Modal>
    </div>
  );
};

export default connect(null, { profileSubmitFacility })(
  withRouter(ProfileSubmitFacility)
);
