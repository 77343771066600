import React, { Component } from "react";
import { withTranslation } from "react-i18next";

export function withChangeLang({ ...props }) {
  return WrappedComponent => {
    class WithChangeLang extends Component {
      render() {
        return (
          <WrappedComponent
            {...this.props}
            changeLang={lang => {
              // this.props.i18n.changeLanguage(lang);
              localStorage.setItem("i18nextLng", lang)
              window.location.reload();
            }}
            currLang={this.props.i18n.language}
          />
        );
      }
    }

    WithChangeLang.displayName = `WithChangeLang(${getDisplayName(
      WrappedComponent
    )})`;

    return withTranslation()(WithChangeLang);
  };
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
