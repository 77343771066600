import history from "utils/history";
import * as AUTH_TYPES from "redux/auth/types";
import { signOut } from "redux/auth/actions";
import { call, put } from "redux-saga/effects";
import { sendRequest, success } from "redux-saga-requests";

export function* onErrorSaga(error, action) {
  const { response } = error;

  if (response) {
    switch (response.status) {
      case 401:
        yield put({ type: AUTH_TYPES.LOGOUT });

        try {
          yield call(sendRequest, signOut());
        } catch (err) {
          console.error(err);
        }

        yield put({ type: success(AUTH_TYPES.LOGOUT) });
        if (
          history.location.pathname !== "/sign-in" &&
          action.type !== AUTH_TYPES.LOGOUT
        ) {
          history.push("/sign-in");
        }
        break;

      case 500:
        history.push("/page500");

        break;

      case 404:
        history.push("/not-found");
        break;

      default:
        break;
    }
  } else {
  }

  return { error };
}
