import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProviderLayout from "layout/ProviderLayout";
import { loadApplicationServices } from "redux/ourServices/actions";

const ProviderApp = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [corporateCount, setCorporateCount] = useState([]);
  const [personalCount, setPersonalCount] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    props
      .loadApplicationServices()
      .then((result) => {
        if (result.data) {
          setLoading(false);
          setPersonalCount(result.data.data.personal);
          setCorporateCount(result.data.data.corporate);
        }
      })
      .catch((res) => {
        if (res.error) {
        if (res.error.response.data.message) {
          setLoading(false);
          setMessage(res.error.response.data.message);
        }}
      });
  }, []);

  return (
    <ProviderLayout
      {...{
        loading,
        corporateCount,
        personalCount,
        message,
        serviceUrl: "provider-select-service",
      }}
    />
  );
};

export default connect(null, { loadApplicationServices })(
  withRouter(ProviderApp)
);
