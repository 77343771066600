
export function* onRequestSaga(request, action) {
  if (typeof window === "undefined") {
    return request;
  }

  if (!request.headers) {
    request.headers = {};
  }
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept-Language"] = localStorage.getItem("i18nextLng");
  if(localStorage.getItem("api_token")){
    request.headers["Authorization"] = "Bearer " + localStorage.getItem("api_token");
  }
  return request;
}
