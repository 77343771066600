import React, { useEffect, useState, Fragment } from "react";
import styles from "./ProfileServices.module.scss";
import { Link, withRouter, Prompt } from "react-router-dom";
import SideBar from "components/SideBar";
import Modal from "components/common/ModalComponent/ModalComponent";
import { ErrorMessage, Formik } from "formik";
import { Field, TextAreaField, CheckBoxField } from "components/fields/index";
import { loadMyProfile, editMyProfile } from "redux/personalProfile/actions";

import { useAuth } from "utils/useAuthHook";

import { Tr } from "utils/i18n";
import { connect, useSelector } from "react-redux";
import {
	profileServices,
	uploadFile,
	applyService,
	uploadImage,
	formWithValue,
	updateServices,
	handleGetNationalities,
} from "../../redux/profile/actions";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

import Loader from "../../components/Loader";
import { resetQuickForm } from "redux/home/actions";
import ScrollToError from "components/common/ScrollToError";

import { getFileName } from "utils/getFileName";

import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";

import ar from "date-fns/locale/ar-SA";
const currLang = localStorage.getItem("i18nextLng");

const ProfileServices = ({ ...props }) => {
	// Show or Hide state based on show value [Sign Out Modal]

	const [form, SetForm] = useState([]);
	const [service, setService] = useState([]);
	const [message, setMessage] = useState("");
	const [initVal, setIntVal] = useState({});
	const [facilitiesTypes, setFacilitiesType] = useState([]);
	const [dateFormat] = useState("yyyy-MM-DD");
	const [validation, setValidations] = useState([]);
	const [mode, setMode] = useState("add");
	const [id, setID] = useState(0);
	const [userData, setuserData] = useState({});

	useEffect(() => {
		props.loadMyProfile().then((res) => {
			let data = res.data.data;
			setuserData(data);
		});
	}, []);

	// user type
	let [userType] = useAuth();

	// Calendar [to set the selected date]
	const [startDate, setStartDate] = useState(null);

	// input image
	const [showInputImage, setShowInputImage] = useState("");

	// remember [not a robot]
	const [remember, setRemember] = useState(false);
	const onChangeCheckedRobot = (selected, setFieldValue) => {
		setRemember(selected);
		setFieldValue("remember", selected);
	};

	// acceptTerms [accept terms]
	const [acceptTerms, setAcceptTerms] = useState(false);
	const onChangeCheckedAcceptTerms = (selected, setFieldValue) => {
		setAcceptTerms(selected);
		setFieldValue("acceptTerms", selected);
	};

	// Scroll To top after catching errors [400] [Bad Request]
	const scrollUp = () => {
		window.scrollTo(0, 0);
	};

	// Loader
	const [loading, setLoading] = useState(true);

	// Show or Hide state based on show value [Cancel btn Modal]
	const [cancel, setCancelation] = useState(true);
	const quickForm = useSelector((state) => state.home.quickForm);
	const inputNumberInvalidCharachters = ["-", "+", "e", "E", "."];
	// Show modal if cancel value is true [setCanncelation(true)] [Cancel btn Modal]
	const showCancelation = () => {
		setCancelation(true);
	};

	// Hide modal if cancel value is false [setCanncelation(false)] [Cancel btn Modal]
	const hideCancelation = () => {
		setCancelation(false);
	};

	const onChangeRadio = (selected, setFieldValue, name) => {
		setFieldValue(name, selected);
	};

	const onChangeSelect = (selected, setFieldValue, name) => {
		let select = name.replace("_select", "");
		setFieldValue(name, selected);
		setFieldValue(select, `${selected.value}`);
	};

	const onChangeMultiSelect = (selected, setFieldValue, name) => {
		if (selected && selected.length > 0) {
			if (selected.length <= 3) {
				setFieldValue(name, selected);
				setFieldValue(name + "_m_select", selected);
			}
		} else {
			setFieldValue(name + "_m_select", "");
			setFieldValue(name, "");
		}
	};

	const onChangeDate = (selected, setFieldValue, name) => {
		let date = name.replace("_date", "");
		let format = moment(selected).locale("en").format(dateFormat);
		setFieldValue(name, format);
		setFieldValue(date, format);
		// to set the selected date
		setStartDate(selected);
	};

	const onChangeUpload = (file, setFieldValue, name) => {
		if (file) {
			let fileName = name.replace("_file", "");
			let data = new FormData();
			data.append("file", file, file.fileName);
			setFieldValue(name, Tr.tr("Forms:UPLOADING")); // put uploading... word until the file been uploaded
			props
				.uploadFile(data)
				.then((res) => {
					setFieldValue(fileName, res.data.data.file);
					setFieldValue(name, file); // put the file value after the response being done
				})
				.catch((res) => {
					if (file.size > 1000000) {
						setFieldValue(fileName, "");
						setFieldValue(
							name,
							<span className={styles.ErrorFileType}>
								{Tr.tr("Forms:TOO_LARGE")}
							</span>
						);
					} else {
						setFieldValue(fileName, "");
						setFieldValue(
							name,
							<span className={styles.ErrorFileType}>
								{res.error.response.data.data.file[0]}
							</span>
						);
					}
				});
		}
	};

	const onUploadImage = (file, setFieldValue, name) => {
		if (file) {
			let fileName = name.replace("_file", "");
			let data = new FormData();
			data.append("image", file, file.fileName);
			setFieldValue(name, Tr.tr("Forms:UPLOADING")); // put uploading... word until the file been uploaded
			props
				.uploadImage(data)
				.then((res) => {
					setFieldValue(fileName, res.data.data.image_url);
					setShowInputImage(res.data.data.image_url);
					setFieldValue(name, file); // put the file value after the response being done
				})
				.catch((res) => {
					setFieldValue(
						name,
						<span className={styles.ErrorFileType}>
							{res.error.response.data.data.image}
						</span>
					);
					setShowInputImage("");
				});
		}
	};

	const setInitValue = (response, mode, withdrawal, service) => {
		let v = {};
		response.map((input) => {
			let val = "";
			if (input.value !== "" && input.type === "select_multiple") {
				val = input.value;
				v[input.name + "_m_select"] = JSON.parse(input.value);
				if (input.name === "nationality") {
					val = JSON.parse(input.value);
				}
			} else if (input.value !== "" && input.type === "select_dropdown") {
				v[input.name + "_select"] = JSON.parse(input.value);
				// val = input.value;
				val = JSON.parse(input.value).value; // access value fom object to avoid object error in edit mode

				if (input.name === "type_of_facility" && mode === "edit") {
					v[input.name + "_select"] = {
						value: `${withdrawal.type_of_facility_id}`,
						label: service.service_types.filter(
							(v) => v.value === withdrawal.type_of_facility_id
						)[0]["label"],
					};
					val = `${withdrawal.type_of_facility_id}`;
				}
			} else if (quickForm[input.name]) {
				if (input.name === "type_of_facility") {
					v[input.name + "_select"] = quickForm[input.name];
				}
				val = quickForm[input.name].value || quickForm[input.name];
			} else if (input.value !== "" && input.type === "date") {
				val = input.value;
			} else if (input.value !== "" && input.type === "file") {
				if (input.value.indexOf("storage") > -1) {
					let numFrom = input.value.lastIndexOf("storage/") + 8;
					let newLink = input.value.substring(numFrom);
					val = newLink;
				} else {
					val = input.value;
				}
				v[input.name + "_file"] = getFileName(input.value);
			} else if (input.value !== "" && input.type === "image") {
				val = input.value;
				v[input.name + "_file"] = getFileName(input.value);
				setShowInputImage(val);
			} else if (input.value !== "") {
				val = input.value;
			}
			v[input.name] = val;
		});
		setIntVal(v);
	};

	if (mode === "add") {
		// Set Inputs Default Values
		if (userType === "corporate") {
			initVal.contact_person_name = `${userData.first_name} ${userData.middle_name} ${userData.last_name}`;
			initVal.company_name = userData.company_name;
		} else {
			initVal.full_name = `${userData.first_name} ${userData.middle_name} ${userData.last_name}`;
		}
		initVal.phone_number = userData.phone;
		initVal.date_of_birth = userData.date_of_birth;
		initVal.date_of_birth_date = userData.date_of_birth;
	}

	const setValidation = (response) => {
		let v = {};
		response.map((input) => {
			v[input.name] = {
				label: input.label,
				type: input.type,
				validation: input.validations,
			};
		});
		setValidations(v);
	};
	const colourStyles = {
		option: (provided, state) => ({
			...provided,
			color: state.isDisabled ? "#ccc" : "#000",
		}),
	};

	useEffect(() => {
		///if applicationId exists this mean we come to edit this form
		if (props.match.params.applicationId) {
			setID(props.match.params.applicationId);
			setMode("edit");

			props
				.formWithValue(props.match.params.applicationId)
				.then((result) => {
					if (result.data) {
						if (result.data.data) {
							setLoading(false);
							setService(result.data.data.service);
							setInitValue(
								result.data.data.form,
								"edit",
								result.data.data.withdrawal,
								result.data.data.service
							);
							setValidation(result.data.data.form);
							SetForm(result.data.data.form);
							setFacilitiesType(
								result.data.data.service.service_types.map((v) => {
									return {
										value: v.value,
										label: v.label,
										isDisabled:
											result.data.data.withdrawal.type_of_facility_id ===
											v.value
												? false
												: !v.active,
									};
								})
							);
						}
					}
				})
				.catch((res) => {
					setLoading(false);
					try {
						setMessage(res.error.response.data.message);
					} catch (e) {
						// console.log(e, res);
					}
				});
		} else {
			setID(props.match.params.id);
			/// here we submit for fist time

			props
				.profileServices(props.match.params.id)
				.then((result) => {
					if (result.data) {
						if (result.data.data) {
							setLoading(false);
							setInitValue(result.data.data.form);
							setValidation(result.data.data.form);
							SetForm(result.data.data.form);
							setService(result.data.data.service);
							setFacilitiesType(
								result.data.data.service.service_types.map((v) => {
									return {
										value: v.value,
										label: v.label,
										isDisabled: !v.active,
									};
								})
							);
						}
					}
				})
				.catch((res) => {
					setLoading(false);
					try {
						setMessage(res.error.response.data.message);
					} catch (e) {
						// console.log(e, res);
					}
				});
		}

		return () => props.resetQuickForm();
	}, [setMessage, SetForm, setIntVal]);

	return (
		<div className={`AddPadding container ${styles.ServiceForm}`}>
			<Prompt
				when={cancel}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
			<div className="row">
				<SideBar />
				<div className="col-lg-9">
					<div className={styles.Tit}>
						<div className={styles.LeftSide}>
							{/* <p className={`TitleFont ${styles.Title}`}>{service.name}</p> */}
							<p className={`TitleFont ${styles.Title}`}>
								{userType === "personal"
									? Tr.tr("Profile:PERSONAL_FACILITY")
									: Tr.tr("Profile:CORPORATE_FACILITY")}
							</p>
							<p className={`DescFont ${styles.DescFont}`}>
								{service.description}
							</p>
						</div>
					</div>
					<Formik
						enableReinitialize={true}
						initialValues={initVal}
						validate={(values) => {
							let errors = {};
							let name, valid, type;
							Object.keys(validation).map((key) => {
								name = validation[key].label;
								valid = validation[key].validation;
								type = validation[key].type;
								let pattern = /^\d+$/;
								let is_digit = pattern.test(values[key]);
								if (key === "phone_number") {
									if (!/^[0-9]{9,15}$/g.test(values[key])) {
										errors[key] = Tr.tr("Forms:err_invalid_phone");
									}
								}
								if (
									valid.required &&
									type !== "number" &&
									type !== "select_multiple" &&
									!values[key].trim()
								) {
									errors[key] = Tr.tr("Forms:err_requierd", {
										field: name,
									});
								} else if (
									valid.required &&
									type === "number" &&
									values[key] === ""
								) {
									errors[key] = Tr.tr("Forms:err_requierd", {
										field: name,
									});
								} else if (type === "file" && !values[key]) {
									errors[key] = Tr.tr("Forms:err_files");
								} else if (
									type !== "select_dropdown" &&
									type !== "select_multiple" &&
									type !== "date" &&
									values[key] !== undefined
								) {
									if (valid.min && values[key].trim().length < valid.min) {
										errors[key] = Tr.tr("Forms:err_min", {
											num: valid.min,
										});
									}
									if (valid.max && values[key].trim().length > valid.max) {
										errors[key] = Tr.tr("Forms:err_max", {
											num: valid.max,
										});
									}
								} else if (type === "number" && !is_digit) {
									errors[key] = Tr.tr("Forms:err_digits");
								}
							});
							return errors;
						}}
						onSubmit={(values, { setSubmitting, setErrors }) => {
							hideCancelation();
							let val = { ...values };
							console.log(val);
							if (val.nationality) {
								let arr = [];
								val.nationality.map((name) => {
									arr.push(name.value);
								});
								val.nationality = arr;
								val.nationality_m_select = arr;
							}
							let value = {};
							Object.keys(val).map((v) => {
								if (
									// !v.includes("_m_select") &&
									!v.includes("_file") &&
									!v.includes("_date") &&
									!v.includes("_select")
								) {
									value[v] = val[v];
								}
							});
							if (mode === "edit") {
								props
									.updateServices(id, value)
									.then((res) => {
										if (res.data.data.status) {
											props.resetQuickForm();
											props.history.push("/profile");
										}
									})
									.catch((res) => {
										showCancelation();
										if (res.error.response.status === 400) {
											if (res.error.response.data.data) {
												setErrors(res.error.response.data.data);
											} else {
												setMessage(res.error.response.data.message);
												scrollUp();
											}
										}
									});
							} else {
								props
									.applyService(service.id, value)
									.then((res) => {
										if (res.data.data.status) {
											props.history.push("/profile");
										}
									})
									.catch((res) => {
										showCancelation();
										if (res.error.response.status === 400) {
											if (res.error.response.data.data) {
												setErrors(res.error.response.data.data);
											} else {
												setMessage(res.error.response.data.message);
												scrollUp();
											}
										}
									});
							}
						}}
					>
						{({
							values,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
							setFieldTouched,
							isSubmitting,
						}) => (
							<>
								{!loading ? (
									<>
										{!message ? (
											<form action="" onSubmit={handleSubmit}>
												<div className="row">
													{form.map((input, index) =>
														(() => {
															switch (input.type) {
																case "text":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<Field
																				name={input.name}
																				label={input.label}
																				type="text"
																				onBlur={handleBlur}
																				touched={touched}
																				onChange={handleChange}
																				placeholder={input.label}
																				required={input.validations.required}
																				maxLength={
																					input.name === "phone_number"
																						? 15
																						: null
																				}
																				hint={input.hint ? input.hint : ""}
																			/>
																		</div>
																	);
																case "number":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<Field
																				name={input.name}
																				label={input.label}
																				type="number"
																				onBlur={handleBlur}
																				touched={touched}
																				min={0}
																				maxLength={input.validations.max}
																				onKeyPress={(e) => {
																					if (
																						inputNumberInvalidCharachters.includes(
																							e.key
																						)
																					) {
																						e.preventDefault();
																					}
																					if (e.target.value.length >= 9) {
																						e.preventDefault();
																					}
																				}}
																				placeholder={input.label}
																				required={input.validations.required}
																				hint={input.hint ? input.hint : ""}
																			/>
																		</div>
																	);
																case "email":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<Field
																				name={input.name}
																				label={input.label}
																				type="email"
																				onBlur={handleBlur}
																				touched={touched}
																				onChange={handleChange}
																				placeholder={input.label}
																				required={input.validations.required}
																				hint={input.hint ? input.hint : ""}
																			/>
																		</div>
																	);
																case "file":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<label className="InputGroup d-block">
																				<label
																					htmlFor={input.name}
																					className={
																						input.validations.required
																							? "Required"
																							: ""
																					}
																				>
																					{input.label}
																				</label>
																				{
																					<p className="Input Pointer d-flex align-items-center">
																						{values[input.name + "_file"] ? (
																							values[input.name + "_file"]
																								.name ||
																							values[input.name + "_file"]
																						) : (
																							<Tr tr="Forms:select" />
																						)}
																					</p>
																				}
																				<div className="d-none">
																					<input
																						name={input.name + "_file"}
																						id={input.name + "_file"}
																						type="file"
																						onChange={(value) => {
																							onChangeUpload(
																								value.currentTarget.files[0],
																								setFieldValue,
																								input.name + "_file"
																							);
																						}}
																						className="form-control"
																						// accept="image/jpeg,image/png,image/jpg,application/pdf"
																					/>
																					<input
																						type="hidden"
																						name={input.name}
																						id={input.name}
																						value={values[input.name]}
																						onBlur={handleBlur}
																						onChange={handleChange}
																					/>
																				</div>
																				<div className="Hint">
																					{/* {Tr.tr("Forms:allow")} : pdf */}
																					{Tr.tr("Forms:UPLOAD")}: PNG, JPEG,
																					JPG, PDF, File
																				</div>
																				{/* {input.value !== "" ? (
                                          <a
                                            href={input.value}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            file
                                          </a>
                                        ) : (
                                          ""
                                        )} */}
																				{input.hint ? (
																					<div className="Hint">
																						{input.hint}
																					</div>
																				) : (
																					""
																				)}
																				<div className="err">
																					<ErrorMessage name={input.name} />
																				</div>
																			</label>
																		</div>
																	);
																case "image":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<label className="InputGroup d-block">
																				<label
																					htmlFor={input.name}
																					className={
																						input.validations.required
																							? "Required"
																							: ""
																					}
																				>
																					{input.label}
																				</label>
																				<p className="Input Pointer d-flex align-items-center">
																					{values[input.name + "_file"] ? (
																						values[input.name + "_file"].name ||
																						values[input.name + "_file"]
																					) : (
																						<Tr tr="Forms:select" />
																					)}
																				</p>
																				<div className="d-none">
																					<input
																						name={input.name + "_file"}
																						id={input.name + "_file"}
																						type="file"
																						onChange={(value) => {
																							onUploadImage(
																								value.currentTarget.files[0],
																								setFieldValue,
																								input.name + "_file"
																							);
																						}}
																						className="form-control"
																						accept="image/jpeg,image/png,image/jpg"
																					/>
																					<input
																						type="hidden"
																						name={input.name}
																						id={input.name}
																						value={values[input.name]}
																						onBlur={handleBlur}
																						onChange={handleChange}
																					/>
																				</div>
																				{values[input.name] &&
																				showInputImage ? (
																					<img
																						src={showInputImage}
																						width="100"
																						alt="input"
																					/>
																				) : (
																					""
																				)}
																				<div className="Hint">
																					{Tr.tr("Forms:allow")} : jpg , jpeg ,
																					png
																				</div>
																				{input.hint ? (
																					<div className="Hint">
																						{input.hint}
																					</div>
																				) : (
																					""
																				)}
																				<div className="err">
																					<ErrorMessage name={input.name} />
																				</div>
																			</label>
																		</div>
																	);
																case "date":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<div className="InputGroup ">
																				<label
																					htmlFor={input.name}
																					className={
																						input.validations.required
																							? "Required"
																							: ""
																					}
																				>
																					{input.label}
																				</label>
																				<div className="DatePicker">
																					<DatePicker
																						name={input.name + "_date"}
																						onChange={(value) =>
																							onChangeDate(
																								value,
																								setFieldValue,
																								input.name + "_date"
																							)
																						}
																						value={
																							input.value &&
																							!values[input.name + "_date"]
																								? input.value
																								: values[input.name + "_date"]
																						}
																						className="Input"
																						dateFormat={dateFormat}
																						placeholderText={input.label}
																						autoComplete="off"
																						onKeyDown={(e) =>
																							e.preventDefault()
																						}
																						onBlur={handleBlur}
																						touched={touched}
																						maxDate={new Date().setDate(
																							new Date().getDate() - 1
																						)}
																						showYearDropdown
																						scrollableYearDropdown
																						yearDropdownItemNumber={121}
																						locale={currLang === "ar" && ar}
																						selected={
																							input.value
																								? new Date(input.value)
																								: startDate
																						} // to set the selected date
																					/>
																					<i className="icon-calendar"></i>

																					<input
																						type="hidden"
																						name={input.name}
																						id={input.name}
																						value={values[input.name]}
																						onBlur={handleBlur}
																						touched={touched}
																						onChange={handleChange}
																					/>
																				</div>
																				{input.hint ? (
																					<div className="Hint">
																						{input.hint}
																					</div>
																				) : (
																					""
																				)}
																				<div className="err">
																					<ErrorMessage name={input.name} />
																				</div>
																			</div>
																		</div>
																	);
																case "radio_btn":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<div className="InputGroup ">
																				<label
																					htmlFor={input.name}
																					className={
																						input.validations.required
																							? "Required"
																							: ""
																					}
																				>
																					{input.label}
																				</label>
																				<div className="CheckBox radioBtnAlign">
																					{input.options.map(
																						(option, index) => (
																							<label
																								className="CheckBox"
																								key={index}
																							>
																								<input
																									type="radio"
																									value={option.value}
																									name={input.name}
																									onBlur={handleBlur}
																									touched={touched}
																									className="radio-input d-none"
																									onChange={(value) =>
																										onChangeRadio(
																											option.value,
																											setFieldValue,
																											input.name
																										)
																									}
																									checked={
																										option.value ===
																										values[input.name]
																									}
																								/>
																								<span className="radio-span"></span>
																								<p className="CheckLabel DescFont">
																									{option.label}
																								</p>
																							</label>
																						)
																					)}
																				</div>
																				{input.hint ? (
																					<div className="Hint">
																						{input.hint}
																					</div>
																				) : (
																					""
																				)}
																				<div className="err">
																					<ErrorMessage name={input.name} />
																				</div>
																			</div>
																		</div>
																	);
																case "select_multiple":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<div className="InputGroup">
																				<label
																					htmlFor={input.name}
																					className={
																						input.validations.required
																							? "Required"
																							: ""
																					}
																				>
																					{input.label}
																				</label>
																				<div className="SelectPicker">
																					<Select
																						onChange={(value) =>
																							onChangeMultiSelect(
																								value,
																								setFieldValue,
																								input.name
																							)
																						}
																						name={input.name + "_m_select"}
																						options={input.options}
																						value={
																							values[input.name + "_m_select"]
																						}
																						isSearchable={true}
																						placeholder={input.label}
																						autoFocus={false}
																						className="react-select-container"
																						classNamePrefix="react-select"
																						defaultValue={
																							mode === "add"
																								? undefined
																								: [...JSON.parse(input.value)]
																						}
																						isMulti
																					/>

																					<input
																						type="hidden"
																						name={input.name}
																						id={input.name}
																						value={values[input.name]}
																						onBlur={handleBlur}
																						touched={touched}
																						onChange={handleChange}
																					/>
																					{input.hint ? (
																						<div className="Hint">
																							{input.hint}
																						</div>
																					) : (
																						""
																					)}
																					{/* put this condition values[input.name + "_m_select"] == undefined because when select one nationality the error required still appear */}
																					{values[input.name + "_m_select"] ===
																						undefined && (
																						<div className="err">
																							<ErrorMessage name={input.name} />
																						</div>
																					)}
																				</div>
																			</div>
																		</div>
																	);
																case "select_dropdown":
																	return (
																		<div
																			className="col-lg-6"
																			key={input.name + index}
																		>
																			<div className="InputGroup">
																				<label
																					htmlFor={input.name}
																					className={
																						input.validations.required
																							? "Required"
																							: ""
																					}
																				>
																					{input.label}
																				</label>
																				<div className="SelectPicker">
																					<Select
																						onChange={(value) =>
																							onChangeSelect(
																								value,
																								setFieldValue,
																								input.name + "_select"
																							)
																						}
																						name={input.name + "_select"}
																						value={
																							values[input.name + "_select"]
																						}
																						options={
																							input.name === "type_of_facility"
																								? facilitiesTypes
																								: input.options
																						}
																						isSearchable={false}
																						placeholder={input.label}
																						autoFocus={false}
																						className="react-select-container"
																						classNamePrefix="react-select"
																						styles={colourStyles}
																						onBlur={handleBlur}
																						touched={touched}
																					/>
																					<input
																						type="hidden"
																						name={input.name}
																						id={input.name}
																						value={values[input.name]}
																						onBlur={handleBlur}
																						touched={touched}
																						onChange={handleChange}
																					/>
																				</div>
																				{input.hint ? (
																					<div className="Hint">
																						{input.hint}
																					</div>
																				) : (
																					""
																				)}
																				<div className="err">
																					<ErrorMessage name={input.name} />
																				</div>
																			</div>
																		</div>
																	);
																case "text_area":
																	return (
																		<Fragment key={input.name + index}>
																			<div className="col-lg-6">
																				<TextAreaField
																					name={input.name}
																					label={input.label}
																					onBlur={handleBlur}
																					touched={touched}
																					onChange={handleChange}
																					placeholder={input.label}
																					required={input.validations.required}
																					hint={input.hint ? input.hint : ""}
																				/>
																				<div className="err">
																					<ErrorMessage name={input.name} />
																				</div>
																			</div>
																		</Fragment>
																	);
																default:
																	return null;
															}
														})()
													)}
												</div>
												<div className={styles.CheckBoxWrapper}>
													<div className={styles.NotRobot}>
														<CheckBoxField
															name="remember"
															label={Tr.tr("Forms:not_robot")}
															type="checkbox"
															isChecked={remember}
															checked={() =>
																onChangeCheckedRobot(!remember, setFieldValue)
															}
															onBlur={handleBlur}
															touched={touched}
														/>
													</div>
													<div className={styles.TermsCondWrapper}>
														<CheckBoxField
															name="acceptTerms"
															label={Tr.tr("Forms:ACCEPT")}
															type="checkbox"
															isChecked={acceptTerms}
															checked={() =>
																onChangeCheckedAcceptTerms(
																	!acceptTerms,
																	setFieldValue
																)
															}
															onBlur={handleBlur}
															touched={touched}
														/>
														<a
															className={`DescFont ${styles.TermsConditions}`}
															href="/terms-conditions"
															target="_blank"
														>
															{Tr.tr("Forms:TERMS_CONDITIONS_POLICY")}
														</a>
													</div>
												</div>
												<div className={styles.BtnWrapper}>
													<Link
														to={
															mode === "edit"
																? `/personal-request-details/${id}`
																: "/profile-submit-facility"
														}
														className="Button White"
													>
														<Tr tr="basic:cancel" />
													</Link>
													{remember && acceptTerms ? (
														<button className="Button" type="submit">
															<Tr tr="Forms:submit" />
														</button>
													) : (
														<button className="Button" type="submit" disabled>
															<Tr tr="Forms:submit" />
														</button>
													)}
												</div>
												<ScrollToError />
											</form>
										) : (
											<BadRequestMessage
												message={message}
												redirectPage={
													mode === "edit"
														? "/profile"
														: "/profile-submit-facility"
												}
											/>
										)}
									</>
								) : (
									<Loader loading={loading} />
								)}
							</>
						)}
					</Formik>
				</div>
			</div>

			{/* Form Cancel btn Modal */}
			{/* <Modal show={cancel} close={hideCancelation}>
        <span className="TitleFont SignOutModal">
          <Tr tr="Forms:close_form" />
        </span>
        <button
          className="Button White ModalLeftButton"
          onClick={hideCancelation}
        >
          {Tr.tr("basic:cancel")}
        </button>
        <button
          className="Button ModalRightButton"
          // to={}
          onClick={() => {
            props.resetQuickForm();
            mode === "edit"
              ? props.history.push(`/personal-request-details/${id}`)
              : props.history.push("/profile-submit-facility");
          }}
        >
          {Tr.tr("Forms:confirm_cancel")}
        </button>
      </Modal> */}
		</div>
	);
};

export default connect(null, {
	profileServices,
	formWithValue,
	uploadFile,
	uploadImage,
	applyService,
	updateServices,
	resetQuickForm,
	handleGetNationalities,
	loadMyProfile,
})(withRouter(ProfileServices));
