import React from "react";
import PropTypes from "prop-types";
import styles from "./BadRequestMessage.module.scss";

import { Link } from "react-router-dom";

import { Tr } from "utils/i18n";

const BadRequestMessage = ({ ...props }) => {
  return (
    <div className={styles.BadRequestMessage}>
      <div className={`DescFont ${styles.DescFont} ${styles.errorMsg}`}>
        {props.message}
        {props.message ? (
          <Link to={props.redirectPage} className={styles.backLink}>
            <Tr tr="basic:back" />
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

BadRequestMessage.defaultProps = {};

BadRequestMessage.propTypes = {};

export default BadRequestMessage;
