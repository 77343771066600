export const en = {
  applications: "Applications",
  personal: "PERSONAL",
  corporate: "CORPORATE",
  withdrawal: "withdrawal cases",
  returned_cases: "returned cases",
  corporate_services: "CORPORATE SERVICES",
  personal_services: "PERSONAL SERVICES",
  num_applications: "APPLICATIONS",
  withdraweled: "WITHDRAWN CASES TODAY",
  num_app_today: "APPLICATIONS TODAY",
  app_loan_service: "APPLICATION OF {{serviceType}} {{serviceName}} SERVICES",
  credit_card: "credit card",
  home_loan: "home loan",
  car_loan: "car loan",
  cash_loan: "cash loan",
  full_name: "FULL NAME",
  id_type: "ID TYPE",
  nationality: "NATIONALITY",
  national_id: "NATIONAL ID",
  sub_date: "Submition Date",
  sub_time: "Submition Time",
  mob_num: "MOBILE NUMBER",
  other_nationality: "OTHER NATIONALITY",
  emp_status: "EMPLOYER STATUS",
  emp: "EMPLOYER",
  occupation: "OCCUPATION",
  hr_letter: "HR LETTER",
  income_freq: "INCOME FREQUENCY",
  monthly_inc: "MONTHLY INCOME",
  other_inc: "OTHER INCOME SOURCE",
  acount_type: "ACCOUNT TYPE",
  service_type: "SERVICE TYPE",
  type_facility: "TYPE OF FACILITY REQUIRED",
  amount_facility: "AMOUNT OF FACILITY REQUIRED",
  loan_month: "LOAN TENOR PER MONTH",
  reject_case: "REJECT CASE",
  return_case: "RETURN THE CASE TO THE MAKER",
  accept_case: "ACCEPT CASE",
  reject_reason: "rejection reason",
  reject_other: "others",
  reject_comment: "REJECTION COMMENT",
  accept_reason: "ACCEPT REASON",
  maker_status: "MAKER STATUS",
  maker_comment: "MAKER COMMENT",
  approver_return: "APPROVER RETURN COMMENT",
  current_withdrawaled_cases: "CURRENT WITHDRAWN CASES",
  accepted_withdarwaled_cases: "ACCEPTED WITHDRAWN CASES",
  total_withdrawaled_cases: "TOTAL WITHDRAWN CASES",
  no_personal: "NO PERSONAL SERVICE HAS BEEN WITHDRAWAL",
  no_corporate: "NO CORPORATE SERVICE HAS BEEN WITHDRAWAL",
  download_hr_letter: "DOWNLOAD ID COPY",
  comment: "comment",
  reject_requests: "Rejected Cases",
  accepted_requests: "Accepted Cases",
  accepted_requests_by_another: "Accepted Cases By Another Bank",
  withdraw: "withdraw",
};

export const ar = {
  applications: "التطبيقات",
  personal: "الشخصية",
  corporate: "الشركات",
  withdrawal: "حالات السحب",
  returned_cases: "الحالات المرتجعة",
  corporate_services: "خدمات الشركات",
  personal_services: "الخدمات الشخصية",
  num_applications: "التطبيقات",
  withdraweled: "عدد السحوبات اليوم",
  app_loan_service: " تطبيقات خدمات  {{serviceName}} {{serviceType}}",
  num_app_today: "التطبيقات اليوم",
  credit_card: "البطاقة الائتمانية",
  home_loan: "قرض المنزل",
  car_loan: "قرض السيارة",
  cash_loan: "القروض النقدية",
  full_name: "الاسم ",
  id_type: "نوع الرقم",
  nationality: "الجنسية",
  national_id: "الرقم القومي",
  sub_date: "تاريخ التسجيل",
  sub_time: "موعد التسجيل",
  mob_num: "رقم الهاتف",
  other_nationality: "جنسية اخرى",
  emp_status: "حالة صاحب العمل",
  emp: "صاحب العمل",
  occupation: "الحرفة",
  hr_letter: "رسالة الموارد البشرية",
  income_freq: "تردد الدخل",
  monthly_inc: "الدخل الشهري",
  other_inc: "مصدر اخر للدخل",
  acount_type: "نوع الحساب",
  service_type: "نوع الخدمة",
  type_facility: "نوع المشأة المطلوبة",
  amount_facility: "المبلغ المطلوب",
  loan_month: "مدة القرض شهريا",
  reject_case: "رفض الحالة",
  accept_case: "قبول الحالة",
  reject_reason: "سبب الرفض",
  reject_other: "اخرى",
  reject_comment: "علق على الرفض",
  accept_reason: "سبب القبول",
  maker_status: "MAKER STATUS",
  maker_comment: "MAKER COMMENT",
  approver_return: "الموافقة على إرجاع التعليق",
  current_withdrawaled_cases: "حالات الانسحاب الحالية",
  accepted_withdarwaled_cases: "حالات الانسحاب المقبولة",
  total_withdrawaled_cases: "إجمالي حالات الانسحاب",
  no_personal: "لا توجد خدمات شخصية قد تم سحبها",
  no_corporate: "لا توجد خدمات شركات قد تم سحبها",
  return_case: "قم بإعادة الحالة إلى الصانع",
  download_hr_letter: "تحميل ملف نسخه الهويه",
  comment: "التعليق",
  reject_requests: "الحالات المرفوضة",
  accepted_requests: "الحالات المقبولة",
  accepted_requests_by_another: "الحالات المقبولة بواسطة بنك اخر",
  withdraw: "سحب",
};
