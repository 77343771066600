export const en = {
	all: "ALL",
	in_progress: "IN PROGRESS",
	approved: "APPROVED",
	rejected: "REJECTED",
	personal_facility: "PERSONAL FACILITY",
	case_id: "CASE ID",
	widthdrawals: "NUMBER OF WITHDRAWALS",
	type_of_facility: "TYPE OF FACILITY REQUIRED",
	submission: "SUBMISSION DATE&TIME",
	status: "STATUS",
	withdrawal_date_time: "WITHDRAWAL DATE & TIME",
	bank: "BANK",
	approved_amount: "APPROVED AMOUNT",
	loan_tenor: "LOAN TENOR",
	years: "YEARS",
	case_number: "CASE NUMBER",
	customer_full_name: "CUSTOMER FULL NAME",
	amount_of_facility: "AMOUNT OF FACILITY REQUIRED",
	month_sal: "MONTHLY SALARY",
	amount_fac: "AMOUNT OF FACILITY",
	bank_offer: "BANK OFFER",
	contact_bank: "CONTACT BANK",
	edit_request: "EDIT REQUEST",
	cancel_request: "CANCEL REQUEST",
	unfortunately: "UNFORTUNATELY, NO BANK WITHDRAWAL YOUR REQUEST",
	resubmit_your_request: "How ever you can resubmit your request",
	resubmit: "RESUBMIT",
	withdarawal_selected: "Withdarawal selected",
	accept_offer: "Accept offer",
	view_offer: "View offer",
	contact_bank: "contact bank",
	bank_name: "bank name",
	maker_name: "maker name",
	maker_mobile_number: "maker mobile number",
	done_submit_the_request: "done submit the request",
	corporate_services: "CORPORATE SERVICES",
	deactive_service: "this service is deactivated",
	approval_status: "APPROVAL STATUS",
	service_name: "SERVICE NAME",
	lower_service_name: "service name",
	service_type: "service type",
	rejection_reasons: "Rejection Reasons",
	view_reasons: "View Reasons",
	rejection_comment: "Rejection Comment",
};

export const ar = {
	all: "الكل",
	in_progress: "قيد التنفيذ",
	approved: "موافق عليه",
	rejected: "مرفوض",
	personal_facility: "تسهيلات شخصية",
	case_id: "رقم الحالة",
	widthdrawals: "عدد عمليات السحب",
	type_of_facility: "نوع التسهيل المطلوب",
	submission: "تاريخ و وقت التقديم",
	status: "الحالة",
	withdrawal_date_time: "تاريخ و وقت السحب",
	bank: "بنك",
	approved_amount: "المبلغ المعتمد",
	loan_tenor: "مدة القرض",
	years: "YEARS",
	case_number: "رقم الحالة",
	customer_full_name: "الإسم الكامل للعميل",
	amount_of_facility: "مقدار التسهيلات المطلوبة",
	month_sal: "الراتب الشهري",
	amount_fac: "قيمة الخدمة",
	bank_offer: "عرض البنك",
	contact_bank: "بنك الاتصال",
	edit_request: "تعديل الطلب",
	cancel_request: "إلغاء الطلب",
	unfortunately: "لسوء الحظ ، لا يوجد بنك يسحب طلبك",
	resubmit_your_request: "ومع ذلك يمكنك إعادة تقديم طلبك",
	resubmit: "إعادة التقديم",
	withdarawal_selected: "تم تحديد الانسحاب",
	accept_offer: "قبول العرض",
	view_offer: "مشاهدة العرض",
	contact_bank: "اتصل بالبنك",
	bank_name: "اسم البنك",
	maker_name: "اسم الصانع",
	maker_mobile_number: "رقم جوال الصانع",
	done_submit_the_request: "تم تقديم الطلب",
	corporate_services: "خدمات الشركات",
	deactive_service: "هذه الخدمة معطلة",
	approval_status: "حالة الموافقة",
	service_name: "إسم الخدمة",
	lower_service_name: "إسم الخدمة",
	service_type: "نوع الخدمة ",
	rejection_reasons: "اسباب الرفض",
	view_reasons: "عرض الاسباب",
	rejection_comment: "تعليق الرفض",
};
