import React, { Component } from "react";
import styles from "./LatestNews.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";

import { dateTimeFormat } from "utils/dateTimeFormat";

// import moment from "moment";

import { date } from "utils/date";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

import { ShareIcon } from "components";
import { useState } from "react";

class LatestNews extends Component {
  // number of items showed in each breakpoint in the slider in responsive in [Home] page
  state = {
    responsive: {
      0: {
        items: 1,
      },
      769: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  render() {
    return (
      <div className={`${styles.LatestNewsContainer} SectionPadding`}>
        <div className="container">
          <h2 className={`TitleFont SubPadding ${styles.SubTitle}`}>
            <Tr tr="basic:latest_news" />
          </h2>
          <div className="row">
            <div className="col-12 col-md-11">
              {this.props.news && this.props.news.length > 0 && (
                <OwlCarousel
                  className="owl-theme home-proces"
                  responsive={this.state.responsive}
                  margin={30}
                  items={3}
                  // loop
                  // autoplay
                  rewind={true}
                  autoplayHoverPause
                  autoplayTimeout={6000}
                  autoplaySpeed={1000}
                  nav
                  navText={[
                    '<i class="icon-previous" />',
                    '<i class="icon-next" />',
                  ]}
                  onChanged={this.owlOnChange}
                  dots={false}
                >
                  {this.props.news &&
                    this.props.news.map((news) => (
                      <NewsCard news={news} key={news.id} />
                    ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LatestNews;

const NewsCard = ({ news }) => {
  const [show, setshow] = useState(false);
  return (
    <div className="item">
      <div className={styles.Wrapper}>
        <div className={styles.LatestNewsOuter}>
          <div className={styles.LatestNews}>
            <div className={styles.LatestHead}>
              <div className={styles.DateTime}>
                {!show
                  ? dateTimeFormat(news.created_at)
                  : date(news.created_at)}
              </div>
              <ShareIcon
                addtionalClass={"shareIco"}
                id={news.id}
                itemUrl={`news-page-details?id=${news.id}`}
                getDropdownStatus={setshow}
              />
            </div>
            <div className={styles.Tit}>
              <div className={styles.LatestNewsImage}>
                <img
                  src={
                    news.image !== null ? news.image : "../img/latestnews.png"
                  }
                  alt="latest news"
                />
              </div>
              <p className="SubTitle">{news.title}</p>
            </div>
            {/* <p className={`${styles.Desc} DescFont`}>{news.content}</p> */}
            <div
              className={`${styles.Desc} DescFont`}
              dangerouslySetInnerHTML={{ __html: news.content }}
            ></div>
          </div>
          <Link
            to={`/news-page-details?id=${news.id}`}
            className={`Button DescFont ${styles.Button}`}
          >
            {Tr.tr("basic:explore")}
          </Link>
        </div>
      </div>
    </div>
  );
};
