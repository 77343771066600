import React, { useEffect, useState } from "react";
import styles from "./AdDetails.module.scss";
import { usePagination } from "utils/usePagination";
import NewsBox from "components/common/NewsPage/NewsPage";
import { date } from "utils/date";
import Pagination from "components/Pagination";
import { Tr } from "utils/i18n";
import Loader from "components/Loader";
import { loadAdDetails, loadAllAds } from "redux/main/actions";
import { connect } from "react-redux";
import { withLoadMore } from "components/hocs/index";
import { Response } from "utils/Response";
import Slider from "./components/Slider/Slider";

const AdDetails = ({ ...props }) => {
  const [ads, setAds] = useState([]);
  const [adDetails, setadDetails] = useState(null);
  const [response, setResponse] = useState(Response);
  const { page, loading, setLoading, onPaginate } = usePagination();
  const { id } = props.match.params;
  const getAllAds = () => {
    props
      .loadAllAds(page)
      .then((res) => {
        setAds(res.data.data.data);
        setLoading(false);
        setResponse(res.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    props
      .loadAdDetails(id)
      .then((res) => {
        setLoading(false);
        setadDetails(res.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);
  useEffect(() => {
    getAllAds();
  }, [page]);
  return (
    <div className={styles.AdDetails}>
      <Slider data={adDetails} requestPath="/news-page-details" />

      <div className={styles.NewsPageCards}>
        <div className="container">
          {!loading ? (
            <>
              {ads && ads.length > 0 ? (
                <div className="row">
                  {ads.map((ads, index) => (
                    <div className="col-lg-4" key={index}>
                      <NewsBox
                        requestPath={`/ad-details/${ads.id}`}
                        date={date(ads.created_at)}
                        imgSrc={
                          ads.image !== null ? ads.image : "/img/latestnews.png"
                        }
                        desc={ads.content}
                        id={ads.id}
                      />
                    </div>
                  ))}
                  <div className="col-12">
                    <Pagination response={response} onPaginate={onPaginate} />
                  </div>
                </div>
              ) : (
                <div className="noData">{Tr.tr("basic:there_are_no_data")}</div>
              )}
            </>
          ) : (
            <Loader loading={loading} />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { loadAdDetails, loadAllAds })(
  withLoadMore({ loadAction: "loadAllAds" })(AdDetails)
);
