import React, { Component } from "react";
import styles from "./EditCorporateProfile.module.scss";
import { Tr } from "utils/i18n";

import { Link } from "react-router-dom";

import { Formik, ErrorMessage } from "formik";
import { Field } from "components/fields/index";
import AvatarEdite from "components/common/AvatarEdite/AvatarEdite";

import { connect } from "react-redux";
import { loadMyProfile, editMyProfile } from "redux/personalProfile/actions";
import Loader from "../../../../components/Loader";
import ScrollToError from "components/common/ScrollToError";
import Select from "react-select";

const SUCCESS_MESSAGE = Tr.tr("Profile:edit_profile_SUCCESS_MESSAGE");

class EditCorporateProfile extends Component {
  state = {
    profileData: null,
    startDate: null,
    isEditSuccess: false,
    loading: true,
  };

  componentDidMount() {
    this.props.loadMyProfile().then((res) => {
      this.setState({
        profileData: res.data.data,
        loading: false,
      });
    });
  }

  resetState = () => {
    setTimeout(() => this.setState({ isEditSuccess: false }), 3000);
  };

  getUpdatedAvatar = (avatar) => {
    this.setState((prevState) => ({
      profileData: { ...prevState.profileData, avatar },
    }));
  };
  handleChangeInput(name, value, setFieldValue) {
    setFieldValue(name, value);
  }

  // to got to top of the page after submitting data
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const user = this.state.profileData;
    const loading = this.state.loading;
    return !loading ? (
      <>
        <div className="col-lg-4 col-xl-3">
          {/* User Edit Profile Pic */}
          {user && <AvatarEdite getUpdatedAvatar={this.getUpdatedAvatar} />}
        </div>
        <div className="col-lg-8 col-xl-9">
          <div className={styles.EditCorporateProfile}>
            <div className={styles.EditForm}>
              {user && (
                <Formik
                  initialValues={{
                    first_name: user.first_name,
                    middle_name: user.middle_name,
                    last_name: user.last_name,
                    company_name: user.company_name,
                    phone: user.phone,
                    email: user.email,
                    avatar: user.avatar,
                    lang: { label: user.lang === "en" ? "English" : "العربية", value: user.lang },
                  }}
                  validate={(values) => {
                    let errors = {};
                    if (!values.first_name.trim())
                      errors.first_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:first_name"),
                      });
                    if (!values.middle_name.trim())
                      errors.middle_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:middle_name"),
                      });
                    if (!values.last_name.trim())
                      errors.last_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:last_name"),
                      });
                    if (!values.company_name.trim())
                      errors.company_name = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:company_name"),
                      });
                    if (!values.lang)
                      errors.lang = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:pref_lang"),
                      });
                    if (!values.phone.trim())
                      errors.phone = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:contact_person_phone_number"),
                      });
                    else if (values.phone.length < 9) {
                      errors.phone = Tr.tr("Forms:err_min", {
                        num: 9 + Tr.tr("Forms:digit"),
                      });
                    } else if (!/^[0-9]+$/.test(values.phone)) {
                      errors.phone = Tr.tr("Forms:err_invalid_phone");
                    }
                    if (!values.email.trim())
                      errors.email = Tr.tr("Forms:err_requierd", {
                        field: Tr.tr("Forms:company_email"),
                      });
                    else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "INVALID email field .\n";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    let val = { ...values };
                    val.lang = val.lang.value;
                    if (user.avatar.includes("storage")) {
                      val.avatar = user.avatar.split("storage/")[1];
                    } else {
                      val.avatar = user.avatar;
                    }

                    this.props
                      .editMyProfile(val)
                      .then((res) => {
                        this.setState(
                          {
                            errorMessage: SUCCESS_MESSAGE,
                            isEditSuccess: true,
                          },
                          () => {
                            this.scrollUp();
                            this.resetState();
                          }
                        );
                      })
                      .catch((res) => {
                        try {
                          if (res.error.response.data !== undefined) {
                            setErrors(res.error.response.data.data);
                          }
                          this.setState({
                            errorMessage: res.error.response.data.message,
                            isEditSuccess: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      })
                      .finally(() => {
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    setFieldTouched,
                  }) => (
                    <form action="" onSubmit={handleSubmit}>
                      {this.state.isEditSuccess && (
                        <div className={styles.UpdatedSuccessfully}>
                          {this.state.errorMessage}
                        </div>
                      )}
                      <Field
                        name="first_name"
                        label={Tr.tr("Forms:first_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        touched={touched}
                        required
                      />
                      <Field
                        name="middle_name"
                        label={Tr.tr("Forms:middle_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        value={values.middle_name}
                        required
                      />
                      <Field
                        name="last_name"
                        label={Tr.tr("Forms:last_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        touched={touched}
                        required
                      />
                      <Field
                        name="company_name"
                        label={Tr.tr("Forms:company_name")}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company_name}
                        touched={touched}
                        required
                      />
                      <Field
                        name="phone"
                        label={Tr.tr("Forms:contact_person_phone_number")}
                        type="tel"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        touched={touched}
                        hint={Tr.tr("Forms:phone_hint")}
                        required
                        maxLength={15}
                      />
                      <div className="InputGroup">
                        <label htmlFor="" className="Required">
                          {Tr.tr("Forms:pref_lang")}
                        </label>
                        <div className="SelectPicker">
                          <Select
                            onBlur={() => setFieldTouched("lang", true)}
                            onChange={(value) =>
                              this.handleChangeInput(
                                "lang",
                                value,
                                setFieldValue
                              )
                            }
                            name="lang"
                            options={[
                              { label: "العربية", value: "ar" },
                              { label: "English", value: "en" },
                            ]}
                            value={values.lang}
                            isSearchable={false}
                            placeholder={Tr.tr("Forms:select")}
                            autoFocus={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        </div>
                        <div className="err">
                          <ErrorMessage name="lang" />
                        </div>
                      </div>
                      <Field
                        name="email"
                        label={Tr.tr("Forms:company_email")}
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        touched={touched}
                        required
                      />
                      <Link
                        to="/change-password"
                        className={`DescFont ${styles.ChangePass}`}
                      >
                        {Tr.tr("Forms:change_password")}
                      </Link>
                      <div className="LogBtns">
                        <Link to="/profile" className="Button">
                          <Tr tr="Forms:back_to_profile" />
                        </Link>
                        <button
                          disabled={isSubmitting}
                          style={{ opacity: isSubmitting ? 0.8 : 1 }}
                          className="Button White"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <Tr tr="basic:save_changes_load" />
                          ) : (
                            <Tr tr="basic:save_changes" />
                          )}
                        </button>
                      </div>
                      <ScrollToError />
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </>
    ) : (
      <Loader loading={loading} />
    );
  }
}

export default connect(null, { loadMyProfile, editMyProfile })(
  EditCorporateProfile
);
