import React, { Component } from "react";
import { Tr } from "utils/i18n";
import styles from "./AcceptWithdrawal.module.scss";

import { withRouter } from "react-router-dom";

import { Formik, ErrorMessage } from "formik";
import { TextAreaField } from "components/fields/index";
import { acceptWithdrawal } from "redux/profile/actions";
import { connect } from "react-redux";

class AcceptWithdrawal extends Component {
  state = {};

  // Scroll To top after catching errors [400] [Bad Request]
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { history, location } = this.props;
    return (
      <Formik
        initialValues={{ comment: "", withdrawal_id: this.props.withDrawalId }}
        onSubmit={(values) => {
          values.withdrawal_id = parseInt(values.withdrawal_id);
          this.props
            .acceptWithdrawal(values)
            .then((res) => {
              if (location.state && location.state.returned) {
                history.push("/provider-returned");
              } else {
                history.push("/provider-withdrawal");
              }
            })
            .catch((err) => {
              this.props.setMessage(err.error.response.data.message);
              this.scrollUp();
            });
        }}
        validate={(values) => {
          let errors = {};
          if (!values.comment)
            errors.comment = Tr.tr("Forms:err_requierd", {
              field: Tr.tr("serviceProvider:comment"),
            });

          return errors;
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, touched }) => (
          <form action="" onSubmit={handleSubmit}>
            <div className={styles.AcceptCase}>
              <div className="InputGroup">
                <TextAreaField
                  name="comment"
                  label={Tr.tr("serviceProvider:accept_reason")}
                  type="text"
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  required
                />
                <div className="err">
                  <ErrorMessage name="comment" />
                </div>
              </div>
              <button type="submit" className="Button">
                {Tr.tr("Forms:submit")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default connect(null, { acceptWithdrawal })(
  withRouter(AcceptWithdrawal)
);
