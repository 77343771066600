import React, { useEffect, useState } from "react";
import styles from "./RejectRequests.module.scss";
import { Tr } from "utils/i18n";
import { withRouter } from "react-router-dom";

import SideBar from "components/SideBar";
import NumbersCard from "components/common/NumbersCard/NumbersCard";
import { connect } from "react-redux";
import { getServiesRejectedById } from "redux/profile/actions";

import RejectCasesTable from "../RejectRequests/components/RejectCasesTable/RejectCasesTable";

import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";

import Loader from "components/Loader";

import { Response } from "../../utils/Response";
import { usePagination } from "utils/usePagination";
import TableTitle from "components/common/TableTitle/TableTitle";

const RejectCases = ({ ...props }) => {
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const type = props.match.params.type;
  const id = props.match.params.id;
  const [counts, setCounts] = useState([]);

  const [serviceName, setServiceName] = useState("");

  // Loader & Pagination
  const [response, setResponse] = useState(Response);

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  const { page, loading, setLoading, onPaginate } = usePagination();

  // sort
  const [order, setorder] = useState("");
  const [sortType, setsortType] = useState("asc");

  // onSort function
  const onSort = (order, type) => {
    setorder(order);
    setsortType(type);
  };

  const getNewServices = () => {
    props
      .getServiesRejectedById(id, { page, sort: sortType, order })
      .then((result) => {
        if (result.data) {
          setLoading(false);
          setResponse(result.data.data.applications);
          setRows(result.data.data.applications.data);
          setCounts(result.data.data.count);
          setServiceName(result.data.data.service_name);
        }
      })
      .catch((res) => {
        if (res.error) {
          if (res.error.response.data.message) {
            setLoading(false);
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });
  };

  // $2y$10$DminJNqICuRmUexB88CHLeAGMnKcrqAO3QsGGUuRWJapTBizdF4rK
  useEffect(() => {
    getNewServices();
  }, [id, page, sortType, order]);

  return (
    <div className="AddPadding">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!loading ? (
              <>
                {!message ? (
                  <>
                    <div className="row">
                      {/* <div className="col-lg-4">
                        <NumbersCard
                          desc={Tr.tr("serviceProvider:num_applications")}
                          number={counts && counts.all_application_count}
                        />
                      </div> */}
                      <div className="col-lg-4">
                        <NumbersCard
                          desc={Tr.tr("serviceProvider:withdraweled")}
                          number={counts && counts.withdrawal_count}
                        />
                      </div>
                      <div className="col-lg-4">
                        <NumbersCard
                          desc={Tr.tr("serviceProvider:num_app_today")}
                          number={counts && counts.application_today_count}
                        />
                      </div>
                    </div>
                    <TableTitle {...{ serviceName, type }} />
                    <RejectCasesTable
                      rows={rows}
                      reloadData={getNewServices}
                      response={response}
                      onPaginate={onPaginate}
                      loading={loading}
                      {...{ sortType, order, onSort }}
                    />
                  </>
                ) : (
                  <BadRequestMessage
                    message={message}
                    redirectPage="/provider-returned"
                  />
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { getServiesRejectedById })(
  withRouter(RejectCases)
);
