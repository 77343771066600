import React, { Component } from "react";
import styles from "./NewsPageDetails.module.scss";
import PagesHead from "components/common/PagesHead/PagesHead";
import ShareIcon from "components/ui/ShareIcon/ShareIcon";
import { connect } from "react-redux";
import { getNewById } from "redux/news/actions";
import { date } from "utils/date";

class NewsPageDetails extends Component {
  state = {
    newDetails: [],
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const id = params.get("id");
    this.props.getNewById(id).then((res) => {
      this.setState({ newDetails: res.data.data });
    });
  }
  render() {
    let data = this.state.newDetails;

    // call date() from utils to make Date Abbreviations
    let theDate = date(data.created_at);

    return (
      <>
        {/* Here we sent addtionalClass prop if the PagesHead Component contains Image Only */}
        <PagesHead addtionalClass={"MarginTop"} imgSrc={data.image} />

        <div className={styles.NewsPageDetails}>
          <div className="container">
            <div className={`Other ${styles.Date}`}>{theDate}</div>
            <p className={`SubTitle ${styles.Tit}`}>{data.title}</p>
            <ShareIcon
              itemUrl={`news-page-details?id=${data.id}`}
              id={data.id}
              addtionalClass={styles.NewsDetails}
            />
            {/* <div className={`DescFont ${styles.Desc}`}>{data.content}</div> */}
            <div
              className={`DescFont ${styles.Desc}`}
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { getNewById })(NewsPageDetails);
