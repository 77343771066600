import React from "react";
import { Tr } from "utils/i18n";
import { getFileName } from "utils/getFileName";
import moment from "moment";
import { timeFormat } from "utils/dateTimeFormat";

const ApplicationTd = (props) => {
	
	return (
		<>
			<tr>
				<td>{Tr.tr("serviceProvider:sub_date")}</td>
				<td>{moment(props.dateTimeSubmission).locale("en").format("L")}</td>
			</tr>
			<tr>
				<td>{Tr.tr("serviceProvider:sub_time")}</td>
				<td>{timeFormat(props.dateTimeSubmission)}</td>
			</tr>
			{props.rows.map((row, index) => (
				<tr key={index}>
					<td>{row.label}</td>
					<td>
						{row.type === "image" ? (
							<a href={row.value} target="_blank" rel="noopener noreferrer">
								<img src={row.value} width="200" alt="row" />
							</a>
						) : row.type === "file" ? (
							row.value !== "" && (
								<a href={row.value} rel="noopener noreferrer" target="_blank">
									{Tr.tr("basic:download_file")}
								</a>
							)
						) : row.type === "select_multiple" ? (
							JSON.parse(row.value).map((v, index) => (
								<span key={index}>
									{v.label} {index + 1 !== JSON.parse(row.value).length && ","}
								</span>
							))
						) : row.type === "select_dropdown" ? (
							<>
								<span>{JSON.parse(row.value).label}</span>
							</>
						) : row.type === "radio_btn" ? (
							<>
								<span>{row.value.label}</span>
							</>
						) : (
							row.value
						)}
					</td>
				</tr>
			))}
		</>
	);
};

export default ApplicationTd;
