import React, { useState } from "react";
import styles from "./NavItems.module.scss";
import { Tr } from "utils/i18n";
import { Link, NavLink } from "react-router-dom";
import { LanguageDropDown } from "components";
import Modal from "components/common/ModalComponent/ModalComponent";
import { useAuth } from "utils/useAuthHook";
import history from "utils/history";
import { connect } from "react-redux";
import { signOut } from "redux/auth/actions";
const Nav = ({ ...props }) => {
	const [token] = useAuth();
	const [show, setShow] = useState(false);

	const handleShow = () => {
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};

	const logOut = () => {
		props.signOut();
		handleClose();
		props.closeSideBar();
		history.replace("/sign-in");
	};
	return (
		<ul
			className={`${styles.NavItems} ${
				props.responsive ? styles.Responsive : ""
			}`}
		>
			<li className="DescFont">
				<NavLink
					exact
					to="/"
					className={styles.NavLink}
					activeClassName={styles.active}
					onClick={props.closeSideBar}
				>
					<Tr tr="Header:Home" />
				</NavLink>
			</li>
			<li className="DescFont">
				<NavLink
					exact
					to="/about"
					className={styles.NavLink}
					activeClassName={styles.active}
					onClick={props.closeSideBar}
				>
					<Tr tr="Header:about_us" />
				</NavLink>
			</li>
			<li className="DescFont">
				<NavLink
					exact
					to="/our-services"
					className={styles.NavLink}
					activeClassName={styles.active}
					onClick={props.closeSideBar}
				>
					<Tr tr="Header:our_services" />
				</NavLink>
			</li>
			{/* <li className="DescFont">
        <NavLink
          exact
          to="/our-clients"
          className={styles.NavLink}
          activeClassName={styles.active}
          onClick={props.closeSideBar}
        >
          <Tr tr="Header:our_clients" />
        </NavLink>
      </li> */}
			<li className="DescFont">
				<NavLink
					exact
					to="/news-page"
					className={styles.NavLink}
					activeClassName={styles.active}
					onClick={props.closeSideBar}
				>
					<Tr tr="Header:news" />
				</NavLink>
			</li>
			{props.responsive && (
				<li>
					<p className={`${styles.LangTitle}`}>{Tr.tr("basic:language")}</p>
					<LanguageDropDown responsive />
					{/* <LocationDropDown /> */}
				</li>
			)}
			{props.responsive && (
				<li>
					{token ? (
						<div className={styles.SideSign} onClick={handleShow}>
							<span className={styles.Sign}>
								<Tr tr="sign_out" />
							</span>
						</div>
					) : (
						<div className={styles.SideSign}>
							<Link
								to="/sign-in"
								className={styles.Sign}
								onClick={props.closeSideBar}
							>
								<Tr tr="sign_in" />
							</Link>
							<Link
								to="/sign-up"
								className={styles.Sign}
								onClick={props.closeSideBar}
							>
								<Tr tr="sign_up" />
							</Link>
						</div>
					)}
				</li>
			)}
			<Modal show={show} close={handleClose}>
				<span className="TitleFont d-block SignOutTitle">
					<Tr tr="Profile:signout_question" />
				</span>
				<button className="Button ModalLeftButton White" onClick={handleClose}>
					{Tr.tr("basic:cancel")}
				</button>
				<button className="Button ModalRightButton" onClick={logOut}>
					{Tr.tr("basic:sign_out")}
				</button>
			</Modal>
		</ul>
	);
};

export default connect(null, { signOut })(Nav);
