import React, { Component } from "react";
import PropTypes from "prop-types";
import { DEFAULT_LIMIT } from "utils/constants/limits";
import queryString from "query-string";

const initState = {
  data: [],
  offset: 0,
  loading: false,
  loadingMore: false,
  noMoreData: true
};

export function withLoadMore({
  limit = DEFAULT_LIMIT,
  addPropsToAction = () => ({}),
  loadAction = "load"
}) {
  return WrappedComponent => {
    class WithLoadMore extends Component {
      static propTypes = {
        [loadAction]: PropTypes.func.isRequired
      };

      state = { ...initState };

      load = () => {
        let searchQuery = this.props.history
          ? queryString.parse(this.props.history.location.search)
          : {};
        this.setState({ ...initState, loading: true });
        this.props[loadAction]({
          offset: 0,
          limit,
          filters: { ...searchQuery },
          ...addPropsToAction(this.props, searchQuery)
        }).then(res => {
          this.setState({
            data: this.getDataFromResponse(res),
            loading: false,
            offset: limit
          });
        });
      };

      loadMore = () => {
        this.setState({ loadingMore: true });
        let searchQuery = this.props.history
          ? queryString.parse(this.props.history.location.search)
          : {};
        this.props[loadAction]({
          offset: this.state.offset,
          limit,
          ...addPropsToAction(this.props, searchQuery)
        }).then(res => {
          this.setState({
            data: this.state.data.concat(this.getDataFromResponse(res)),
            loadingMore: false,
            offset: this.state.offset + limit
          });
        });
      };

      getDataFromResponse = res => {
        if (res && res.data && res.data.data) {
          if (res.data.data.length !== undefined) {
            if (res.data.data.length < limit) {
              this.setState({ noMoreData: true });
            } else if (this.state.noMoreData) {
              this.setState({ noMoreData: false });
            }
          }
          return res.data.data;
        }
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            dataState={this.state}
            loadData={this.load}
            loadMoreData={this.loadMore}
            defaultLoadMoreBtnProps={{
              show: !this.state.noMoreData,
              loading: this.state.loadingMore,
              onClick: this.loadMore
            }}
          />
        );
      }
    }

    WithLoadMore.displayName = `WithLoadMore(${getDisplayName(
      WrappedComponent
    )})`;

    return WithLoadMore;
  };
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
