import * as T from "./types";

//LOAD MY PROFILE ACTION
export const loadMyProfile = () => ({
  type: T.LOAD_MY_PROFILE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/get-user-by-token`,
    method: "get",
  },
});

// EDIT MY PROFILE
export const editMyProfile = (data) => ({
  type: T.EDIT_MY_PROFILE,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/edit`,
    method: "put",
    data,
  },
});

// Change Password
export const changePassword = (data) => ({
  type: T.CHANGE_PASSWORD,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}website-users/change-password`,
    method: "post",
    data,
  },
});
