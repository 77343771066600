import React, { useState } from "react";
import styles from "./SignUp.module.scss";
import { Tr } from "utils/i18n";

import { Link } from "react-router-dom";

import Personal from "./components/Personal/Personal";
import Corporate from "./components/Corporate/Corporate";
import { USERS_TYPES } from "utils/constants/usersTypes";

const SignUp = ({ ...props }) => {
  // appear SignUp form based on userType personal or corporate
  const [active, setActive] = useState("personal");

  return (
    <div className={`${styles.SignUp} SectionPadding`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div action="" className="position-relative">
              <h1 className="TitleFont SubPadding">
                <Tr tr="Forms:sign_up" />
              </h1>
              <p className={`DescFont ${styles.need}`}>
                <Tr tr="i_need" />
                ...
              </p>
              <div className={styles.Tabs}>
                <div
                  className={`DescFont ${styles.Tab} ${
                    active === USERS_TYPES.Personal ? styles.active : ""
                  }`}
                  onClick={() => setActive(USERS_TYPES.Personal)}
                >
                  <span className={styles.Radio}></span>
                  <Tr tr="Forms:personal_services" />
                </div>
                <div
                  className={`DescFont ${styles.Tab} ${
                    active !== USERS_TYPES.Personal ? styles.active : ""
                  }`}
                  onClick={() => setActive(USERS_TYPES.Corporate)}
                >
                  <span className={styles.Radio}></span>
                  <Tr tr="Forms:corporate_services" />
                </div>
                <Link to="/faq" className={styles.Quis}>
                  <i className="icon-info"></i>
                </Link>
              </div>
              {active === USERS_TYPES.Personal ? <Personal /> : <Corporate />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default SignUp;
