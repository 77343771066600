import React from "react";
import styles from "./ModalComponent.module.scss";
import { Tr } from "utils/i18n";

import Modal from "react-bootstrap/Modal";

const ModalComponent = ({
  children, //props that contains everything that is written in the <Modal>
  show, // boolean prop that setState of show to true (view modal)
  formModal,
  close, //boolean prop that setState of show to false (hide modal)
  withBack, // to tell that there is a back btn
  ...props
}) => {
  return (
    <div className={styles.ModalComponent}>
      <Modal
        show={show}
        onHide={close}
        className={`NotificationModal ${show ? "ModalOpen" : "ModalClosed"} ${
          formModal ? "FormPadding" : "null"
        }`}
      >
        <div className={styles.ModalCard}>
          {children}
          <button className={styles.CloseIcon} onClick={close}>
            <i className={`icon-close ${styles.Close}`} />
          </button>
          {withBack && (
            <button
              className={`Button ModalLeftButton White ${styles.Button}`}
              onClick={close}
            >
              <Tr tr="basic:back" />
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;
