import React, { Component } from "react";
import styles from "./ContactForm.module.scss";
import { Tr } from "utils/i18n";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import Select from "react-select";

import { Formik, ErrorMessage } from "formik";
import { Field, TextAreaField, CheckBoxField } from "components/fields/index";

import { contactUs } from "redux/main/actions";

class ContactForm extends Component {
  state = {
    errorMessage: "",
  };

  onChangeReason(selected, setFieldValue) {
    setFieldValue("reason", selected);
  }

  onChangeCheckedRobot(selected, setFieldValue) {
    setFieldValue("remember", selected);
  }

  // Scroll To top after submmitting
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className={styles.ContactForm}>
        <div className={`SubTitle LatoB ${styles.DirectMsg}`}>
          {Tr.tr("Forms:direct_message")}
        </div>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            reason: "",
            message: "",
            remember: false,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.name.trim())
              errors.name = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:name"),
              });
            if (!values.email.trim())
              errors.email = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:email"),
              });
            else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = Tr.tr("Forms:invalid");
            }
            if (!values.phone.trim())
              errors.phone = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:phone_number"),
              });
            else if (!/^[0-9]{9,15}$/g.test(values.phone)) {
              errors.phone = Tr.tr("Forms:err_invalid_phone");
            }
            if (!values.reason)
              errors.reason = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:reason"),
              });
            if (!values.remember)
              errors.remember = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:not_robot"),
              });
            if (!values.message.trim())
              errors.message = Tr.tr("Forms:err_requierd", {
                field: Tr.tr("Forms:message"),
              });
            console.log(values, errors);

            return errors;
          }}
          onSubmit={(
            values,
            { setSubmitting, setErrors, resetForm, setFieldValue }
          ) => {
            let val = { ...values };
            val.reason = val.reason.value;

            this.props
              .contactUs(val)
              .then((result) => {
                this.setState({ errorMessage: result.response.data.message });
                resetForm({});
                setFieldValue("remember", false);
                this.props.setSuccess(); //props to handle success value in [ContactUs] page
                this.scrollUp();
              })
              .catch((res) => {
                try {
                  if (res.error.response.data !== undefined) {
                    setErrors(res.error.response.data.data);
                  }
                  this.setState({
                    errorMessage: res.error.response.data.message,
                  });
                } catch (err) {
                  console.error(err);
                }
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
          }) => (
            <form action="" onSubmit={handleSubmit}>
              <div className={`GlobalErr DescFont ${styles.ErrMsg}`}>
                {this.state.errorMessage}
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Field
                    name="name"
                    label={Tr.tr("Forms:name")}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    touched={touched}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Field
                    name="email"
                    label={Tr.tr("Forms:email")}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    touched={touched}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Field
                    name="phone"
                    label={Tr.tr("Forms:phone_number")}
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    touched={touched}
                    required
                    maxLength={15}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div className="InputGroup">
                    <label htmlFor="" className="Required">
                      {Tr.tr("Forms:reason")}
                    </label>
                    <Select
                      onBlur={() => setFieldTouched("reason", true)}
                      onChange={(value) =>
                        this.onChangeReason(value, setFieldValue)
                      }
                      name="reason"
                      label={Tr.tr("Forms:reason")}
                      options={[
                        { label: Tr.tr("Forms:complaint"), value: "complaint" },
                        { label: Tr.tr("Forms:inquiry"), value: "inquiry" },
                        {
                          label: Tr.tr("Forms:suggestion"),
                          value: "suggestion",
                        },
                        { label: Tr.tr("Forms:others"), value: "others" },
                      ]}
                      value={values.reason}
                      isSearchable={false}
                      placeholder={Tr.tr("Forms:select")}
                      autoFocus={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      required
                    />
                    <div className="err">
                      <ErrorMessage name="reason" />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="InputGroup">
                    <TextAreaField
                      name="message"
                      label={Tr.tr("Forms:message")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      touched={touched}
                      required
                    />
                    <div className="err">
                      <ErrorMessage name="message" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.BtnsRow}>
                <CheckBoxField
                  name="remember"
                  label={Tr.tr("Forms:not_robot")}
                  type="checkbox"
                  value={values.remember}
                  checked={() =>
                    this.onChangeCheckedRobot(!values.remember, setFieldValue)
                  }
                  onBlur={handleBlur}
                  touched={touched}
                  NotRobot="NotRobot" // send as a props to style the asterisk * in checkbox field
                  required
                />
                <div className="err">
                  <ErrorMessage name="remember" />
                </div>

                <button
                  disabled={isSubmitting}
                  style={{ opacity: isSubmitting ? 0.8 : 1 }}
                  className="Button"
                  type="submit"
                >
                  {isSubmitting ? (
                    <Tr tr="Forms:send..." />
                  ) : (
                    <Tr tr="Forms:send" />
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect(null, { contactUs })(withRouter(ContactForm));
