import React, { useEffect, useState } from "react";
import styles from "./OurClients.module.scss";
import ClientsBox from "components/common/ClientsBox/ClientsBox";
import PagesHead from "components/common/PagesHead/PagesHead";
import { Tr } from "utils/i18n";
import { connect } from "react-redux";
import { loadOurClients } from "redux/ourClients/actions";
import { withLoadMore } from "components/hocs/index";
import { Response } from "../../utils/Response";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { usePagination } from "utils/usePagination";

const OurClients = (props) => {
	const [clients, setClients] = useState([]);

	const [response, setResponse] = useState(Response);

	const { page, loading, setLoading, onPaginate } = usePagination();

	useEffect(() => {
		props
			.loadOurClients("?page=" + page)
			.then((res) => {
				setLoading(false);
				setResponse(res.data.data);
				setClients(res.data.data.data);
			})
			.catch(() => {
				setLoading(false);
			});
	}, [page]);

	return (
		<>
			<PagesHead
				mainTitle={Tr.tr("OurClients:our_clients")}
				paragraph={Tr.tr("OurClients:desc")}
				imgSrc="/img/ourClients.png"
			/>
			<div className="container">
				<div className={styles.AddPadding}>
					{!loading ? (
						<>
							{clients && clients.length > 0 ? (
								<div className="row">
									{/* Loop over Clients card and appear them based on Array Number */}
									{clients.map((client) => (
										<div className="col-6 col-lg-3" key={client.id}>
											<ClientsBox imgSrc={client.logo} />
										</div>
									))}
									<div className="col-12">
										<Pagination response={response} onPaginate={onPaginate} />
									</div>
								</div>
							) : (
								<div className="row">
									<div className="col-12">
										<div className="noData">
											{Tr.tr("basic:there_are_no_data")}
										</div>
									</div>
								</div>
							)}
						</>
					) : (
						<Loader loading={loading} />
					)}
				</div>
			</div>
		</>
	);
};

export default connect(null, { loadOurClients })(
	withLoadMore({
		loadAction: "loadOurClients",
	})(OurClients)
);
