import React, { Component } from "react";
import styles from "./OurTeam.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Tr } from "utils/i18n";
import { withChangeLang } from "components/hocs/index";

export class OurTeam extends Component {
	// Our Team Slider in [About] page
	state = {
		responsive: {
			0: {
				items: 1,
			},
			450: {
				items: 1,
			},
			1000: {
				items: 4,
			},
		},
	};
	render() {
		const { team } = this.props;
		let { currLang } = this.props;
		console.log(currLang);
		return (
			<div className={team.length > 0 && styles.OurTeamSlider}>
				<div className="container">
					{team.length > 0 && (
						<h2 className={`TitleFont ${styles.TitleFont}`}>
							<Tr tr="About:our_team" />
						</h2>
					)}
					<div className={styles.OurTeamSlide}>
						<OwlCarousel
							className="owl-theme"
							// loop
							rewind={true}
							margin={30}
							items={4}
							nav
							navText={[
								'<i class="icon-previous"></i>',
								'<i class="icon-next"></i>',
							]}
							dots={false}
							responsive={this.state.responsive}
							rtl={true}
							startPosition={currLang === "ar" ? -1 : 0}
							// startPosition={-1}
						>
							{team.map((member) => (
								<div key={member.id} className="item">
									<div className={styles.OurTeam}>
										<div className={styles.Img}>
											<img src={member.image} alt="" />
										</div>
										<p className={`SubTitle ${styles.Tit}`}>{member.name}</p>
										<p className={`DescFont ${styles.Desc}`}>
											{member.job_title}
										</p>
									</div>
								</div>
							))}
						</OwlCarousel>
					</div>
				</div>
			</div>
		);
	}
}

export default withChangeLang()(OurTeam);
