import React, { useState } from "react";
import styles from "./Slider.module.scss";
import { ShareIcon } from "components/index";
import { date } from "utils/date";
import { Tr } from "utils/i18n";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";

const Slider = (props) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<div className={styles.NewsPageHeader}>
			<div className={styles.NewsPageBg} />
			<div className="container">
				<div className="row">
					<div className="col-12">
						{props.data && (
							<div className={`${styles.item}`} key={props.data.id}>
								<div className={styles.NewsPageMainImg}>
									<div className={styles.Img}>
										<img
											src={
												props.data.image !== null
													? props.data.image
													: "../img/newspageheader.png"
											}
											alt=""
										/>
										{props.data !== null && props.data.video_url && (
											<button
												href="#"
												className={`Button DescFont ${styles.Play}`}
												onClick={handleShow}
											>
												<i className="icon-play" />
												{Tr.tr("About:play")}
											</button>
										)}
									</div>
								</div>
								<div className={styles.NewsPageContent}>
									<div className="row">
										<div className="col-lg-10 mx-auto">
											<p className={`Other ${styles.Date}`}>
												{date(props.data.created_at)}
											</p>
											<p className={`SubTitle ${styles.Tit}`}>
												{props.data.title}
											</p>
											<div
												className={`DescFont`}
												dangerouslySetInnerHTML={{
													__html: props.data.content,
												}}
											></div>
											<ShareIcon
												itemUrl={`ad-details/${props.data.id}`}
												id={props.data.id}
												addtionalClass={"shareIco"}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{props.data !== null && props.data.video_url && (
					<Modal show={show} onHide={handleClose} className="VideoModal">
						<button className="CloseIcon" onClick={handleClose}>
							<i className="icon-close"></i>
						</button>
						<Modal.Body>
							<iframe src={props.data.video_url} title="youtube video"></iframe>
						</Modal.Body>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default Slider;
