import React from "react";
import { Field } from "./_Field";
import { ErrorMessage } from "formik";

export function DefaultField({
  hint,
  label,
  addClass,
  required,
  invalidPass, // to fix input group style based on this prop
  ...props
}) {
  return (
    <div className={`InputGroup ${invalidPass ? "RemoveMarginBottom" : ""}`}>
      <label htmlFor="" className={`Label ${required ? "Required" : ""}`}>
        {label}
      </label>
      <Field className={"Input " + (addClass ? addClass : "")} {...props} />
      <div className="Hint">{hint}</div>
      <div className="err">
        <ErrorMessage name={props.name} />
      </div>
    </div>
  );
}
