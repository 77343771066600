export const en = {
  all: "ALL",
  services: "SERVICES",
  news: "NEWS",
  offers: "OFFERS",
  RESULTS_FOUND: "RESULTS FOUND",
  results: "RESULTS",
  see_all: "SEE ALL",
  no_results: "no results found",
};

export const ar = {
  all: "الكل",
  services: "الخدمات",
  news: "الاخبار",
  offers: "العروض",
  RESULTS_FOUND: "النتائج",
  results: "نتائج",
  see_all: "عرض الكل",
  no_results: "لا توجد نتائج مطابقة",
};
