import * as T from "./types";
import * as PROFILE_T from "../personalProfile/types";
import { success } from "redux-saga-requests";
import moment from "moment";

let fromStorage = localStorage.getItem("auth");
if (fromStorage) {
  fromStorage = JSON.parse(fromStorage);
  let time = moment(fromStorage.remember);
  let timeNow = moment();
  if (time.isBefore(timeNow)) {
    fromStorage = null;
    localStorage.removeItem("auth");
    localStorage.removeItem("api_token");
  }
}

const INITIAL_STATE = fromStorage || {
  user: null,
  type: "",
  api_token: "",
};
let newState = INITIAL_STATE;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case success(T.LOGIN_WEBSITE_USER):
    case success(PROFILE_T.EDIT_MY_PROFILE):
    case success(PROFILE_T.LOAD_MY_PROFILE):
      let data = JSON.parse(localStorage.getItem("auth"));
      if (data) {
        data.user.avatar = JSON.parse(localStorage.getItem("auth")).user.avatar;
        localStorage.setItem("auth", JSON.stringify(data));
      }
    case success(T.RESET_PASSWORD):
      const user = {
        ...state.user,
        ...action.data.data,
      };

      newState = {
        ...state,
        user: user,
        type: user.type,
        api_token: user.api_token,
        remember:
          action.meta && action.meta.data && action.meta.data.remember
            ? moment().add(30, "days")
            : moment().add(1, "days"),
      };
      localStorage.setItem("auth", JSON.stringify(newState));
      localStorage.setItem("api_token", user.api_token);
      return newState;

    case success(T.CORPORATE_REGISTER):
      const corporateUser = { ...state.user, ...action.data.data };
      newState = {
        ...state,
        user: corporateUser,
        type: "corporate",
        api_token: corporateUser.api_token,
      };
      localStorage.setItem("auth", JSON.stringify(newState));
      localStorage.setItem("api_token", corporateUser.api_token);
      return newState;

    case success(T.PERSONAL_REGISTER):
      const personalUser = { ...state.user, ...action.data.data };
      newState = {
        ...state,
        user: personalUser,
        type: "personal",
        api_token: personalUser.api_token,
      };
      localStorage.setItem("auth", JSON.stringify(newState));
      localStorage.setItem("api_token", personalUser.api_token);
      return newState;

    case T.LOGOUT:
      localStorage.removeItem("api_token");
      localStorage.removeItem("auth");
      return {
        user: null,
        api_token: "",
        type: "",
      };
    default:
      return state;
  }
};
