import React from "react";
import styles from "./Testimonials.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

const Testimonials = ({ ...props }) => {
  return (
    <div className={`${styles.Testimonials} SectionPadding`}>
      <div className="container">
        <h2 className={`TitleFont SubPadding ${styles.SubTitle}`}>
          <Tr tr="basic:testimonials" />
        </h2>
        <div className="row">
          <div className="col-12 col-md-9">
            {props.testimonials && props.testimonials.length && (
              <OwlCarousel
                className="owl-theme home-proces"
                margin={30}
                items={1}
                // loop
                rewind={true}
                autoplay
                autoplayHoverPause
                autoplayTimeout={5000}
                autoplaySpeed={1000}
                nav
                navText={[
                  '<i class="icon-previous" />',
                  '<i class="icon-next" />',
                ]}
                dots={false}
              >
                {/* Loop over carousel items and appear them based on Array Number */}
                {props.testimonials.map((testimonial, index) => (
                  <div className="item" key={index}>
                    <div className={styles.TestiBox}>
                      <img
                        src="../img/qoutes-top.png"
                        className={styles.Qoutes}
                        alt="qoutes"
                      />
                      <p className={`SubTitle ${styles.Tit}`}>
                        {testimonial.testimonial}
                      </p>
                      <img
                        src="../img/qoutes-bottom.png"
                        className={`${styles.Qoutes} ${styles.BottomQoute}`}
                        alt="qoutes"
                      />
                      <div className={styles.UserLine}>
                        <div className={styles.Img}>
                          <img src={testimonial.avatar} alt="avatar" />
                        </div>
                        <div>
                          <div className={`Other ${styles.Name}`}>
                            {testimonial.name}
                          </div>
                          <p className="Font10">{testimonial.job_title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
