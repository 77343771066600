import { useState, useEffect } from "react";
import history from "./history";
import qs from "query-string";

export const usePagination = () => {
  const [page, setPage] = useState(qs.parse(history.location.search).page || 1);

  const [loading, setLoading] = useState(true);

  const searchP = qs.parse(history.location.search);

  const onPaginate = (p) => {
    searchP["page"] = p;
    setLoading(true);
    setPage(p);
    history.push({ search: qs.stringify(searchP) });
  };

  // useEffect(() => {
  //   setPage(qs.parse(history.location.search).page || 1);
  // }, [page]);
  return { page, loading, setLoading, onPaginate };
};
