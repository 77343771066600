import React, { useEffect, useState } from "react";
import styles from "./AcceptWithdrawal.module.scss";
import { Tr } from "utils/i18n";

import { Formik, ErrorMessage } from "formik";
import { Field, TextAreaField } from "components/fields/index";
import {
  loadAcceptApprovalWithdrawalForm,
  approvalAccept,
} from "redux/profile/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

import Loader from "components/Loader";
import ScrollToError from "components/common/ScrollToError";
import ar from "date-fns/locale/ar-SA";
const currLang = localStorage.getItem("i18nextLng");
const AcceptedApprovalWithdrawal = (props) => {
  const serviceId = props.serviceId;
  const [formInitValues, setformInitValues] = useState({});
  const [form, setform] = useState([]);
  const [validation, setvalidation] = useState({});

  // Loader
  const [loading, setLoading] = useState(true);

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  const setInitialValues = (form) => {
    let v = {};
    form.map((input) => {
      v[input.name] = "";
    });
    setformInitValues(v);
  };

  const onChangeSelect = (selected, setFieldValue, name) => {
    let select = name.replace("_select");
    setFieldValue(name, selected);
    setFieldValue(select, selected.value);
  };
  const onChangeUpload = (file, setFieldValue, name) => {
    let fileName = name.replace("_file");
    let data = new FormData();
    data.append("image", file, file.fileName);
    props.uploadFile(data).then((res) => {
      console.log(res.data.data.image_url, fileName);
      setFieldValue(fileName, res.data.data.image_url);
    });
    setFieldValue(name, file);
  };
  const onChangeDate = (selected, setFieldValue, name) => {
    let date = name.replace("_date");
    setFieldValue(name, selected);
    setFieldValue(date, moment(selected).format("YYYY-MM-DD"));
  };
  const onChangeRadio = (selected, setFieldValue, name) => {
    setFieldValue(name, selected);
  };
  const onChangeMultiSelect = (selected, setFieldValue, name) => {
    if (selected) {
      setFieldValue(name, JSON.stringify(selected));
      setFieldValue(name + "_m_select", selected);
    } else {
      setFieldValue(name + "_m_select", undefined);
      setFieldValue(name, "");
    }
  };
  const setValidations = (response) => {
    let v = {};
    response.map((input) => {
      v[input.name] = {
        label: input.label,
        type: input.type,
        validation: input.validations,
      };
    });
    setvalidation(v);
  };
  useEffect(() => {
    props
      .loadAcceptApprovalWithdrawalForm(serviceId)
      .then((result) => {
        if (result.data) {
          if (result.data.data) {
            setLoading(false);
            setInitialValues(result.data.data.form);
            setform(result.data.data.form);
            setValidations(result.data.data.form);
          }
        }
      })
      .catch((err) => {
        props.setMessage(err.error.response.data.message);
        scrollUp();
      });
  }, [serviceId]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={formInitValues}
      validate={(values) => {
        let errors = {};
        let name, valid, type;
        Object.keys(validation).map((key) => {
          name = validation[key].label;
          valid = validation[key].validation;
          type = validation[key].type;
          if (key === "phone_number") {
            if (!/^[0-9]{9,15}$/g.test(values[key])) {
              errors[key] = Tr.tr("Forms:err_invalid_phone");
            }
          }
          if (valid.required && values[key] === "") {
            errors[key] = Tr.tr("Forms:err_requierd", {
              field: name,
            });
          } else if (valid.min && values[key].length < valid.min) {
            errors[key] = Tr.tr("Forms:err_min", {
              num: valid.min,
            });
          } else if (valid.max && values[key].length > valid.max) {
            errors[key] = Tr.tr("Forms:err_max", {
              num: valid.max,
            });
          }
          let pattern = /^\d+$/;
          let is_digit = pattern.test(values[key]);
          if (type === "number" && !is_digit) {
            errors[key] = Tr.tr("Forms:err_digits");
          }
        });

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let value = {};
        Object.keys(values).map((v) => {
          console.log(v);
          if (!v.includes("_m_select")) {
            value[v] = values[v];
          }
        });
        props
          .approvalAccept(props.match.params.id, values)
          .then((res) => {
            props.history.replace("/provider-withdrawal");
          })
          .catch((err) => {
            props.setMessage(err.error.response.data.message);
            scrollUp();
          });
      }}
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        setFieldValue,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.RejectCase}>
            {!loading ? (
              <div className="row">
                {form.map((input) =>
                  (() => {
                    switch (input.type) {
                      case "text":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <Field
                              name={input.name}
                              label={input.label}
                              type="text"
                              onBlur={handleBlur}
                              touched={touched}
                              onChange={handleChange}
                              required
                              maxLength={input.validations.max}
                            />
                          </div>
                        );
                      case "number":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <Field
                              name={input.name}
                              label={input.label}
                              type="number"
                              onBlur={handleBlur}
                              touched={touched}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        );
                      case "email":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <Field
                              name={input.name}
                              label={input.label}
                              type="email"
                              onBlur={handleBlur}
                              touched={touched}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        );
                      case "file":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <label className="InputGroup d-block">
                              <label htmlFor="" className="Required">
                                {input.label}
                              </label>
                              <p className="Input Pointer d-flex align-items-center">
                                <Tr tr="Forms:select" />
                              </p>
                              <div className="d-none">
                                <input
                                  id="file"
                                  name={input.name + "_file"}
                                  id={input.name + "_file"}
                                  type="file"
                                  onChange={(value) => {
                                    onChangeUpload(
                                      value.currentTarget.files[0],
                                      setFieldValue,
                                      input.name
                                    );
                                  }}
                                  className="form-control"
                                />

                                <input
                                  type="hidden"
                                  name={input.name}
                                  id={input.name}
                                  value={values[input.name]}
                                  onBlur={handleBlur}
                                  touched={touched}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="err">
                                <ErrorMessage name={input.name} />
                              </div>
                            </label>
                          </div>
                        );
                      case "date":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <div className="InputGroup">
                              <label htmlFor="" className="Required">
                                {input.label}
                              </label>
                              <div className="DatePicker">
                                <DatePicker
                                  name={input.name + "_date"}
                                  selected={new Date()}
                                  onChange={(value) =>
                                    onChangeDate(
                                      value,
                                      setFieldValue,
                                      input.name
                                    )
                                  }
                                  value={values[input.name + "_date"]}
                                  className="Input"
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText={input.label}
                                  autoComplete="off"
                                  onKeyDown={(e) => e.preventDefault()}
                                  maxDate={new Date().setDate(
                                    new Date().getDate() - 1
                                  )}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={121}
                                  locale={currLang === "ar" && ar}
                                />
                                <i className="icon-calendar"></i>

                                <input
                                  type="hidden"
                                  name={input.name}
                                  id={input.name}
                                  value={values[input.name]}
                                  onBlur={handleBlur}
                                  touched={touched}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="err">
                                <ErrorMessage name={input.name} />
                              </div>
                            </div>
                          </div>
                        );
                      case "radio_btn":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <div className="InputGroup">
                              <label htmlFor="" className="Required">
                                {input.label}
                              </label>
                              <div className="CheckBox">
                                {input.options.map((option, index) => (
                                  <label className="CheckBox" key={index}>
                                    <input
                                      type="radio"
                                      value={option.value}
                                      name={input.name}
                                      onBlur={handleBlur}
                                      touched={touched}
                                      className="radio-input d-none"
                                      onChange={(value) =>
                                        onChangeRadio(
                                          option.value,
                                          setFieldValue,
                                          input.name
                                        )
                                      }
                                    />
                                    <span className="radio-span"></span>
                                    <p className="CheckLabel DescFont">
                                      {option.label}
                                    </p>
                                  </label>
                                ))}
                              </div>
                              <div className="err">
                                <ErrorMessage name={input.name} />
                              </div>
                            </div>
                          </div>
                        );
                      case "select_multiple":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <div className="InputGroup">
                              <label htmlFor="" className="Required">
                                {input.label}
                              </label>
                              <div className="SelectPicker">
                                <Select
                                  onChange={(value) =>
                                    onChangeMultiSelect(
                                      value,
                                      setFieldValue,
                                      input.name
                                    )
                                  }
                                  name={input.name + "_m_select"}
                                  options={input.options}
                                  value={values[input.name + "_m_select"]}
                                  isSearchable={false}
                                  placeholder={input.label}
                                  autoFocus={false}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  isMulti
                                />

                                <input
                                  type="hidden"
                                  name={input.name}
                                  id={input.name}
                                  value={values[input.name]}
                                  onBlur={handleBlur}
                                  touched={touched}
                                  onChange={handleChange}
                                />

                                <div className="err">
                                  <ErrorMessage name={input.name} />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      case "select_dropdown":
                        return (
                          <div className="col-lg-6" key={input.label}>
                            <div className="InputGroup">
                              <label htmlFor="" className="Required">
                                {input.label}
                              </label>
                              <div className="SelectPicker">
                                <Select
                                  onChange={(value) =>
                                    onChangeSelect(
                                      value,
                                      setFieldValue,
                                      input.name
                                    )
                                  }
                                  name={input.name + "_select"}
                                  options={input.options}
                                  value={values[input.name + "_select"]}
                                  isSearchable={false}
                                  placeholder={input.label}
                                  autoFocus={false}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  onBlur={handleBlur}
                                  touched={touched}
                                />
                                <input
                                  type="hidden"
                                  name={input.name}
                                  id={input.name}
                                  value={values[input.name]}
                                  onBlur={handleBlur}
                                  touched={touched}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="err">
                                <ErrorMessage name={input.name} />
                              </div>
                            </div>
                          </div>
                        );
                      case "text_area":
                        return (
                          <div className="col-lg-12" key={input.label}>
                            <div className="InputGroup">
                              <TextAreaField
                                name={input.name}
                                id={input.name}
                                label={input.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[input.name]}
                                touched={touched}
                                required
                              />
                              <div className="err">
                                <ErrorMessage name={input.name} />
                              </div>
                            </div>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })()
                )}
              </div>
            ) : (
              <Loader loading={loading} />
            )}

            <button type="submit" className="Button">
              {Tr.tr("Forms:submit")}
            </button>
          </div>
          <ScrollToError />
        </form>
      )}
    </Formik>
  );
};

export default connect(null, {
  loadAcceptApprovalWithdrawalForm,
  approvalAccept,
})(withRouter(AcceptedApprovalWithdrawal));
