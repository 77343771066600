import React, { Component } from "react";
import styles from "./NewsPageSlider.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ShareIcon from "components/ui/ShareIcon/ShareIcon";
import { Link } from "react-router-dom";
import { Tr } from "utils/i18n";
import { connect } from "react-redux";
import { withLoadMore } from "components/hocs/index";
import { laodNewsSlider } from "redux/news/actions";
import { date } from "utils/date";

class NewsPageSlider extends Component {
  // number of items showed in each breakpoint in the slider in responsive in [NewsPage] page
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    let sliderDetails = this.props.dataState.data,
      theDate = [];

    // call date() from utils to make Date Abbreviations
    sliderDetails.map((_, index) => {
      theDate.push(date(sliderDetails[index].created_at));
    });

    return (
      <>
        <div className={styles.NewsPageHeader}>
          <div className={styles.NewsPageBg} />
          <div className="container">
            <div className="row">
              <div className="col-12">
                {sliderDetails.length && (
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={30}
                    items={1}
                    nav
                    navText={[
                      '<img src="/img/small-nextArrow.png" alt="prev" />',
                      '<img src="/img/small-nextArrow.png" alt="next" />',
                    ]}
                    dots={false}
                    responsive={this.state.responsive}
                  >
                    {sliderDetails &&
                      sliderDetails.map((data, index) => {
                        return (
                          <div className="item" key={data.id}>
                            <div className={styles.NewsPageMainImg}>
                              <div className={styles.Img}>
                                <img
                                  src={
                                    data.image !== null
                                      ? data.image
                                      : "../img/newspageheader.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className={styles.NewsPageButtons}>
                                <Link
                                  to={`${this.props.requestPath}?id=${data.id}`}
                                  className="Button DescFont"
                                >
                                  {Tr.tr("basic:explore")}
                                </Link>
                              </div>
                            </div>
                            <div className={styles.NewsPageContent}>
                              <div className="row">
                                <div className="col-lg-10 mx-auto">
                                  <p className={`Other ${styles.Date}`}>
                                    {theDate[index]}
                                  </p>
                                  <p className={`SubTitle ${styles.Tit}`}>
                                    {data.title}
                                  </p>
                                  <div
                                    className={`DescFont ${styles.Desc}`}
                                    dangerouslySetInnerHTML={{
                                      __html: data.content,
                                    }}
                                  ></div>
                                  <ShareIcon
                                    itemUrl={`news-page-details?id=${data.id}`}
                                    id={data.id}
                                    addtionalClass={"shareIco"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { laodNewsSlider })(
  withLoadMore({ loadAction: "laodNewsSlider" })(NewsPageSlider)
);
