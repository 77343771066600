import React from "react";
import PropTypes from "prop-types";
import { Field } from "./_Field";

function TextAreaCustomFieldComponent({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  hint,
  required,
  ...props
}) {
  return (
    <div className="InputGroup">
      <label htmlFor="" className={`Label ${required ? "Required" : ""}`}>
        {label}
      </label>
      <textarea name={field.name} id={field.name} className="Input" {...props} />
      <div className="Hint">{hint}</div>
    </div>
  );
}

function TextAreaField({ ...props }) {
  return <Field {...props} component={TextAreaCustomFieldComponent} />;
}

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired
};

export { TextAreaField };
