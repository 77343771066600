export const en = {
  BANK_SERVICES: "BANK SERVICES",
  ISLAMIC_SERVICES: "ISLAMIC BANK SERVICES",
  corporate_facility: "corporate facility request",
  submit_personal_facility: "Submit Personal Facility",
  Bank_Offers: "Bank Special Offers",
  Bank_News: "Bank News",
  edit_profile: "edit profile",
  signout_question: "ARE YOU SURE YOU WANT TO SIGN OUT?",
  case_id: "CASE ID",
  download_doc: "DOWNLOAD DOCUMENTS",
  personal_facility: "PERSONAL FACILITY",
  personal_facility_request: "Personal Facility requests",
  loan_serv: "LOAN SERVICES",
  corporate_facility_request: "Corporate Facility requests",
  not_submit_any_facility: "You have not submit any Facility Request.",
  apply_for_personal_facility: "Apply for Personal Facility",
  edit_profile_SUCCESS_MESSAGE: "You have successfully Updated Your Data!",
  APPLIED_SERVICE: "You have applied for this service",
  submit_corporate_facility: "Submit corporate Facility",
  RESUBMITTED_SERVICE: "you have resubmitted this service",
  PERSONAL_PROFILE: "PERSONAL PROFILE",
  PERSONAL_FACILITY: "PERSONAL FACILITY",
  CORPORATE_FACILITY: "CORPORATE FACILITY",
  THERE_ARE_NO_SERVICES: "THERE ARE NO SERVICES",
  VIEW_THE_REQUEST: "VIEW THE REQUEST",
};

export const ar = {
  BANK_SERVICES: "الخدمات البنكية",
  ISLAMIC_SERVICES: "خدمات البنك الاسلامي",
  corporate_facility: "طلبات تسهيلات الشركات",
  submit_personal_facility: "تقديم طلبات التسهيلات الشخصية",
  Bank_Offers: "عروض البنك الخاصة",
  Bank_News: "اخبار البنك",
  edit_profile: "تعديل الصفحة الشخصية",
  signout_question: "هل انت متاكد انك تريد الخروج؟",
  case_id: "CASE ID",
  download_doc: "تحميل ملف",
  personal_facility: "التسهيلات الشخصية",
  personal_facility_request: "طلبات التسهيلات الشخصية",
  loan_serv: "خدمات القروض",
  corporate_facility_request: "طلبات تسهيلات المؤسسة",
  not_submit_any_facility: "لم ترسل أي طلب",
  apply_for_personal_facility: "تقدم بطلب تسهيلات شخصية",
  edit_profile_SUCCESS_MESSAGE: "لقد قمت بتحديث بياناتك بنجاح!",
  APPLIED_SERVICE: "لقد تقدمت بطلب لهذه الخدمة",
  submit_corporate_facility: "تقديم طلبات تسهيلات المؤسسة",
  RESUBMITTED_SERVICE: "لقد قمت بإعادة تقديم هذه الخدمة",
  PERSONAL_PROFILE: "الملف الشخصي",
  PERSONAL_FACILITY: "تسهيلات شخصية",
  CORPORATE_FACILITY: "تسهيلات المؤسسة",
  THERE_ARE_NO_SERVICES: "لا توجد خدمات",
  VIEW_THE_REQUEST: "عرض الطلب",
};
