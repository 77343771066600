import React from "react";
import styles from "./ResetPassword.module.scss";
import { Tr } from "utils/i18n";

import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { Field } from "components/fields/index";
import qs from "query-string";
import { resetPassword } from "redux/auth/actions";

const ResetPassword = ({ ...props }) => {
  const dispatch = useDispatch();
  const { token, type } = qs.parse(props.location.search);
  return (
    <div className={`${styles.ResetPassword} SectionPadding`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <Formik
              initialValues={{ password: "", password_confirmation: "" }}
              validate={(values) => {
                let errors = {};
                if (!values.password) {
                  errors.password = Tr.tr("Forms:err_requierd", {
                    field: Tr.tr("Forms:password"),
                  });
                }
                // else if (values.password.length < 8) {
                //   errors.password = Tr.tr("Forms:minimum_password");
                // }
                else if (
                  !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}$/.test(
                    values.password
                  )
                ) {
                  errors.password = Tr.tr("Forms:INVALID_PASSWORD_CONTENT");
                }

                if (!values.password_confirmation) {
                  errors.password_confirmation = Tr.tr("Forms:err_requierd", {
                    field: Tr.tr("Forms:password_confirmation"),
                  });
                } else if (values.password !== values.password_confirmation) {
                  errors.password_confirmation = Tr.tr("Forms:doesnt_match");
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                dispatch(resetPassword({ ...values, token, type }))
                  .then((res) => {
                    props.history.replace("/");
                  })
                  .catch((err) => {})
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="position-relative">
                  <h1 className="TitleFont SubPadding">
                    <Tr tr="Forms:reset_password" />
                  </h1>
                  <Field
                    name="password"
                    label={Tr.tr("Forms:new_password")}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    touched={touched}
                    required
                  />
                  <Field
                    name="password_confirmation"
                    label={Tr.tr("Forms:confirm_password")}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    touched={touched}
                    required
                  />
                  <div className="LogBtns">
                    <button
                      className="Button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <Tr tr="Forms:send" />
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
