import React from "react";
import { Route } from "react-router-dom";
import SignIn from "pages/SignIn";
import { useAuth } from "utils/useAuthHook";

/**
 * if user auth will render component
 * if not auth will render auth config component
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
export const Auth = ({ component: Component, ...rest }) => {
  let [, token] = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        } else {
          return <SignIn {...props} />;
        }
      }}
    />
  );
};
