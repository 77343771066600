import React from "react";

import SideBar from "components/SideBar";
import RequestTable from "./components/RequestTable/RequestTable";
const PersonalRequestDetails = ({ ...props }) => {
  return (
    <div className={`SectionPadding styles.PersonalRequestDetails`}>
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            <RequestTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalRequestDetails;
