export const en = {
  get_touch: "Get in touch",
  hanks_for_your_submission: "Thanks for your submission",
  desc:
    "We offer a 24/7 safe and positive experiences for our online users and service providers",
};

export const ar = {
  get_touch: "تواصل معنا",
  hanks_for_your_submission: "شكرا لتسجيلك",
  desc:
    "نحن نقدم تجارب آمنة وإيجابية على مدار الساعة طوال أيام الأسبوع لمستخدمينا ومزودي الخدمة عبر الإنترنت",
};
