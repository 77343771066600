import React from "react";
import styles from "./NewsPage.module.scss";
import ShareIcon from "../../ui/ShareIcon/ShareIcon";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
const NewsPage = ({
	date, //date of the box
	imgSrc, //img of the box
	desc, //derscription of the box
	requestPath, //the link that the box will be redirected to
	id,
	...props
}) => {
	return (
		<div className={styles.NewsPageOuter}>
			<div className={styles.NewsPage}>
				<div className={styles.NewsHead}>
					<span className={`Other ${styles.Date}`}>{date}</span>
					{/*add class (VISIBLE) to icon holder to show the icons*/}
					<ShareIcon itemUrl={`news-page-details?id=${id}`} id={id} />
				</div>

				<div className={styles.Img}>
					<img src={imgSrc} alt="news" />
				</div>
				{/* <p className="Other">{desc}</p> */}
				<div className="Other" dangerouslySetInnerHTML={{ __html: desc }}></div>
			</div>

			<Link
				to={requestPath}
				className={`Button White DescFont ${styles.Button}`}
			>
				<Tr tr="basic:explore" />
			</Link>
		</div>
	);
};

NewsPage.defaultProps = {};

NewsPage.propTypes = {};

export default NewsPage;
