import React from "react";
import styles from "./SignDropDown.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { Tr } from "utils/i18n";
import { withChangeLang } from "components/hocs/withChangeLang";

const SignDropDown = ({ ...props }) => {
  return (
    <Dropdown className={styles.SignDropDown}>
      <Dropdown.Toggle as="span" bsPrefix={props.classes.DropBtn}>
        <i className={`icon-independence ${props.classes.NavItem}`}></i>
      </Dropdown.Toggle>

      <Dropdown.Menu alignRight={props.currLang === "ar" ? false : true}>
        <Dropdown.Item as="div" bsPrefix={styles.SignLink} key="0">
          <Link to="/sign-in" className="Other font-weight-bold">
            <Tr tr="sign_in" />
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" bsPrefix={styles.SignLink} key="1">
          <Link to="/sign-up" className="Other font-weight-bold">
            <Tr tr="sign_up" />
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withChangeLang()(SignDropDown);
