import React from "react";
import styles from "./Head.module.scss";

import RequestService from "./components/RequestService/RequestService";
import HeadSlider from "./components/HeadSlider/HeadSlider";

const Head = ({ ...props }) => {
  return (
    <div className={styles.Head}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            <HeadSlider />
          </div>
          <div className="col-12 col-lg-1"></div>
          <div className="col-12 col-lg-4">
            <RequestService />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
