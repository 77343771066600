import React, { useEffect, useState } from "react";
import styles from "./PersonalTable.module.scss";

import Table from "react-bootstrap/Table";
import { Link, withRouter } from "react-router-dom";
import { Tr } from "utils/i18n";
import { connect } from "react-redux";
import { personalProfile } from "../../../../redux/profile/actions";
import moment from "moment";
import { Response } from "utils/Response";

import { trimWhiteSpace } from "utils/trimWhiteSpace";
import Pagination from "../../../../components/Pagination";
import Loader from "../../../../components/Loader";
import NoSubmition from "components/common/NoSubmition/NoSubmition";
import { usePagination } from "utils/usePagination";

import { timeFormat } from "utils/dateTimeFormat";

import { useAuth } from "utils/useAuthHook";

const PersonalTable = ({ ...props }) => {
  const [rows, setRows] = useState([]);
  const [, setMessage] = useState("");
  const [status, setStatus] = useState([]);
  const [activeStatus, setActiveStatus] = useState("all");
  //// pagination
  const [response, setResponse] = useState(Response);

  const { page, loading, setLoading, onPaginate } = usePagination();

  // sort
  const [order, setorder] = useState("");
  const [type, settype] = useState("asc");
  const onSort = (order, type) => {
    setorder(order);
    settype(type);
  };

  let [userType] = useAuth();

  useEffect(() => {
    props
      .personalProfile(
        "?status=" +
          activeStatus +
          "&page=" +
          page +
          `&type=${type}&order=${order}`
      )
      .then((result) => {
        if (result.data) {
          if (result.data.message) {
            setMessage(result.data.message);
          }
          if (result.data.data.records.data) {
            setResponse(result.data.data.records);
            setRows(result.data.data.records.data);
          }
          if (result.data.data.status) {
            setStatus(result.data.data.status);
          }
        }
        setLoading(false);
      })
      .catch((res) => {
        if (res.error) {
          if (res.error.response.data.message) {
            setMessage(res.error.response.data.message);
          }
        }
        setLoading(false);
      });
  }, [activeStatus, page, loading, order, type]);

  if (!loading && !rows) {
    return <NoSubmition />;
  }

  const tableHeader = [
    { label: Tr.tr("Table:case_id"), key: "id" },
    { label: Tr.tr("Table:widthdrawals"), key: "number_of_withdrawals" },
    { label: Tr.tr("Table:service_name"), key: "" },
    { label: Tr.tr("Table:type_of_facility"), key: "service_id" },
    { label: Tr.tr("Table:submission"), key: "created_at" },
    { label: Tr.tr("Table:status"), key: "status" },
  ];

  const sortCondetion = (column) => order === column.key && type === "desc";

  return (
    <>
      <div className={styles.TitleTabs}>
        <div className={`TitleFont ${styles.Title}`}>
          {userType === "personal"
            ? Tr.tr("Table:personal_facility")
            : Tr.tr("Table:corporate_services")}
        </div>
        <div className={`DescFont ${styles.Tabs}`}>
          {status.map((singleStatus) => (
            <span
              key={"status" + singleStatus.slug}
              className={activeStatus === singleStatus.slug ? "Active" : ""}
              onClick={() => {
                setLoading(true);
                setActiveStatus(singleStatus.slug);
              }}
            >
              {singleStatus.label} ({singleStatus.total})
            </span>
          ))}
        </div>
      </div>
      {!loading ? (
        <>
          <div className={styles.PersonalTable}>
            <Table className={styles.Table}>
              <thead>
                <tr>
                  {tableHeader.map((column, index) => {
                    return (
                      <th key={index}>
                        <div
                          onClick={
                            sortCondetion(column)
                              ? () => onSort(column.key, "asc")
                              : () => onSort(column.key, "desc")
                          }
                        >
                          <span>{column.label}</span>
                          {sortCondetion(column) ? (
                            <i className={"arrow_up  icon-dropdown "}></i>
                          ) : (
                            <i className={"icon-dropdown"}></i>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.length ? (
                  rows.map((row) => (
                    <tr key={"request" + row.id}>
                      <td>{row.id}</td>
                      <td>{row.number_of_withdrawals}</td>
                      <td>{row.service.name ? row.service.name : "-"}</td>
                      <td>
                        {row.type_of_facility ? row.type_of_facility : "-"}
                      </td>
                      <td>
                        <div>
                          {moment(row.created_at)
                            .locale("en")
                            .format("YYYY-MM-DD")}
                        </div>
                        <div>{timeFormat(row.created_at)}</div>
                      </td>
                      <td>
                        <div className={styles.ArrowLink}>
                          <div
                            className={`${styles.Status} ${trimWhiteSpace(
                              row.status_key
                            )}`}
                          >
                            <span className={styles.Dot}></span>
                            <span>{row.status}</span>
                          </div>
                          <Link to={`/personal-request-details/${row.id}`}>
                            <i className="icon-dropdown"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className={styles.NoAvailableData}>
                      {Tr.tr("basic:no_available_data")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination response={response} onPaginate={onPaginate} />
          </div>
        </>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};

export default connect(null, { personalProfile })(withRouter(PersonalTable));
