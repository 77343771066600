import React, { useState, useEffect } from "react";
import ServiceCounterCard from "components/common/ServiceCounterCard/ServiceCounterCard";
import styles from "./ApprovalAcceptedRequests.module.scss";
import SideBar from "components/SideBar";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import { connect } from "react-redux";
import { getApprovalAcceptedRequests } from "redux/profile/actions";

const ApprovalAcceptedRequests = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [corporateCount, setCorporateCount] = useState([]);
  const [personalCount, setPersonalCount] = useState([]);

  useEffect(() => {
    props.getApprovalAcceptedRequests().then(({ data: { data } }) => {
      if (data) {
        setLoading(false);
        setCorporateCount(data.corporate);
        setPersonalCount(data.personal);
      }
    });
  }, []);

  return (
    <div className={styles.ProviderApp}>
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!loading ? (
              <>
                {!corporateCount.length && !personalCount.length ? (
                  <p className="noData">
                    {Tr.tr("Profile:THERE_ARE_NO_SERVICES")}
                  </p>
                ) : (
                  <>
                    <div className={styles.WithdrawalSection}>
                      {corporateCount.length > 0 && (
                        <p className={`TitleFont ${styles.Tit}`}>
                          <Tr tr="serviceProvider:corporate_services" />
                        </p>
                      )}
                      <div className="row">
                        {corporateCount.length > 0
                          ? corporateCount.map((card, index) => {
                              return (
                                <div className="col-lg-4" key={card.id}>
                                  <Link
                                    to={`/approval-accepted-cases/${card.id}/corporate`}
                                  >
                                    <ServiceCounterCard
                                      text={card.name}
                                      number={card.total}
                                    />
                                  </Link>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                    <div className={styles.WithdrawalSection}>
                      {personalCount.length > 0 && (
                        <p className={`TitleFont ${styles.Tit}`}>
                          <Tr tr="serviceProvider:personal_services" />
                        </p>
                      )}
                      <div className="row">
                        {personalCount.length > 0
                          ? personalCount.map((card, index) => {
                              return (
                                <div className="col-lg-4" key={card.id}>
                                  <Link
                                    to={`/approval-accepted-cases/${card.id}/personal`}
                                  >
                                    <ServiceCounterCard
                                      text={card.name}
                                      number={card.total}
                                    />
                                  </Link>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Loader loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getApprovalAcceptedRequests })(
  ApprovalAcceptedRequests
);
