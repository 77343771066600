import React, { useState } from "react";
import styles from "./AvatarEdite.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "redux/main/actions";

const AvatarEdite = ({ notEditable, getUpdatedAvatar }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const [imgURL, setImgURL] = useState(authUser.avatar);
  const [uploading, setuploading] = useState(false);
  const imageUploader = (event) => {
    setuploading(true);
    if (event.target.files && event.target.files[0]) {
      const data = new FormData();
      data.append("image", event.target.files[0]);
      dispatch(uploadImage(data)).then(({ response }) => {
        setuploading(false);
        setImgURL(response.data.data.image_url);
        getUpdatedAvatar(response.data.data.avatar);
      });
    }
  };
  return (
    <div className={styles.AvatarEdite}>
      <div className={styles.Wrapper}>
        <div className={styles.Holder}>
          <div className={styles.ProfileImg}>
            <img src={imgURL} className={styles.Img} alt="profile" />
          </div>
          <label
            className={styles.Camera}
            style={{ backgroundColor: uploading ? "#DDD" : "" }}
          >
            <input
              type="file"
              onChange={(event) => imageUploader(event)}
              accept="image/png, image/jpeg, image/jpg"
            ></input>
            {!notEditable && (
              <span>
                <i className={`icon-upload-photo ${styles.UploadIcon}`} />
              </span>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default AvatarEdite;
