import * as T from "./types";

export const loadServices = () => ({
  type: T.LOAD_OUR_SERVICES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}pages/services`,
    method: "get",
  },
});

export const getServiceById = (id) => ({
  type: T.GET_SERVICE_BY_ID,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}pages/services/${id}`,
    method: "get",
  },
});

export const loadApplicationServices = () => ({
  type: T.LOAD_APPLICATION_SERVICES,
  request: {
    url: `${process.env.REACT_APP_API_BASE_URL}providers/services-application`,
    method: "get",
  },
});
