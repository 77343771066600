import React from "react";
import PropTypes from "prop-types";
import styles from "./Page404.module.scss";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
const Page404 = ({ ...props }) => {
  return (
    <>
      <div className="container">
        <div className={styles.Page404}>
          <p className={styles.ErrorNum}>404</p>
          <p className={`TitleFont ${styles.Tit}`}>
            <Tr tr="Page404:PAGE_NOT_FOUND" />
          </p>
          <Link to="/" className="Button DescFont">
            <Tr tr="Page404:BACK_HOME" />
          </Link>
        </div>
      </div>
    </>
  );
};

Page404.defaultProps = {};

Page404.propTypes = {};

export default Page404;
