export const en = {
  about_us: "about us",
  play: "play",
  core_values: "Core values",
  our_team: "Our team",
  title: "Finance at your fingertips",
  description: `Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
  eiusmod tempor incididunt ut labore et dolore roipi magna aliqua.
  Ut enim ad minim veeniam, quis nostruklad exercitation ullamco
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
  dolor in reprehenderit in tufpoy voluptate velit esse cillum
  dolore eu fugiat nulla parieratur. Excepteur sint occaecat
  cupidatat. Culpa qui officia deserunt mollit`,
  our_vision: "OUR VISION",
  our_mission: "OUR MISSION",
  relations: "Relationships",
  accountability: "Accountability",
  confidentiality: "Confidentiality",
  independence: "Independence",
  our_vision_description:
    "Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do eiusmod tempor incididunt ut labore et dolore roipi magna aliqua. Ut enim ad minim veeniam, quis nostruklad exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in tufpoy voluptate velit esse cillum dolore eu fugiat nulla parieratur. Excepteur sint occaecat cupidatat.",
  our_mission_description:
    "Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do eiusmod tempor incididunt ut labore et dolore roipi magna aliqua. Ut enim ad minim veeniam, quis nostruklad exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in tufpoy voluptate velit esse cillum dolore eu fugiat nulla parieratur. Excepteur sint occaecat cupidatat.",
};

export const ar = {
  about_us: "عن الشركة",
  play: "تشغيل",
  core_values: "القيم الأساسية",
  our_team: "فريقنا",
  title: "التمويل في متناول يدك",
  description: `الوصف`,
  our_vision: "رؤيتنا",
  our_mission: "مهمتنا",
  relations: "العلاقات",
  accountability: "مسئولية",
  confidentiality: "سرية",
  independence: "استقلال",
  our_vision_description:
    "هدفنا هو خلق ولاء العملاء وقيمة المساهمين ورضا الموظفين",
  our_mission_description:
    "سنكون المزود المفضل للخدمات المالية المستهدفة في مجتمعاتنا بناءً على علاقات العملاء القوية. سنعزز هذه العلاقات من خلال توفير الحلول الصحيحة التي تجمع بين التكنولوجيا والخبرة والقوة المالية لدينا",
};
