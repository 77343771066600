import React, { useState, useEffect } from "react";
import styles from "../Search.module.scss";
import CreditCard from "components/common/CreditCard/CreditCard";
import NewsBox from "components/common/NewsPage/NewsPage";
import { Tr } from "utils/i18n";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getSearchResults } from "redux/main/actions";
import { date } from "utils/date";
import Loader from "components/Loader";
import { useAuth } from "utils/useAuthHook";
import { UsersOnlyAccess } from "routes/Middleware/AccessControls";
import Offers from "components/common/Offers/Offers";

const AllResults = ({ search, ...props }) => {
  const [results, setresults] = useState(null);
  const [loading, setloading] = useState(false);
  const [allEmpty, setallEmpty] = useState(false);

  let [userType] = useAuth();

  useEffect(() => {
    setloading(true);
    props.getSearchResults(search).then((res) => {
      setloading(false);
      setresults(res.data.data);
      if (
        res.data.data &&
        !res.data.data.services.personal.length &&
        !res.data.data.services.corporate.length &&
        !res.data.data.news.length &&
        !res.data.data.offers.length
      ) {
        setallEmpty(true);
      } else {
        setallEmpty(false);
      }
    });
  }, [search]);

  if (allEmpty) {
    return (
      <div className="container">
        <div className={styles.NoResults}>{Tr.tr("Search:no_results")}</div>
      </div>
    );
  } else {
    return (
      <div className="container">
        {/* OUR SERVICES SECTION */}
        {!loading ? (
          <>
            <div className={styles.SectionPadding}>
              <div className={styles.Tit}>
                <p className={`TitleFont ${styles.TitleFont}`}>
                  <Tr tr="Search:services" />
                </p>
                <Link
                  className={`DescFont ${styles.SeeAll}`}
                  to={`/search/services?search=${search}`}
                >
                  <Tr tr="Search:see_all" />
                </Link>
              </div>
              {userType === "personal" ? (
                <div className={styles.PersonalServices}>
                  <p className={`TitleFont ${styles.Title}`}>
                    {Tr.tr("basic:personal")}
                  </p>
                  <div className="row">
                    {!loading ? (
                      <>
                        {results && results.services.personal.length ? (
                          results.services.personal.map((service, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-4"
                                key={index}
                              >
                                <CreditCard
                                  key={index}
                                  requestPath={`/profile-services/${service.id}`}
                                  detailsPath={`/profile-services/${service.id}`}
                                  imgSrc={service.image}
                                  title={service.name}
                                  desc={service.description}
                                  isApplied={service.is_applied}
                                  userServiceId={service.user_service_id}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div className="col-12">
                            <div className={styles.NoResultsFound}>
                              {Tr.tr("Search:no_results")}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader loading={loading} />
                    )}
                  </div>
                </div>
              ) : userType === "corporate" ? (
                <div className={styles.CorporateServices}>
                  <p className={`TitleFont ${styles.Title}`}>
                    {Tr.tr("basic:corporate")}
                  </p>
                  <div className="row">
                    {!loading ? (
                      <>
                        {results && results.services.corporate.length ? (
                          results.services.corporate.map((service, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-4"
                                key={index}
                              >
                                <CreditCard
                                  key={index}
                                  requestPath={`/profile-services/${service.id}`}
                                  detailsPath={`/profile-services/${service.id}`}
                                  imgSrc={service.image}
                                  title={service.name}
                                  desc={service.description}
                                  isApplied={service.is_applied}
                                  userServiceId={service.user_service_id}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div className="col-12">
                            <div className={styles.NoResultsFound}>
                              {Tr.tr("basic:no_results_found")}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader loading={loading} />
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.PersonalServices}>
                    <p className={`TitleFont ${styles.Title}`}>
                      {Tr.tr("basic:personal")}
                    </p>
                    <div className="row">
                      {/* show corporate services cards based on Arary Number */}
                      {!loading ? (
                        <>
                          {results && results.services.personal.length ? (
                            results.services.personal.map((service, index) => {
                              return (
                                <div
                                  className="col-12 col-md-6 col-lg-4"
                                  key={index}
                                >
                                  <CreditCard
                                    key={index}
                                    requestPath={`/profile-services/${service.id}`}
                                    detailsPath={`/profile-services/${service.id}`}
                                    imgSrc={service.image}
                                    title={service.name}
                                    desc={service.description}
                                    isApplied={service.is_applied}
                                    userServiceId={service.user_service_id}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-12">
                              <div className={styles.NoResultsFound}>
                                {Tr.tr("Search:no_results")}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader loading={loading} />
                      )}
                    </div>
                  </div>
                  <div className={styles.CorporateServices}>
                    <p className={`TitleFont ${styles.Title}`}>
                      {Tr.tr("basic:corporate")}
                    </p>
                    <div className="row">
                      {/* show corporate services cards based on Arary Number */}
                      {!loading ? (
                        <>
                          {results && results.services.corporate.length ? (
                            results.services.corporate.map((service, index) => {
                              return (
                                <div
                                  className="col-12 col-md-6 col-lg-4"
                                  key={index}
                                >
                                  <CreditCard
                                    key={index}
                                    requestPath={`/profile-services/${service.id}`}
                                    detailsPath={`/profile-services/${service.id}`}
                                    imgSrc={service.image}
                                    title={service.name}
                                    desc={service.description}
                                    isApplied={service.is_applied}
                                    userServiceId={service.user_service_id}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-12">
                              <div className={styles.NoResultsFound}>
                                {Tr.tr("Search:no_results")}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader loading={loading} />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* NEWS PAGE SECTION */}
            <div className={styles.SectionPadding}>
              <div className={styles.Tit}>
                <p className={`TitleFont ${styles.TitleFont}`}>
                  <Tr tr="Search:news" />
                </p>
                <Link
                  className={`DescFont ${styles.SeeAll}`}
                  to={`/search/news?search=${search}`}
                >
                  <Tr tr="Search:see_all" />
                </Link>
              </div>
              <div className="row">
                {/* show corporate services cards based on Arary Number */}
                {!loading ? (
                  <>
                    {results && results.news && results.news.length ? (
                      results.news.map((item, index) => {
                        return (
                          <div className="col-12 col-md-6 col-lg-4" key={index}>
                            <NewsBox
                              key={index}
                              requestPath={`/news-page-details?id=${item.id}`}
                              date={date(item.created_at)}
                              imgSrc={item.image}
                              desc={item.title}
                              id={item.id}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12">
                        <div className={styles.NoResultsFound}>
                          {Tr.tr("Search:no_results")}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Loader loading={loading} />
                )}
              </div>
            </div>

            {/* OFFERS SECTION  */}
            <UsersOnlyAccess hide>
              <div className={styles.SectionPadding}>
                <div className={styles.Tit}>
                  <p className={`TitleFont ${styles.TitleFont}`}>
                    <Tr tr="Search:offers" />
                  </p>
                  <Link
                    className={`DescFont ${styles.SeeAll}`}
                    to={`/search/offers?search=${search}`}
                  >
                    <Tr tr="Search:see_all" />
                  </Link>
                </div>
                <div className="row">
                  {!loading ? (
                    <>
                      {results && results.offers && results.offers.length ? (
                        results.offers.map((offer, index) => {
                          return (
                            <div
                              className="col-12 col-md-6 col-lg-4"
                              key={index}
                            >
                              <Offers
                                key={index}
                                imgSrc={offer.bank.logo}
                                name={offer.title}
                                // number="100.000"
                                // percentage="1%"
                                title={offer.title}
                                desc={offer.description}
                                requestPath={`/profile-bank-details?id=${offer.id}`}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12">
                          <div className={styles.NoResultsFound}>
                            {Tr.tr("Search:no_results")}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader loading={loading} />
                  )}
                </div>
              </div>
            </UsersOnlyAccess>
          </>
        ) : (
          <Loader loading={loading} />
        )}
      </div>
    );
  }
};
export default connect(null, { getSearchResults })(AllResults);
