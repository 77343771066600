import { ar as basic } from "./namespaces/basic";
import { ar as Header } from "./namespaces/Header";
import { ar as countries } from "./namespaces/countries";
import { ar as OurServices } from "./namespaces/OurServices";
import { ar as Search } from "./namespaces/Search";
import { ar as Page404 } from "./namespaces/Page404";
import { ar as Page500 } from "./namespaces/Page500";
import { ar as faq } from "./namespaces/faq";
import { ar as About } from "./namespaces/About";
import { ar as OurClients } from "./namespaces/OurClients";
import { ar as ServicesDetails } from "./namespaces/ServicesDetails";
import { ar as PrivacyPolicy } from "./namespaces/PrivacyPolicy";
import { ar as TermsConditions } from "./namespaces/TermsConditions";
import { ar as Footer } from "./namespaces/Footer";
import { ar as Home } from "./namespaces/Home";
import { ar as ContactUs } from "./namespaces/ContactUs";
import { ar as Forms } from "./namespaces/Forms";
import { ar as Profile } from "./namespaces/Profile";
import { ar as Table } from "./namespaces/Table";
import { ar as serviceProvider } from "./namespaces/serviceProvider";
import { ar as OurTeams } from "./namespaces/OurTeams";

export default {
  basic,
  Header,
  countries,
  About,
  OurServices,
  Search,
  Page404,
  Page500,
  faq,
  OurClients,
  ServicesDetails,
  PrivacyPolicy,
  TermsConditions,
  Footer,
  Home,
  ContactUs,
  Profile,
  Table,
  serviceProvider,
  Forms,
  Profile,
  OurTeams,
};
