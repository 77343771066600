// ESSENTIALS
import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import ScrollToTop from "react-router-scroll-top";
// COMPONENTS
import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";
import { Page500 } from "pages/index";

// ROUTES
import Routes from "./routes";

// STYLES
import "App.scss";

// UTILITIES
import history from "utils/history";

// STORE
const store = configureStore();

class App extends Component {
  componentDidMount() {
    window.addEventListener("offline", () => {
      history.push("/page500");
    });
    window.addEventListener("online", () => {
      history.goBack();
    });

    !localStorage.getItem("i18nextLng") && localStorage.setItem("i18nextLng", "ar");
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === "production") window.location = "/page500";
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop>
            <React.Fragment>
              <Route
                path="/"
                render={() => (
                  <>
                    <Switch>
                      <Route exact strict path="/page500" component={Page500} />
                      <>
                        <Header />
                        <div className="main">
                          <Routes />
                        </div>
                        <Footer />
                      </>
                    </Switch>
                  </>
                )}
              />
            </React.Fragment>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
