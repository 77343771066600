import React, { useEffect, useState } from "react";
import styles from "./ProviderSelectService.module.scss";
import { Tr } from "utils/i18n";
import { withRouter } from "react-router-dom";

import SideBar from "components/SideBar";
import NumbersCard from "components/common/NumbersCard/NumbersCard";
import ServiceTable from "./components/ServiceTable/ServiceTable";
import { connect } from "react-redux";
import { getNewServicesForMaker } from "redux/profile/actions";
import { loadServices } from "redux/ourServices/actions";

import { Response } from "../../utils/Response";
import { usePagination } from "utils/usePagination";

import BadRequestMessage from "../../components/common/BadRequestMessage/BadRequestMessage";
import TableTitle from "components/common/TableTitle/TableTitle";

const ProviderSelectService = ({ ...props }) => {
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);
  const [type] = useState(props.match.params.type);
  const [id] = useState(props.match.params.id);
  const [counts, setCounts] = useState([]);
  const [serviceName, setServiceName] = useState("");
  // Loader & Pagination
  const [response, setResponse] = useState(Response);

  const { page, loading, setLoading, onPaginate } = usePagination();

  // Scroll To top after catching errors [400] [Bad Request]
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  // sort
  const [order, setorder] = useState("");
  const [sortType, setsortType] = useState("asc");

  // onSort function
  const onSort = (order, type) => {
    setorder(order);
    setsortType(type);
  };

  const getNewServices = () => {
    props
      .getNewServicesForMaker(id, { page, type: sortType, order })
      .then((result) => {
        if (result.data) {
          
          setLoading(false);
          setResponse(result.data.data.applications);
          setRows(result.data.data.applications.data);
          setCounts(result.data.data.count);
          setServiceName(result.data.data.service_name);
        }
      })
      .catch((res) => {
        if (res.error) {
          if (res.error.response.data.message) {
            setLoading(false);
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });
  };

  const load = () => {
    props
      .loadServices()
      .then((result) => {
        if (result.data.data[type]) {
          setServices(result.data.data[type]);
        }
      })
      .catch((res) => {
        console.log(res.error);
        if (res.error) {
          if (res.error.response.data.message) {
            setMessage(res.error.response.data.message);
            scrollUp();
          }
        }
      });
  };
  // $2y$10$DminJNqICuRmUexB88CHLeAGMnKcrqAO3QsGGUuRWJapTBizdF4rK
  useEffect(() => {
    getNewServices();
    load();
  }, [id, page, sortType, order]);

  return (
    <div className="AddPadding">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-lg-9">
            {!message ? (
              <>
                <div className="row">
                  {/* <div className="col-lg-4">
                    <NumbersCard
                      desc={Tr.tr("serviceProvider:num_applications")}
                      number={counts && counts.all_application_count}
                    />
                  </div> */}
                  <div className="col-lg-4">
                    <NumbersCard
                      desc={Tr.tr("serviceProvider:withdraweled")}
                      number={counts && counts.withdrawal_count}
                    />
                  </div>
                  <div className="col-lg-4">
                    <NumbersCard
                      desc={Tr.tr("serviceProvider:num_app_today")}
                      number={counts && counts.application_today_count}
                    />
                  </div>
                </div>
                <TableTitle {...{ serviceName, type }} />

                <ServiceTable
                  rows={rows}
                  reloadData={getNewServices}
                  response={response}
                  onPaginate={onPaginate}
                  loading={loading}
                  order={order}
                  type={sortType}
                  onSort={onSort}
                  setMessage={setMessage}
                />
              </>
            ) : (
              <BadRequestMessage
                message={message}
                redirectPage="/provider-app"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProviderSelectService.defaultProps = {};

ProviderSelectService.propTypes = {};

export default connect(null, { getNewServicesForMaker, loadServices })(
  withRouter(ProviderSelectService)
);
