import React from "react";
import { Route } from "react-router-dom";
import SignIn from "pages/SignIn";
import Home from "pages/Home";
import { useAuth } from "utils/useAuthHook";
import { USERS_TYPES } from "utils/constants/usersTypes";

/**
 * if user authh && type == 'personal'  will render component
 * if not auth will render auth config component
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
export const Users = ({ component: Component, ...rest }) => {
  let [userType] = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userType) {
          if (
            userType === USERS_TYPES.Personal ||
            userType === USERS_TYPES.Corporate
          ) {
            return <Component {...props} />;
          } else {
            return <Home {...props} />;
          }
        } else {
          return <SignIn {...props} />;
        }
      }}
    />
  );
};
