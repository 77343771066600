import React from 'react';


const Pagination = props => {

    const pages = []
    const minPage = props.response.current_page - 4;
    const maxPage = props.response.current_page + 4;

    if (props.response.last_page > 1) {

        pages.push(
            <li key={"first_page"} className={(1 === props.response.current_page ? " disabled " : '') + "  page-item"}>
                <span onClick={() => props.onPaginate(1)} className="page-link">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                </span>
            </li>
        )

        if (props.response.current_page > 4) {
            pages.push(
                <li key={"dot_prev"} className="page-item">
                    <span className="page-link">..</span>
                </li>
            )
        }

        for (let i = 1; i <= props.response.last_page; i++) {
            if (i >= minPage && i <= maxPage) {
                pages.push(
                    <li key={"page_" + i}
                        className={(i === props.response.current_page ? " active disabled " : '') + " page-item"}>
                        <span onClick={() => props.onPaginate(i)} className="page-link">{i}</span>
                    </li>
                )
            }
        }

        if (props.response.last_page > maxPage) {
            pages.push(
                <li key={"dot_next"} className="page-item">
                    <span className="page-link">..</span>
                </li>
            )
        }

        pages.push(
            <li key={"last_page"}
                className={(props.response.last_page === props.response.current_page ? " disabled " : '') + "  page-item"}>
                <span onClick={() => props.onPaginate(props.response.last_page)} className="page-link">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                </span>
            </li>
        )
    }

    return (
        <div>
            <ul className="pagination">
                {pages}
            </ul>
        </div>
    )

};

Pagination.propTypes = {};

export default Pagination;