export const en = {
  dir: "ltr",
  sign_in: "SIGN IN",
  sign_in_load: "SIGN IN...",
  sign_up: "SIGN UP",
  sign_up_load: "SIGN UP...",
  sign_out: "SIGN OUT",
  select: "Select",
  send: "SEND",
  submit: "Submit",
  email_confirmed: "Email Confirmed",
  you_will_receive_an_email_shortly: "You will receive an email shortly",
  wrong_email_or_password: "Wrong Email Or Password",
  please_confirm_the_sent_email: "Please confirm the sent email",
  email: "E-Mail",
  password: "Password",
  forget_password: "Forgot Password",
  dont_have_account: "Don't Have Account",
  remember_me: "Remember Me",
  personal_services: "Personal Services",
  corporate_services: "Corporate Services",
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  company_name: "Company Name",
  company_email: "Company E-Mail",
  contact_person_phone_number: "Contact person phone number",
  contact_person_email: "Contact person E-Mail",
  contact_person_name: "Contact person name",
  confirm_password: "Confirm Password",
  new_password: "New Password",
  not_robot: "I’M NOT A ROBOT",
  date_picker: "Date Of Birth",
  gender: "Gender",
  contact_soon: "El Momawel team will contact you As Soon As Possible ",
  phone_number: "Phone Number",
  request_call: "Request A Call",
  request_submitted: "Request submitted",
  request_add: "Request Ads",
  call_reason: "Call Reason",
  required_time: "Required Time",
  preferred_time_for_the_call: "Preferred Time For The Call",
  other_reason: "Other Reason",
  requested_ad_location: "Requested AD Location",
  message: "Message",
  have_an_account: "Already have an account",
  change_password: "CHANGE PASSWORD",
  back_to_profile: "BACK TO PROFILE",
  current_password: "Current Password",
  confirm_new_password: "Confirm New Password",
  are_you_forget_password: "Forgot password?",
  direct_message: "Direct message",
  name: "Name",
  reason: "Reason",
  full_name: "FULL NAME",
  date_birth: "DATE OF BIRTH",
  nationality: "NATIONALITY",
  id_type: "ID TYPE",
  national_id: "NATIONAL ID NUMBER",
  other_nation: "Other Nationality",
  income_freq: "INCOME FREQUENCY",
  monthly_inc: "MONTHLY INCOME",
  other_inc: "OTHER INCOME SOURCE",
  emp_status: "EMPLOYER STATUS",
  employer: "EMPLOYER",
  occupation: "OCCUPATION",
  hr_letter: "UPLOAD HR LETTER",
  upload_img: "UPLOAD IMAGE",
  upload_doc: "UPLOAD DOCUMENTS",
  are_you_forget_password: "Forgot password?",
  loan_tenor: "LOAN TENOR PER MONTH",
  type_facility: "TYPE OF FACILITY REQUIRED",
  amount_facility: "AMOUNT OF FACILITY REQUIRED",
  confirm_cancel: "CONFIRM CANCELATION",
  close_form: "ARE YOU SURE YOU WANT TO CLOSE THE FORM",
  done: "DONE",
  reset_password: "RESET PASSWORD",
  cancel_request_question: "ARE YOU SURE YOU WANT TO CANCEL THE REQUEST",
  password_confirmation: "password confirmation",
  birth_date: "Birth Date",
  password_hint:
    "Password content should be at least: one lowercase, one uppercase, one number",
  phone_hint: "phone 01111157945",
  err_requierd: `The {{field}} field is required.\n`,
  doesnt_match: "The password confirmation does not match..\n",
  invalid: "INVALID email field .\n",
  minimum_password: "minimum password is 8 characters",
  err_min: `This field min {{num}} .\n`,
  err_max: `This field max {{num}} .\n`,
  err_digits: "This field  must be digit .\n",
  err_files: "You must upload a file .\n",
  allow: "Allow",
  complaint: "Complaint",
  inquiry: "Inquiry",
  suggestion: "Suggestion",
  others: "Others",
  err_invalid_phone: "INVALID phone number .\n",
  INVALID_PASSWORD_CONTENT:
    "Password content should be at least: one lowercase, one uppercase, one number",
  UPLOADING: "Uploading...",
  ACCEPT: "ACCEPT",
  TERMS_CONDITIONS_POLICY: "TERMS & CONDITIONS & POLICY",
  UPLOAD: "Upload",
  digit: " digit",
  TOO_LARGE: "This file is too large",
  pref_lang: "Preferred Language",
  language: "language",
};

export const ar = {
  dir: "rtl",
  sign_in: "تسجيل الدخول",
  sign_in_load: "تسجيل الدخول...",
  sign_up: "تسجيل حساب",
  sign_up_load: "تسجيل حساب...",
  sign_out: "تسجيل الخروج",
  select: "اختار",
  send: "إرسال",
  submit: "موافقة",
  email_confirmed: "تأكيد البريد الإلكترونى",
  you_will_receive_an_email_shortly: "سوف يصلك بريد الكتروني قريبا ",
  wrong_email_or_password: "البريد الإلكترونى أو كلمة المرور خاطئة",
  please_confirm_the_sent_email: "Please confirm the sent email",
  email: "البريد الالكتروني",
  password: "كلمة المرور",
  forget_password: "هل نسيت كلمة المرور",
  dont_have_account: "ليس لديك حساب",
  remember_me: "تذكرني",
  personal_services: "الخدمات الشخصية",
  corporate_services: "خدمات الشركات",
  first_name: "الاسم الاول",
  middle_name: "الاسم الاوسط",
  last_name: "الاسم الاخير",
  company_name: "إسم الشركة",
  company_email: "البريد الإلكترونى للشركة",
  contact_person_phone_number: "Contact person phone number",
  contact_person_email: "Contact person E-Mail",
  contact_person_name: "Contact person name",
  confirm_password: "تأكيد كلمة المرور",
  new_password: "كلمة المرور الجديدة",
  not_robot: "أنا لست روبوت",
  date_picker: "تاريخ الميلاد",
  gender: "النوع",
  contact_soon: "فريق الممول سوف يتصل بك في اقرب فرصة ممكنة ",
  phone_number: "رقم الهاتف",
  request_call: "طلب مكالمة",
  request_submitted: "طلب تسجيل",
  request_add: "طلب اعلان",
  call_reason: "سبب المكالمة",
  required_time: "الوقت المطلوب",
  preferred_time_for_the_call: "الوقت المفضل للمكالمة",
  other_reason: "سبب اخر",
  requested_ad_location: "Requested AD Location",
  message: "الرسالة",
  have_an_account: "لديك حساب بالفعل",
  change_password: "تغيير كلمة المرور",
  back_to_profile: "الرجوع إلى الصفحة الشخصية",
  current_password: "كلمة المرور الحالية",
  confirm_new_password: "تأكيد كلمة المرور الجديدة",
  contact_person_phone_number: "رقم التواصل",
  are_you_forget_password: "هل نسيت كلمة المرور؟",
  direct_message: "رسالة مباشرة",
  name: "الإسم",
  reason: "السبب",
  full_name: "الاسم بالكامل",
  date_birth: "تاريخ الميلاد",
  nationality: "الجنسية",
  id_type: "ID TYPE",
  national_id: "الرقم القومي",
  other_nation: "جنسية اخرى",
  income_freq: "تردد الدخل",
  monthly_inc: "الدخل الشهري",
  other_inc: "مصدر اخر للدخل",
  emp_status: "حالة الموظف",
  employer: "الموظف",
  occupation: "OCCUPATION",
  hr_letter: "رسالة الموارد البشرية",
  upload_img: "رفع صورة",
  upload_doc: "رفع ملفات",
  are_you_forget_password: "هل نسيت كلمة المرور؟",
  loan_tenor: "مدة القرض شهريا",
  type_facility: "نوع الخدمة المطلوبة",
  amount_facility: "مقدار الخدمو المطلوبة",
  confirm_cancel: "تأكيد إلغاء",
  close_form: "هل انت متاكد انك تريد اغلاق النموذج؟",
  done: "تم",
  reset_password: "انشاء كلمة مرور جديدة",
  cancel_request_question: "هل أنت متأكد من أنك تريد إلغاء الطلب",
  password_confirmation: "تأكيد كلمة المرور",
  birth_date: "تاريخ الميلاد",
  password_hint:
    "محتوى كلمة المرور على الأقل حرف واحد صغير ، حرف واحد كبير ، رقم واحد",
  phone_hint: "رقم الهاتف 01111157945",
  err_requierd: `حقل {{field}} مطلوب.\n`,

  doesnt_match: "حقل غير متطابق",
  invalid: "هذا البريد الالكتروني غير صحيح",
  minimum_password: "الحد الأدنى لكلمة المرور هو 8 أحرف",
  err_min: `الحد الادني لهذا الحقل {{num}} .\n`,
  err_max: `الحد الأقصي لهذا الحقل {{num}} .\n`,
  err_digits: "هذا الحقل يجب ان يحتوي علي ارقام فقط",
  err_files: "يجب ارفاق ملف .\n",
  allow: "مسموح بـ",
  complaint: "شكوي",
  inquiry: "تحقيق",
  suggestion: "اقتراح",
  others: "اخري",
  err_invalid_phone: "رقم هاتف غير صحيح.\n",
  INVALID_PASSWORD_CONTENT:
    "يجب أن يكون محتوى كلمة المرور على الأقل: حرف صغير واحد ، حرف كبير واحد ، ورقم",
  UPLOADING: "تحميل...",
  ACCEPT: "الموافقة على",
  TERMS_CONDITIONS_POLICY: "الأحكام والشروط",
  UPLOAD: "رفع",
  digit: " رقم",
  TOO_LARGE: "هذا الملف كبير جدًا",
  pref_lang: "اللغة المفضلة",
  language: "اللغة",
};
